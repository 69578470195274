import React, {useContext} from 'react';
import { styled } from 'linaria/react'
import UseFormatProduct from '../Core/Hooks/UseFormatProduct';
import SiteButton from '../Core/SiteButton';
import Image from '@jetshop/ui/Image/Image'
import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';
import {useIntl} from '@jetshop/intl';
import PriceSection from '../Core/Cards/PriceSection';
import { ShippingAlert, ShippingAlertNotification } from '../Cart/ShippingAlert';
import { useSiteSettingsQuery } from '../../hooks/useSiteSettings';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import Link from '../Core/Link';

const NotificationContainer = styled.div`
    color: black;

    .notification-image {
        width: 100%;
    }

    .product-card-title {
        font-size: 1.25rem;
        font-weight: 700;
     }
  
     .product-card-title a {
        font-family: var(--font-industry);
        color: black;
     }

     .product-card-sku {
        font-size: 0.688rem;
        font-family: var(--font-grotesk);
        font-weight: 600;
        color: black;
     }

     .product-card-price {
        font-size: 1rem;
        font-weight: 700;
        font-family: var(--font-industry);
     }
  
     .product-card-saleprice {
        color: #a61200;
     }
  
     .product-card-price-regular {
        text-decoration: line-through;
     }
  
`

const AddToCartNotification = ({ product, cartData }) => {
   const {
      isPackage,
      imageUrl,
      url,
      articleNumber,
      isPreBook
   } = UseFormatProduct(product)
   const selectedChannel = useContext(ChannelContext)?.selectedChannel
   const [getSetting, loading] = useSiteSettingsQuery()
   const alertIds = getSetting('productIds') ?? "";
   const track = useTracker()
   const t = useIntl()

   const handleCheckoutClick = (event) => {
      event.preventDefault();
      ShippingAlert(cartData?.addMultipleToCart?.cart, alertIds, track, selectedChannel);
   }

   return (
      <NotificationContainer>
         <div className="row">
            <div className="col-4 p-0">
               <Image className='product-card-image p-16' src={imageUrl} aspect="1:1"></Image>
            </div>
            <div className="col-8 d-flex flex-column justify-content-center">
               <div className="product-card-sku">ARTNR: {articleNumber}</div>
               <div className="product-card-title d-flex lh-1 mb-8 mt-1">
                  <Link to={url}>{product?.title ?? product?.name}</Link>
               </div>
               <div className="row d-flex align-items-center">
                  <PriceSection product={product} selectedChannel={selectedChannel}></PriceSection>
               </div>
            </div>
            {/* <div className="col-2">
                <Title size="1.0rem" weight={600}>
                    {brand}
                </Title>
            </div> */}
         </div>
         <SiteButton
            size="medium"
            styleType="solid"
            color={isPreBook ? "yellow": 'green'}
            value={t('to the checkout')}
            onClick={handleCheckoutClick}
            className="w-100 py-8 text-uppercase"
         ></SiteButton>
      </NotificationContainer>
   )
}
 
export default AddToCartNotification;