import React, { useEffect } from 'react'
import { styled } from 'linaria/react'
import { theme } from '../Theme'
import Link from './Link'

const Button = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  scale: 1;
  transition: background 0.2s ease;
  text-decoration: none;
  width: fit-content;

  &.uppercase--text {
    text-transform: uppercase;
  }

  &.button--solid {
    font-family: var(--font-industry);
    font-weight: 700;
    border-radius: 50px;
  }

  & svg {
    transform: translate(0, 0);
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translate(0.25rem, 0);
    transition: transform 0.5s ease-in-out;
  }

  &.button--hollow {
    background-color: unset;
    font-family: var(--font-industry);
    font-weight: 700;
    border-radius: 50px;
  }

  &.button--icon {
    display: flex;
    align-items: center;
    text-align: left;
  }

  &.button--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.button--blue {
    color: var(--color-white);
    background: var(--color-vb-blue);
    transition: background 0.2s ease;
  }

  &.button--blue:hover {
    background: var(--color-vb-blue-darker);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--hollow--blue {
    border: 3px solid var(--color-vb-blue);
    color: var(--color-vb-blue);
    transition: all;
  }

  &.button--hollow--blue:hover {
    background: rgba(25, 81, 120, 0.15);
  }

  &.button--black {
    color: var(--color-white);
    background: var(--color-black);
    transition: background 0.2s ease;
  }

  &.button--black:hover {
    background: rgba(0, 0, 0, 0.75);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--hollow--black {
    border: 3px solid var(--color-black);
    color: var(--color-black);
    transition: background 0.5s ease;
  }

  &.button--hollow--black:hover {
    background: rgba(0, 0, 0, 0.15);
  }

  &.button--white {
    color: var(--color-black);
    background: var(--color-white);
    transition: background 0.2s ease;
  }

  &.button--white:hover {
    background: rgba(255, 255, 255, 0.75);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--hollow--white {
    border: 3px solid var(--color-white);
    color: var(--color-white);
    transition: background 0.5s ease;
  }

  &.button--hollow--white:hover {
    background: rgba(255, 255, 255, 0.15);
  }

  &.button--green {
    color: var(--color-white);
    background-color: var(--color-instock);
    transition: background 0.2s ease;
  }

  &.button--green:hover {
    background-color: var(--color-instock-hover);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--yellow {
    color: var(--color-black);
    background-color: var(--color-prebook);
    transition: background 0.2s ease;
  }

  &.button--yellow:hover {
    background-color: var(--color-prebook-hover);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--grey {
    color: var(--color-white);
    background-color: rgb(var(--color-vb-gray-3));
    transition: background 0.2s ease;
  }

  &.button--grey:hover {
    background-color: rgba(var(--color-vb-gray-3), 0.75);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &:hover::after {
    transform: translate(0.25rem, 0);
    transition: transform 0.5s ease-in-out;
  }

  &.button--large {
    font-size: 1.125rem;

    ${theme.below.md}{
      font-size: 	0.875rem;
    }
  }
  
  &.button--medium {
    font-size: 1rem;

    ${theme.below.md}{
      font-size: 	0.875rem;
    }
  }
  
  &.button--small {
    font-size: 0.8rem;

    ${theme.below.md}{
      font-size: 	0.6rem;
    }
  }
`

const LinkButton = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  scale: 1;
  transition: background 0.2s ease;
  text-decoration: none;
  width: fit-content;

  &.uppercase--text {
    text-transform: uppercase;
  }

  &.button--solid {
    font-family: var(--font-industry);
    font-weight: 700;
    border-radius: 50px;
  }

  & svg {
    transform: translate(0, 0);
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translate(0.25rem, 0);
    transition: transform 0.5s ease-in-out;
  }

  &.button--hollow {
    background-color: unset;
    font-family: var(--font-industry);
    font-weight: 700;
    border-radius: 50px;
  }

  &.button--icon {
    display: flex;
    align-items: center;
    text-align: left;
  }

  &.button--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.button--blue {
    color: var(--color-white);
    background: var(--color-vb-blue);
    transition: background 0.2s ease;
  }

  &.button--blue:hover {
    background: var(--color-vb-blue-darker);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--hollow--blue {
    border: 3px solid var(--color-vb-blue);
    color: var(--color-vb-blue);
    transition: all;
  }

  &.button--hollow--blue:hover {
    background: rgba(25, 81, 120, 0.15);
  }

  &.button--black {
    color: var(--color-white);
    background: var(--color-black);
    transition: background 0.2s ease;
  }

  &.button--black:hover {
    background: rgba(0, 0, 0, 0.75);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--hollow--black {
    border: 3px solid var(--color-black);
    color: var(--color-black);
    transition: background 0.5s ease;
  }

  &.button--hollow--black:hover {
    background: rgba(0, 0, 0, 0.15);
  }

  &.button--white {
    color: var(--color-black);
    background: var(--color-white);
    transition: background 0.2s ease;
  }

  &.button--white:hover {
    background: rgba(255, 255, 255, 0.75);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--hollow--white {
    border: 3px solid var(--color-white);
    color: var(--color-white);
    transition: background 0.5s ease;
  }

  &.button--hollow--white:hover {
    background: rgba(255, 255, 255, 0.25);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--green {
    color: var(--color-white);
    background-color: var(--color-instock);
    transition: background 0.2s ease;
  }

  &.button--green:hover {
    background-color: var(--color-instock-hover);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--yellow {
    color: var(--color-black);
    background-color: var(--color-prebook);
    transition: background 0.2s ease;
  }

  &.button--yellow:hover {
    background-color: var(--color-prebook-hover);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--grey {
    color: var(--color-white);
    background-color: rgb(var(--color-vb-gray-3));
    transition: background 0.2s ease;
  }

  &.button--grey:hover {
    background-color: rgba(var(--color-vb-gray-3), 0.75);
    scale: 1.005;
    transition: background 0.5s ease, scale 0.5s;
  }

  &.button--disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &:hover::after {
    transform: translate(0.25rem, 0);
    transition: transform 0.5s ease-in-out;
  }

  &.button--large {
    font-size: 1.125rem;

    ${theme.below.md}{
      font-size: 	0.875rem;
    }
  }
  
  &.button--medium {
    font-size: 1rem;

    ${theme.below.md}{
      font-size: 	0.875rem;
    }
  }
  
  &.button--small {
    font-size: 0.8rem;

    ${theme.below.md}{
      font-size: 	0.6rem;
    }
  }
`

export function SiteButton({ value, children, size, disabled, styleType, icon, color, onClick, uppercase, href, className, ...props }) {
   const buttonSize = `${size === 'small' ? 'button--small' : size === 'medium' ? 'button--medium' : size === 'large' ? 'button--large' : ''}`;
   const buttonType = `${styleType === 'solid' ? 'button--solid' : styleType === 'hollow' ? 'button--hollow' : ''}`;
   const buttonCenter = `${!icon ? 'button--center' : 'button--icon'}`;
   const buttonDisabled = `${disabled ? 'button--disabled' : ''}`;
   const upperCase = `${uppercase ? 'uppercase--text' : ''}`;

   const buttonColor =
      styleType === 'solid'
         ? `${
              color === 'blue'
                 ? 'button--blue'
                 : color === 'black'
                 ? 'button--black'
                 : color === 'green'
                 ? 'button--green'
                 : color === 'yellow'
                 ? 'button--yellow'
                 : color === 'white'
                 ? 'button--white'
                 : color === 'grey'
                 ? 'button--grey'
                 : ''
           }`
         : `${
              color === 'blue'
                 ? 'button--hollow--blue'
                 : color === 'black'
                 ? 'button--hollow--black'
                 : color === 'white'
                 ? 'button--hollow--white'
                 : ''
           }`

  const buttonOnClick = (event) => {
    if(onClick){
      onClick(event);
    }
  }

   return href ? (
      <LinkButton
         to={href}
         className={`${buttonSize} ${buttonType} ${buttonCenter} ${buttonColor} ${upperCase} ${buttonDisabled} ${className}`}
         onNavigation={buttonOnClick}
         {...props}
      >
         {value && <span>{value}</span>}
         {children}
      </LinkButton>
   ) : (
      <Button
         className={`${buttonSize} ${buttonType} ${buttonCenter} ${buttonColor} ${upperCase} ${className}`}
         disabled={disabled}
         onClick={buttonOnClick}
         {...props}
      >
         {value && <span>{value}</span>}
         {children}
      </Button>
   )
}

export default SiteButton
