import { css } from 'linaria'
import { theme } from '../components/Theme'

import IndustryWoffBold from './Industry-Bold.woff'
import IndustryWoffMedium from './Industry-Medium.woff'

import IndustryWoff2Bold from './Industry-Bold.woff2'
import IndustryWoff2Medium from './Industry-Medium.woff2'

import HKGroteskProWoff2Bold from './HKGroteskPro-Bold.woff2'
import HKGroteskProWoff2Medium from './HKGroteskPro-Medium.woff2'
import HKGroteskProWoff2Regular from './HKGroteskPro-Regular.woff2'
import HKGroteskProWoff2SemiBold from './HKGroteskPro-SemiBold.woff2'

import HKGroteskProWoffBold from './HKGroteskPro-Bold.woff'
import HKGroteskProWoffMedium from './HKGroteskPro-Medium.woff'
import HKGroteskProWoffRegular from './HKGroteskPro-Regular.woff'
import HKGroteskProWoffSemiBold from './HKGroteskPro-SemiBold.woff'

export default function loadCss() {
   return css`
      :global(html) {

         @font-face {
            font-family: ${theme.font.family.body};
            font-style: normal;
            font-weight: ${theme.font.weight.regular};
            src: local(${theme.font.family.body}),
               url(${HKGroteskProWoffRegular}) format('woff'),
               url(${HKGroteskProWoff2Regular}) format('woff2');
         }

         @font-face {
            font-family: ${theme.font.family.body};
            font-style: normal;
            font-weight: ${theme.font.weight.medium};
            src: local(${theme.font.family.body}),
               url(${HKGroteskProWoffMedium}) format('woff'),
               url(${HKGroteskProWoff2Medium}) format('woff2');
         }

         @font-face {
            font-family: ${theme.font.family.body};
            font-style: normal;
            font-weight: ${theme.font.weight.semiBold};
            src: local(${theme.font.family.body}),
               url(${HKGroteskProWoffSemiBold}) format('woff'),
               url(${HKGroteskProWoff2SemiBold}) format('woff2');
         }

         @font-face {
            font-family: ${theme.font.family.body};
            font-style: normal;
            font-weight: ${theme.font.weight.bold};
            src: local(${theme.font.family.body}),
               url(${HKGroteskProWoffBold}) format('woff'),
               url(${HKGroteskProWoff2Bold}) format('woff2');
         }

         @font-face {
            font-family: ${theme.font.family.heading};
            font-style: normal;
            font-weight: ${theme.font.weight.medium};
            src: local(${theme.font.family.heading}),
               url(${IndustryWoffMedium}) format('woff'),
               url(${IndustryWoff2Medium}) format('woff2');
         }

         @font-face {
            font-family: ${theme.font.family.heading};
            font-style: normal;
            font-weight: ${theme.font.weight.medium};
            src: local(${theme.font.family.heading}),
               url(${IndustryWoffMedium}) format('woff'),
               url(${IndustryWoff2Medium}) format('woff2');
         }

         @font-face {
            font-family: ${theme.font.family.heading};
            font-style: normal;
            font-weight: ${theme.font.weight.bold};
            src: local(${theme.font.family.heading}),
               url(${IndustryWoffBold}) format('woff'),
               url(${IndustryWoff2Bold}) format('woff2');
         }

         &.open-sans-ready body {
            font-family: ${theme.font.family.body}, sans-serif;
            line-height: 1.2;
            letter-spacing: 0;
            word-spacing: 0;
            font-weight: normal;
         }

         &.industry-ready {
            h1,
            h2,
            h3,
            h4,
            h5 {
               font-family: ${theme.font.family.heading}, sans-serif;
               letter-spacing: 0;
               word-spacing: 0;
               font-weight: 700;
            }
         }
      }
   `
}
