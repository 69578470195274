
export const fetchSmartCollection = async (apiKey, limit, offset, filters, sortFilter) => {
   try {
      const currentPath = window.location.pathname
      // New userId and sessionId i set on every request
      const uniqueId = generateRandomString()
      const payload = {
         user: {
            uid: uniqueId,
            sid: uniqueId,
            persist: false,
            exist: false,
         },
         t_client: Date.now(),
         key: apiKey,
         limit: limit,
         offset: offset,
         filters: filters,
         slot: currentPath?.slice(1),
         sort: [sortFilter]
      }
      const response = await fetch(
         `https://api-v3.findify.io/v3/smart-collection/${currentPath}`,
         {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'x-key': apiKey,
            },
            body: JSON.stringify(payload),
         }
      )
      return await response.json();
   } catch (error) {
      console.error(error)
   }
}

export const fetchFindifySearch = async (apiKey, limit, searchValue, offset, filters, sortFilter) => {
   try {
      // New userId and sessionId i set on every request
      const uniqueId = generateRandomString()
      const payload = {
         user: {
            uid: uniqueId,
            sid: uniqueId,
            persist: false,
            exist: false,
         },
         t_client: Date.now(),
         key: apiKey,
         limit: limit,
         offset: offset,
         filters: filters,
         sort: [sortFilter],
         q: searchValue
      }
      const response = await fetch(
         `https://api-v3.findify.io/v3/search`,
         {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'x-key': apiKey,
            },
            body: JSON.stringify(payload),
         }
      )
      return await response.json();
   } catch (error) {
      console.error(error)
   }
}

export const fetchFindifyAutoComplete = async (apiKey, searchValue, itemLimit, suggestionLimit ) => {
   try {
      // New userId and sessionId i set on every request
      const uniqueId = generateRandomString()
      const payload = {
         user: {
            uid: uniqueId,
            sid: uniqueId,
            persist: false,
            exist: false,
         },
         t_client: Date.now(),
         key: apiKey,
         suggestion_limit: suggestionLimit,
         item_limit: itemLimit,
         q: searchValue
      }
      const response = await fetch(
         `https://api-v3.findify.io/v3/autocomplete`,
         {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'x-key': apiKey,
            },
            body: JSON.stringify(payload),
         }
      )
      return await response.json();
   } catch (error) {
      console.error(error)
   }
}

const getDataFromLocalStorage = (key, timeoutMs, maxAttempts) => {
   return new Promise((resolve, reject) => {
     let attempts = 0;
 
     const checkData = () => {
       const data = localStorage.getItem(key);
       attempts++;
 
       if (data !== null) {
         resolve(data);
       } else if (attempts < maxAttempts) {
         setTimeout(checkData, timeoutMs);
       } else {
         reject(new Error(`Data not found in localStorage after ${attempts} attempts.`));
       }
     };
 
     checkData();
   });
 }

 const generateRandomString = () => {
   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
   let result = '';
   for (let i = 0; i < 16; i++) {
     result += characters.charAt(Math.floor(Math.random() * characters.length));
   }
   return result;
 }