import React, { useState, useEffect, useContext } from 'react'
import { styled } from 'linaria/react'
import { css } from 'linaria'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { ReactComponent as LogoSweSvg } from '../../../svg/verktygsboden-logo-sv-animated.svg'
import { ReactComponent as LogoNorSvg } from '../../../svg/verktygsboden-logo-no-animated.svg'
import { ReactComponent as LogoDenSvg } from '../../../svg/verktygsboden-logo-dk-animated.svg'
import { theme } from '../../Theme'

const LogoWrapper = styled.div`
   position: relative;

   svg .vb-logo-1 {
      stroke-dashoffset: 70.97278594970703px;
      stroke-dasharray: 70.97278594970703px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s;
   }

   svg.active .vb-logo-1 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-2 {
      stroke-dashoffset: 74.09278106689453px;
      stroke-dasharray: 74.09278106689453px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            0.1s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s;
   }

   svg.active .vb-logo-2 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-3 {
      stroke-dashoffset: 78.5925521850586px;
      stroke-dasharray: 78.5925521850586px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            0.2s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s;
   }

   svg.active .vb-logo-3 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-4 {
      stroke-dashoffset: 79.45098876953125px;
      stroke-dasharray: 79.45098876953125px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            0.30000000000000004s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
   }

   svg.active .vb-logo-4 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-5 {
      stroke-dashoffset: 51.7120361328125px;
      stroke-dasharray: 51.7120361328125px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            0.4s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
   }

   svg.active .vb-logo-5 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-6 {
      stroke-dashoffset: 67.81697845458984px;
      stroke-dasharray: 67.81697845458984px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            0.5s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
   }

   svg.active .vb-logo-6 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-7 {
      stroke-dashoffset: 83.7005844116211px;
      stroke-dasharray: 83.7005844116211px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            0.6000000000000001s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
   }

   svg.active .vb-logo-7 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-8 {
      stroke-dashoffset: 82.24095153808594px;
      stroke-dasharray: 82.24095153808594px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            0.7000000000000001s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
   }

   svg.active .vb-logo-8 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-9 {
      stroke-dashoffset: 79.6542739868164px;
      stroke-dasharray: 79.6542739868164px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            0.8s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
   }

   svg.active .vb-logo-9 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-10 {
      stroke-dashoffset: 75.53507232666016px;
      stroke-dasharray: 75.53507232666016px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            0.9s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4s;
   }

   svg.active .vb-logo-10 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-11 {
      stroke-dashoffset: 77.67509460449219px;
      stroke-dasharray: 77.67509460449219px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
   }

   svg.active .vb-logo-11 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-12 {
      stroke-dashoffset: 74.09283447265625px;
      stroke-dasharray: 74.09283447265625px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            1.1s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
   }

   svg.active .vb-logo-12 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-13 {
      stroke-dashoffset: 85.2475814819336px;
      stroke-dasharray: 85.2475814819336px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            1.2000000000000002s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
   }

   svg.active .vb-logo-13 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-14 {
      stroke-dashoffset: 70.97278594970703px;
      stroke-dasharray: 70.97278594970703px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            1.3s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
   }

   svg.active .vb-logo-14 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-15 {
      stroke-dashoffset: 74.09278106689453px;
      stroke-dasharray: 74.09278106689453px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            1.4000000000000001s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
   }

   svg.active .vb-logo-15 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-16 {
      stroke-dashoffset: 78.5925521850586px;
      stroke-dasharray: 78.5925521850586px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            1.5s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
   }

   svg.active .vb-logo-16 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-17 {
      stroke-dashoffset: 79.45098876953125px;
      stroke-dasharray: 79.45098876953125px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            1.6s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
   }

   svg.active .vb-logo-17 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-18 {
      stroke-dashoffset: 51.7120361328125px;
      stroke-dasharray: 51.7120361328125px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            1.7000000000000002s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
   }

   svg.active .vb-logo-18 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-19 {
      stroke-dashoffset: 67.81697845458984px;
      stroke-dasharray: 67.81697845458984px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            1.8s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
   }

   svg.active .vb-logo-19 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-20 {
      stroke-dashoffset: 83.7005844116211px;
      stroke-dasharray: 83.7005844116211px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            1.9000000000000001s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
   }

   svg.active .vb-logo-20 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-21 {
      stroke-dashoffset: 82.24095153808594px;
      stroke-dasharray: 82.24095153808594px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 2s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
   }

   svg.active .vb-logo-21 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-22 {
      stroke-dashoffset: 79.6542739868164px;
      stroke-dasharray: 79.6542739868164px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            2.1s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
   }

   svg.active .vb-logo-22 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-23 {
      stroke-dashoffset: 75.53507232666016px;
      stroke-dasharray: 75.53507232666016px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            2.2s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
   }

   svg.active .vb-logo-23 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-24 {
      stroke-dashoffset: 77.67509460449219px;
      stroke-dasharray: 77.67509460449219px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            2.3000000000000003s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8000000000000003s;
   }

   svg.active .vb-logo-24 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-25 {
      stroke-dashoffset: 74.09283447265625px;
      stroke-dasharray: 74.09283447265625px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            2.4000000000000004s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s;
   }

   svg.active .vb-logo-25 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   svg .vb-logo-26 {
      stroke-dashoffset: 85.2475814819336px;
      stroke-dasharray: 85.2475814819336px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
            2.5s,
         fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 3s;
   }

   svg.active .vb-logo-26 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
   }

   .default-svg {
        opacity: 0.5;
        position: absolute;
        top: 0;
        ${theme.above.lg}{
         top: -4px;
        }
        transition: opacity 1.2s, visibility 1.2s;
   }

   .default-svg-hide {
      visibility: hidden;
      opacity: 0;
   }
`

const Logo = () => {
   const [show, setShow] = useState(false);
   // const [hideDefault, setHideDefault] = useState(false);
   const selectedChannel = useContext(ChannelContext)?.selectedChannel

   const getLogoByLanguage = () => {
      switch (selectedChannel?.id) {
         case 1:
            return <LogoSweSvg className={`logo-desktop d-xl-block d-lg-block ${show && "active"}`} id="main-svg"></LogoSweSvg>;
         case 2: 
            return <LogoDenSvg className={`logo-desktop d-xl-block d-lg-block ${show && "active"}`} id="main-svg"></LogoDenSvg>;
         case 3:
            return <LogoNorSvg className={`logo-desktop d-xl-block d-lg-block ${show && "active"}`} id="main-svg"></LogoNorSvg>;
         default:
            return <LogoSweSvg className={`logo-desktop d-xl-block d-lg-block ${show && "active"}`} id="main-svg"></LogoSweSvg>;
       }
   }


   useEffect(() => {

    const timeout = setTimeout(() => {
      setShow(true)
    }, 750);

    return () => {
      clearTimeout(timeout);
    };
   }, [])

   return (
      <LogoWrapper>
         {getLogoByLanguage()}

         {/* <div className={`default-svg ${hideDefault && "default-svg-hide"}`}>
            <LogoSweDefault></LogoSweDefault>
         </div> */}
      </LogoWrapper>
   )
}
 
export default Logo;