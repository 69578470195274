import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import categoryByIdQuery from '../components/Layout/Header/categoryByIdQuery.gql'
import { SITE_SETTING } from '../components/CustomElements/elementTypes'
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig'

/**
 * @returns {Array} [getSetting, loading]
 * @example
 * // Usage
 *    const [getSetting, loading] = useSiteSettingsQuery()
 *    if (loading) return 'Loading...';
 */
export const useSiteSettingsQuery = () => {
   const { selectedChannel } = useContext(ChannelContext)
   const { siteSettingsId } = useShopConfig()

   const { data, loading } = useQuery(categoryByIdQuery, {
      variables: { id: siteSettingsId },
      options: {
         headers: { 
            "channelId": selectedChannel?.id
          } 
      }
   })
   
   if (!siteSettingsId) {
      console.error('Environment variable SITE_SETTINGS_ID is not set')
   }

   //console.log(data)
   
   function getSetting(key, type) {
      let value = undefined

      data?.category?.data?.items?.forEach(item => {
         if (item.type === SITE_SETTING) {
            let found = item.properties?.find(field => {
               return field.name === 'key' && field.value.string === key
            })

            if (found) {
               // We are on the correct item, let's get the value
               let foundValue = item.properties?.find(field => {
                  return field.name === 'value'
               })

               if(foundValue && foundValue.value?.string) {
                  value = foundValue.value?.string;
               }
            }
         }

         if(item?.type === type){
            value = item;
         }
      })

      return value
   }

   return [getSetting, loading]
}
