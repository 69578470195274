import React, {useState} from 'react';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { User, LogOut } from 'iconoir-react';
import { useQuery } from '@apollo/react-hooks'
import customerNameQuery from '../../../MyPages/CustomerNameQuery.gql';
import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine'
import t from '@jetshop/intl'
import Title from '../../../Core/Title';
import { styled } from 'linaria/react'
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import Link from '../../../Core/Link';


const UserIconContainer = styled.div`
   display: flex;
   align-items: center;
   gap: 0.5rem;
   
   a svg {
      width: 26px;
      height: 26px;
   }
`

const UserIcon = () => {
   const { loggedIn } = useAuth()
   const { routes } = useShopConfig()

   const { data, loading } = useQuery(customerNameQuery,{
      skip: !loggedIn,
   })

   const fullName = `${data?.customer?.billingAddress?.firstName} ${data?.customer?.billingAddress?.lastName}`

   return (
      <UserIconContainer>
            {loading ? (
               <></>
            ) : (
               data?.customer && (
                  <>
                     <Link className="link link--vb link--white d-none d-xl-block" to={routes.myPages.path}>
                        <Title size="16px" weight={600}>
                           {fullName}
                        </Title>
                     </Link>
                  </>
               )
            )}
         <Link className="my-account-link" to={routes.myPages.path}>
            <User strokeWidth={2.0}></User>
         </Link>
      </UserIconContainer>
   )
}
 
export default UserIcon;