import React from 'react'
import { getStringByPropertyName } from '../../../../Core/Util/Helpers'
import { styled } from 'linaria/react'
import { theme } from '../../../../Theme'
import Link from '../../../../Core/Link'

const SideCategoriesNavWrapper = styled('nav')`
   gap: 1rem;
   ${theme.above.xl} {
      gap: 0.6rem;
   }
   ${theme.above.xxxl} {
      gap: 2rem;
   }

   & .nav-item {
      color: white;
      white-space: nowrap;
      font-size: ${theme.font.size.xs};
      font-weight: 600;
      font-family: var(--font-grotesk);
      /* font-family: ${theme.font.family.heading}; */
      ${theme.above.xxxl} {
         font-size: ${theme.font.size.s};
      }
      &:hover {
         opacity: 0.85;
      }
   }
`

const SideCategoryNav = ({ categories }) => {
   return (
      <SideCategoriesNavWrapper
         className="d-none d-xl-flex align-align-content-center text-nowrap"
         aria-label="Category Navigation"
      >
         {categories?.map(({ properties }, i) => {
            const catName = getStringByPropertyName(properties, 'text') ?? ''
            const catLink = getStringByPropertyName(properties, 'link') ?? ''
            const isHidden = getStringByPropertyName(properties, 'isActive') === 'hidden'

            if (isHidden) return null

            return (
               <Link
                  to={catLink}
                  key={i}
                  // onNavigation={() => console.log('navigate')}
               >
                  <span className="nav-item link link--vb link--white">
                     {catName}
                  </span>
               </Link>
            )
         })}
      </SideCategoriesNavWrapper>
   )
}

export default SideCategoryNav
