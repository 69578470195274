import React, { useEffect } from 'react'
import { styled } from 'linaria/react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { theme } from '../Theme'
import { Cancel as CancelIcon } from 'iconoir-react';
import Title from './Title'

const StyledDrawer = styled(Drawer)`
   overflow-y: auto;
   width: 35vw !important;
   height: 100% !important;
   border-radius: 2rem 0rem 0rem 0rem;
   background-color: ${props => props.backgroundColor} !important;
   z-index: 1030;

   ${theme.below.xxxl} {
      width: 40vw !important;
      border-radius: 2rem 0rem 0rem 0rem;
   }

   ${theme.below.xxl} {
      width: 50vw !important;
      border-radius: 2rem 0rem 0rem 0rem;
   }

   ${theme.below.lg} {
      width: 60vw !important;
      border-radius: 2rem 0rem 0rem 0rem;
   }

   ${theme.below.md} {
      width: 85vw !important;
      border-radius: 2rem 0rem 0rem 0rem;
   }

   .menu-drawer-title {
      margin-top: -6px;
      ${theme.below.lg} {
         font-size: 1.3rem;
         margin-top: 0;
      }
   }

   .cancel-icon {
      cursor: pointer;
      ${theme.below.lg} {
         height: 1.75rem;
         width: 1.75rem;
         margin-top: 2px;
      }
   }

   .cancel-icon:hover {
      opacity: 0.75;
      transition: opacity 0.5s ease;
   }

   a {
      text-decoration: none;
   }

   a:hover {
      opacity: 0.75;
      transition: opacity 0.5s ease;
   }
`

const VbDrawer = ({isOpen, setIsOpen, backgroundColor, overrideDefault, title, className, children, ...props}) => {

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    useEffect(() => {
      // Add/remove the "prevent-body-scroll" class to the body element
      document.body.classList.toggle("prevent-body-scroll", isOpen);
  
      // Cleanup function to remove the class when the component unmounts
      return () => {
        document.body.classList.remove("prevent-body-scroll");
      };
    }, [isOpen]);
    
    return (
       <StyledDrawer
          backgroundColor={backgroundColor ?? '#e9e9e9'}
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          className={`py-64 pl-64 pr-128 ${className}`}
          {...props}
       >
          {!overrideDefault ? (
             <>
                <div className="d-flex justify-content-between align-items-center">
                   <Title
                      size="32px"
                      fontFamily="Industry"
                      weight={700}
                      className="menu-drawer-title"
                   >
                      {title}
                   </Title>
                   <CancelIcon
                      strokeWidth={2.0}
                      width={44}
                      height={44}
                      className="cancel-icon"
                      onClick={() => setIsOpen(!isOpen)}
                   ></CancelIcon>
                </div>
                <hr className="mt-32"></hr>
                {children}
             </>
          ) : (
            <>
               {children}
            </>
          )}
       </StyledDrawer>
    )
}

export default VbDrawer
