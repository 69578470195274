import { NavArrowRight } from 'iconoir-react'
import Spinner from './Spinner'
import { styled } from 'linaria/react'
import React from 'react'

const StyledSpinner = styled(Spinner)`
   height: 1.2rem;
   width: 1.2rem;
   margin-left: 0.4rem;
   circle.path {
      stroke: white;
   }
`

const LoadingArrowIcon = ({ loading }) => {
   return (
      <>
         {loading ? (
            <StyledSpinner />
         ) : (
            <NavArrowRight
               strokeWidth={2.0}
               style={{ marginLeft: '0.3rem' }}
            ></NavArrowRight>
         )}
      </>
   )
}

export default LoadingArrowIcon
