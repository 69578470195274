import React, { useEffect } from 'react'
import { styled } from 'linaria/react'

const Span = styled.span`
    font-family: ${props => props.fontFamily};
    font-size: ${props => props.size};
    font-weight: ${props => props.weight};
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    display: block;
`

export function Title({value, size, fontFamily, weight, className, children}) {
   return (
      <Span className={className} size={size} fontFamily={fontFamily ?? "HKGrotesk"} weight={weight ?? 500}>
        {value}
        {children}
      </Span>
   )
}

export default Title
