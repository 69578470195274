import React from 'react'
import { styled } from 'linaria/react'
import SiteButton from '../SiteButton'
import { Minus, Plus } from 'iconoir-react'
import { useState } from 'react'
import { useEffect } from 'react'
import { theme } from '../../Theme'

const QuantityButtonWrapper = styled.div`
   border: 3px solid var(--color-vb-blue);
   border-radius: 23px;

   .qty-input {
      width: 1.45rem;
      border-style: hidden;
      background-color: white;
      font-family: var(--font-industry);
      font-size: 14px;
      text-align: center;
      color: var(--color-vb-blue);
      ${theme.above.sm} {
         font-size: 16px;
      }
   }

   .qty-input:focus {
      outline: none;
   }

  .qty-button {
     padding: 0rem 0rem;
     background-color: white;
     ${theme.above.sm} {
        padding: 0.2rem 0.2rem;
     }
  }

   .qty-button:hover {
      background-color: white;
      scale: revert;
   }

   .qty-button:hover svg {
      transform: none;
      transition: none;
   }

   .qty-button svg {
      color: var(--color-vb-blue);
   }

   & input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   & input[type='number'] {
      -moz-appearance: textfield;
   }
`

const QuantityButton = ({ qty, updateQuantity }) => {
   const [quantity, setQuantity] = useState(qty)

   useEffect(() => {
      setQuantity(qty)
   }, [qty])

   const increaseQuantity = () => {
      updateQuantity(qty + 1)
   }

   const decreaseQuantity = () => {
      updateQuantity(qty - 1)
   }

   const manualSetQuantity = e => {
      if (!e.target.value) {
         setQuantity(e.target.value)
         return
      }
      let newValue = e.target.value
      updateQuantity(newValue)
   }

   return (
      <QuantityButtonWrapper className="qty-container d-flex">
         <SiteButton
            size="medium"
            styleType="solid"
            color={'green'}
            className="qty-button"
            disabled={qty == 1}
            onClick={decreaseQuantity}
         >
            <Minus strokeWidth={2.0}></Minus>
         </SiteButton>
         <input
            className="qty-input"
            type="number"
            value={quantity}
            onChange={manualSetQuantity}
         ></input>
         <SiteButton
            size="medium"
            styleType="solid"
            color={'green'}
            icon={true}
            className="qty-button"
            onClick={increaseQuantity}
         >
            <Plus strokeWidth={2.0}></Plus>
         </SiteButton>
      </QuantityButtonWrapper>
   )
}

export default QuantityButton
