import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { Heart, Cancel as CancelIcon } from 'iconoir-react'
import { styled } from 'linaria/react'
import Drawer from 'react-modern-drawer'
import { theme } from '../../../Theme'
import Title from '../../../Core/Title'
import WishlistQuery from './WishlistQuery.gql'
import VbDrawer from '../../../Core/VbDrawer'
import {
   useProductList,
   useProductListItems,
} from '@jetshop/core/hooks/ProductList'
import ProductWishListCard from '../../../Core/Cards/ProductWishListCard'
import { useIntl } from '@jetshop/intl'
import Link from '../../../Core/Link'

const WhishlistDrawer = ({ wishlistItems, isOpen, setIsOpen, clear }) => {
   const t = useIntl()
   let items = []
   Object.keys(wishlistItems?.items).forEach(function (key) {
      items.push(key)
   })

   const { loading, error, data } = useQuery(WishlistQuery, {
      skip: items?.length == 0,
      variables: { articleNumbers: items },
      onError: error => {
         clear()
      }
   })

   const products = data?.products ?? []

   return (
      <VbDrawer
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         title={t('your wish list')}
      >
         <div className="mt-32">
            {products?.length > 0 &&
               products?.map((product, i) => {

                  if (product.isPackage) {
                     return (
                        <ProductWishListCard
                           product={product}
                           key={i}
                           closeDrawer={() => setIsOpen(false)}
                        ></ProductWishListCard>
                     )
                  }

                  return product?.variants?.values?.map((variant, i) => {
                     if (!items.includes(variant?.articleNumber)) {
                        return <React.Fragment key={i}></React.Fragment>
                     }
                     const newVariant = {
                        ...variant,
                        images: product.images,
                        name: product?.name,
                        primaryRoute: product?.primaryRoute,
                        subName: product?.subName,
                     }
                     return (
                        <ProductWishListCard
                           product={newVariant}
                           key={i}
                           closeDrawer={() => setIsOpen(false)}
                        ></ProductWishListCard>
                     )
                  })
               })}
         </div>
      </VbDrawer>
   )
}

const WhishlistIcon = () => {
   const { list, count, clear } = useProductList()
   const [isOpen, setIsOpen] = useState(false)

   return (
      <>
         <Link
            className="my-account-link"
            to="/"
            onClick={event => {
               event.preventDefault()
               setIsOpen(!isOpen)
            }}
         >
            <Heart strokeWidth={2.0}></Heart>
            {count > 0 && (
               <div className="badge-counter">
                  <span>{count}</span>
               </div>
            )}
         </Link>
         <WhishlistDrawer
         clear={clear}
            wishlistItems={list}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
         ></WhishlistDrawer>
      </>
   )
}

export default WhishlistIcon
