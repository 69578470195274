import React, { useRef } from 'react'
import { styled } from 'linaria/react'
import SearchIcon from '../../../svg/Search.svg'
import UseSearchLogic from '../../Core/Hooks/UseSearchLogic'
import { useIntl } from '@jetshop/intl'
import { useHeaderContext } from './HeaderContext/HeaderContext'
import Spinner from '../../ui/Spinner'
import ClearSearch from '../../Core/Icons/ClearSearch'

const SearchMobile = styled.div`
   background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(25, 81, 120, 1) 50%,
      rgba(25, 81, 120, 1) 100%
   );
   margin-top: -8px;
   position: relative;

   .search-mobile {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-family: var(--font-grotesk);
      font-size: 1rem;
      line-height: normal;
      padding: 0.8rem 1.5rem 0.8rem 2.2rem;
      border-radius: 50px;
      scale: 1;
      transition: all 0.2s ease;
      font-weight: 600;
      border: 8px solid var(--color-vb-blue) !important;
      padding: ${props =>
         props.isScrolled
            ? '0.5rem 1.1rem 0.5rem 2.5rem'
            : '0.8rem 1.1rem 0.8rem 2.5rem'};
   }

   /* .search-mobile:hover {
      box-shadow: 0 0 0.5rem 3px rgba(255, 255, 255, 0.2);
   } */

   .search-mobile:focus {
      box-shadow: none;
   }

   .icon {
      transition: margin 0.2s ease;
      margin-top: ${props => (props.isScrolled ? '-1px' : '4px')};
      position: absolute;
      padding: 1rem 0rem 1rem 0rem !important;
      margin-left: 1.4rem;
      top: 0;
      left: 0;
      opacity: 0.5;
   }

   .spinner-wrapper {
      transition: margin 0.2s ease;
      margin-top: ${props => (props.isScrolled ? '-1px' : '4px')};
      position: absolute;
      padding: 0.8rem 0rem;
      margin-right: 1rem;
      top: 0;
      right: 0;
      opacity: 0.5;
   }

   input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
   }
`

const StyledSpinner = styled(Spinner)`
   height: 1.2rem;
   width: 1.2rem;
   circle.path {
      stroke: black;
   }
`

function MobileSearchBar() {
   const inputRef = useRef(null)
   const {
      handleInputChange,
      handleInputFocus,
      handleKeyPressed,
      isLoadingRedirectReq,
      handleClear
   } = UseSearchLogic(inputRef)
   const t = useIntl()
   const { isScrolled } = useHeaderContext()

   return (
      <SearchMobile
         className="form-group d-block d-md-none"
         isScrolled={isScrolled}
      >
         <input
            ref={inputRef}
            type="search"
            id="searchfield-mobile"
            className="form-control search-mobile"
            placeholder={t('Searchfield-placeholder')}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onKeyDown={handleKeyPressed}
         />

         {isLoadingRedirectReq ? (
            <span className="icon pr-8">
               <StyledSpinner />
            </span>
         ) : (
            <img className="icon pr-8" alt="Search-icon" src={SearchIcon} />
         )}

         <ClearSearch inputRef={inputRef} handleClear={handleClear} className="pr-32"/>
      </SearchMobile>
   )
}

export default MobileSearchBar
