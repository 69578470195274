import React, { useContext, useEffect, useRef } from 'react'
import { emptyCart, trackCartUpdate } from '../../../utils/voyado/voyadoEngage'
import ChannelContext from '@jetshop/core/components/ChannelContext'

const useVoyadoTrackCart = ({ cart }) => {
   const prevCartId = useRef(null)
   const latestCart = useRef(cart) // Create a ref to store the latest cart
   const timeoutIdRef = useRef(null) // Ref to store the timeout ID for cleanup
   const localeCulture = useContext(ChannelContext)?.selectedChannel?.defaultLanguage?.culture ?? 'sv-SE'

   useEffect(() => {
      latestCart.current = cart // Update the ref to the latest cart data on each render

      // 'No active cart found to track, likely because the user is entering the site with an empty or uninitialized cart'
      if (!cart) {
         // console.log(
         //    'No active cart found to track, likely because the user is entering the site with an empty or uninitialized cart'
         // )
         return
      }

      // Clear any previous timeout before setting a new one to prevent stale data or multiple timeouts
      if (timeoutIdRef.current) {
         clearTimeout(timeoutIdRef.current)
      }

      // If the cart has no id and prevCartId is null, no need to proceed
      if (cart?.id === null && prevCartId.current === null) {
         // console.log('No cart found to track', '\n\n')
         return
      }

      // If the current cart is null but a previous cart exists, empty the previous cart after a delay
      if (cart?.id === null && prevCartId.current) {
         timeoutIdRef.current = setTimeout(() => {
            // console.log(
            //    'emptyCart FN triggered(with delay) with previous cart id:',
            //    prevCartId.current
            // )
            emptyCart(prevCartId.current, localeCulture)
            prevCartId.current = null // Reset previous cart ID after emptying
         }, 250)
      }

      // If a valid cart id exists, track the cart and update the previous cart ID after a delay
      if (cart?.id !== null) {
         prevCartId.current = cart?.id
         timeoutIdRef.current = setTimeout(() => {
            // console.log('trackCartUpdate FN triggered(with delay):', cart?.id)
            // Use latestCart.current to ensure we use the latest cart data when the timeout runs
            trackCartUpdate(latestCart.current, localeCulture)
         }, 250)
      }

      // Cleanup function to clear timeout if the cart changes or component unmounts
      return () => {
         if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current)
         }
      }
   }, [cart, localeCulture])
}

export default useVoyadoTrackCart
