import { PREBOOK_SV, PREBOOK_DE, PREBOOK_NO, PREBOOK_EN, STOCK_ID } from './Constants';

export const stringByPropertyNameHasValidValue = value => {
   if (typeof value === 'string') {
      const trimmedStr = value.trim()
      return !!trimmedStr
   }
   return value !== null && value !== undefined ? true : false
}

export const getStringByPropertyName = (properties, key) => {
   return properties?.find(p => p?.name == key)?.value?.string
}

export const getBoolByPropertyName = (properties, key) => {
   return properties?.find(p => p?.name == key)?.value?.bool
}

export function splitArrayIntoChunks(array, numChunks) {
   const chunkSize = Math.floor(array.length / numChunks)
   const numLargeChunks = array.length % numChunks
   let currentIndex = 0

   const chunks = []

   for (let i = 0; i < numChunks; i++) {
      const chunkLength = chunkSize + (i < numLargeChunks ? 1 : 0)
      const chunk = array.slice(currentIndex, currentIndex + chunkLength)
      currentIndex += chunkLength
      chunks.push(chunk)
   }

   return chunks
}

export function elementsToKeepInArray(arr, numToFit) {
   if (arr?.length && arr?.length == 0) {
      return []
   }

   if (arr?.length <= numToFit) {
      return arr
   }

   let numToRemove = arr?.length - numToFit

   return arr.slice(0, -numToRemove)
}

function alphabeticalSort(a, b, prop) {
   if (a[prop] < b[prop]) {
      return -1
   }
   if (a[prop] > b[prop]) {
      return 1
   }
   return 0
}

export function sortObjectsByProperty(arr, prop) {
   const arrCopy = [...arr]
   return arrCopy.sort((a, b) => alphabeticalSort(a, b, prop))
}

function isDecimal(number) {
   return !Number.isInteger(number)
}

export function formatPrice(price, channelId) {
   if (!price || price == null) {
      return ''
   }

   const isDecimalPrice = isDecimal(price)

   if (isDecimalPrice) {
      price = price.toFixed(2)
   }

   if (price >= 1000) {
      price = price.toString().replace('.', ',')
      // insert space every 3 digits before the comma
      price = price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
   } else {
      price = price.toString().replace('.', ',')
   }

   if (channelId == 1) {
      return price + ' kr'
   } else if (channelId == 2) {
      return price + ' kr'
   } else if (channelId == 3) {
      return price + ' kr'
   } else {
      return price
   }
}

export const generateUniqueId = () => {
   const timestamp = new Date().getTime()
   const random = Math.random().toString(36).substring(2, 15)
   return `${timestamp}_${random}`
}

export const getCurrencyCodeByCountry = (channelId) => {
   switch (channelId) {
     case 1:
       return "SEK";
     case 2:
       return "DKK";
     case 3:
       return "NOK";
     default:
       return "";
   }
 };

export function isTimestampWithin30Days(timestamp) {
   // Get the current date
   var currentDate = new Date()

   // Calculate the date 30 days ago
   var thirtyDaysAgo = new Date()
   thirtyDaysAgo.setDate(currentDate.getDate() - 30)

   // Convert the given timestamp to a Date object
   var givenDate = new Date(timestamp)

   // Compare the given date with the date 30 days ago
   return givenDate > thirtyDaysAgo
}

export function stripQueryParamFromUrl(url, paramName) {
   // Find the index of the query parameter in the URL
   const paramIndex = url.indexOf(`${paramName}=`)

   // If the parameter is found
   if (paramIndex !== -1) {
      // Find the index of the '&' character following the parameter
      const ampersandIndex = url.indexOf('&', paramIndex)

      // Extract the query parameter substring
      const paramSubstring = url.substring(
         paramIndex,
         ampersandIndex !== -1 ? ampersandIndex : undefined
      )

      // Remove the query parameter substring from the URL
      let strippedUrl = url.replace(paramSubstring, '')

      // Remove the trailing '?' character if the parameter was the last query parameter
      if (strippedUrl.endsWith('?')) {
         strippedUrl = strippedUrl.slice(0, -1)
      }

      return strippedUrl
   }

   // Return the original URL if the parameter and query mark are not found
   return url
}

export function calculateDaysSinceDate(dateString) {
   const currentDate = new Date()
   const inputDate = new Date(dateString)

   // Calculate the difference in milliseconds
   const timeDiff = currentDate.getTime() - inputDate.getTime()

   // Convert milliseconds to days
   const daysPassed = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

   return daysPassed
}

export function getStickersFromProductSettings(productSettings) {
   const campaignSticker = getStringByPropertyName(
      productSettings?.properties,
      'campaignSticker'
   )

   const newSticker = getStringByPropertyName(
      productSettings?.properties,
      'newsSticker'
   )

   const packageSticker = getStringByPropertyName(
      productSettings?.properties,
      'packageSticker'
   )

   const preBookCampaignSticker = getStringByPropertyName(
      productSettings?.properties,
      'preBookCampaignSticker'
   )

   return { campaignSticker, newSticker, packageSticker, preBookCampaignSticker }
}

export const isJetshopProductNew = stockDate => {
   const stockDateParsed = new Date(stockDate)
   let numberOfDaysToAdd = 30
   let result = new Date(
      stockDateParsed.setDate(stockDateParsed.getDate() + numberOfDaysToAdd)
   )
   var currentDate = new Date()
   return result.getTime() > currentDate.getTime()
}

export const formatDate = dateString => {
   // Check if the input is a valid, non-empty string
   if (typeof dateString !== 'string' || dateString.trim() === '') {
      return null;
   }

   // Extract and return the date portion from the date string
   return dateString.split(' ')[0];
}

export const isProductPrebook = product => {
   const findifyPrebook =
      Array.isArray(product?.custom_fields?.['product-stockstatus']) && // Ensure it's an array
      product.custom_fields['product-stockstatus'].length > 0 &&
      product.custom_fields['product-stockstatus'][0] === 'CustomStockStatus1'; // Use strict equality

   const jetshopPrebook = STOCK_ID.PREBOOK.includes(product?.stockStatus?.stockStatusId); // Use strict equality

   const helloRetailPrebook = product?.extraData?.prebook === 'true'; // Use strict equality

   return findifyPrebook || jetshopPrebook || helloRetailPrebook;
};

/**
 * 
 * @param {*} dateStr unformatted datestring
 * @param {*} withTimstamp boolean
 * @returns 
 */
export const formatISO8601Date = (dateStr , withTimstamp) => {
   if (typeof dateStr !== "string" || !dateStr) {
      console.error('Date string is null, undefined or not string.')
      return null
   }

   try {
      const date = new Date(dateStr)

      if (isNaN(date.getTime())) {
         return null
      }

      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')

      let formattedDate = `${year}-${month}-${day}`

      if (withTimstamp) {
         const hours = date.getHours().toString().padStart(2, '0')
         const minutes = date.getMinutes().toString().padStart(2, '0')
         const seconds = date.getSeconds().toString().padStart(2, '0')
         formattedDate = `${formattedDate}:${hours}:${minutes}:${seconds}`
      }

      return formattedDate

   } catch (error) {
      //   console.error('An error occurred while formatting date:', error);
      return null
   }
}


/**
 * Sorts an array of strings in alphabetical order, considering numeric values within the strings.
 * @param {string[]} stringArr - The array of strings to be sorted.
 * @returns {string[]} - The sorted array.
 */
export function sortStringArray(stringArr) {
   // Early return if options is undefined
   if (!stringArr) {
      //   console.error("Options are undefined. Cannot perform sorting.");
      return undefined
   }
   /**
    * Helper function to extract the numeric part from a string.
    * @param {string} str - The input string.
    * @returns {number|null} - The numeric part as a number, or null if not found.
    */
   const getNumericPart = str => {
      // Use a regular expression to find one or more digits (\d+)
      const numericPart = str.match(/\d+/)
      // Convert the numeric part to an integer using parseInt, or return null if not found
      return numericPart ? parseInt(numericPart[0], 10) : null
   }

   // Get the numeric parts from the strings (default to 0 if not found)
   const sortedStrArr = stringArr.slice().sort((a, b) => {
      const numA = getNumericPart(a) || 0
      const numB = getNumericPart(b) || 0

      // Check if the numeric parts are valid numbers
      if (isNaN(numA) || isNaN(numB)) {
         //  Handle the case where the numeric part is not a valid number
         //  console.error("Invalid numeric part detected. Sorting alphabetically.");
         //  Fall back to sorting alphabetically
         return a.localeCompare(b)
      }

      // Compare alphabetically if the numeric parts are equal
      if (numA === numB) {
         return a.localeCompare(b)
      }

      // Compare numerically
      return numA - numB
   })

   // Return the sorted string array
   return sortedStrArr
}


export const getPreBookLangStr = (channelId) => {
   if (typeof channelId !== 'number') {
      return PREBOOK_EN; // Default value for non-number inputs
   }
   switch (channelId) {
     case 1:
       return PREBOOK_SV
     case 2:
       return PREBOOK_DE
     case 3:
      return PREBOOK_NO
     default:
      return PREBOOK_EN
   }
};

export const setLangLinkRoutes = ({channelId, sv, dk, no}) => {
   if (typeof channelId !== 'number') {
      return "" // Default value for non-number inputs
   }
   switch (channelId) {
     case 1:
       return sv
     case 2:
       return dk
     case 3:
      return no
     default:
      return ""
   }
};

/**
 * Function to extract substring before the first question mark
 * @param {*} inputString
 * @returns Extracted substring before the first question mark or full string if no questionmark
 */
export function extractStrBeforeQuestionMark(inputString) {
   if (typeof inputString !== 'string') {
      // If not a string, return an empty string
      return '';
   }
   // Find the index of the first question mark
   const questionMarkIndex = inputString.indexOf('?');
   // If a question mark is found, return the substring before it; otherwise, return the input string
   return questionMarkIndex !== -1 ? inputString.substring(0, questionMarkIndex) : inputString;
}


export function formatRedirectUrl(redirectUrl) {
   if (typeof redirectUrl !== 'string' || redirectUrl.trim() === '') {
      //  console.error("Invalid input: URL must be a non-empty string.");
       return null;
   }
   try {       
       const url = new URL(redirectUrl);
       // Return the pathname, or null if it's not a valid pathname
       return url?.pathname || null;
   } catch (error) {
      //  console.error("Failed to parse URL:", error);
       return null;
   }
}

export const removeAlterantiveStr = (str) => {
   // Check if str is null, undefined, or not a string
   if (typeof str !== 'string' || str == null) {
       return ''; // Return an empty string or any other default value
   }

   return str.replace(/\s?\{alternativ\}/g, "");
};


export const clearInputSearchFieldsById = (ids = ["searchfield-mobile","searchfield-desktop"]) => {
   ids?.forEach((id) => {
     const element = document.getElementById(id);
     if (element?.tagName === 'INPUT') {
       element.value = '';
       element.blur(); // Remove focus from the input field
     }
   });
};

export const isValidString = (possibleStr) =>
!!(typeof possibleStr === 'string' && possibleStr.trim());

export const isInvalidString = (value) => {
   return !value || typeof value !== 'string';
}

export const createGroupedItemsByTypeObj = (itemsArr, typeKeyName, groupArrName = 'items', titleFormatter) => {
   if (!Array.isArray(itemsArr)) { return {};}
   if (typeof typeKeyName !== 'string' || typeKeyName.trim() === '') {return {};}
   return itemsArr.reduce((accObj, item) => {
      const groupType = item?.[typeKeyName];
      // Ignore invalid items
      if (!groupType) { return accObj; }
      // Initialize the group if it doesn't exist
      if (!accObj[groupType]) {
         accObj[groupType] = { 
            [groupArrName]: [], 
            title: titleFormatter ? titleFormatter(groupType) : groupType 
         };
      }
      // Add the item to the correct group
      accObj[groupType][groupArrName].push(item);
      return accObj;
   }, {});
};

export function isArrayWithItems(arr) {
   return Array.isArray(arr) && arr.length > 0;
}

export function isObjectWithKeys(obj) {
   return typeof obj === 'object' && Object.keys(obj).length > 0;
}

export const getPackageStockStatus = packageProducts => {
   if (!Array.isArray(packageProducts)){
       return null;
   }
   const priorityStockStatusIds = [
       STOCK_ID.OUT_OF_STOCK,
       STOCK_ID.PREBOOK,
       STOCK_ID.IN_STOCK,
   ];

   // Find the first product with the highest priority stock status
   for (let stockStatusArray of priorityStockStatusIds) {
       const foundPackageObj = packageProducts?.find(product =>
         stockStatusArray.includes(product?.product?.variants?.values[0]?.stockStatus?.stockStatusId)
       )
       const foundPackageStatus = foundPackageObj?.product?.variants?.values[0]?.stockStatus;
       
       if (foundPackageStatus) {
           return foundPackageStatus;
       }
   }
};

export function getHostname(inputUrl, fallbackUrl) {
   try {
      const url = new URL(inputUrl)
      return url.hostname
   } catch (error) {
      //   console.error(`Invalid URL provided. Defaulting to ${fallbackUrl}`, error);
      return fallbackUrl
   }
 }