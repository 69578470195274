import React from 'react';
import { styled } from 'linaria/react'
import { theme } from '../../Theme';

// import { ReactComponent as ChevronRight } from '../../svg/ChevronAltRight.svg'
import CheckIcon from '../../../svg/CheckIcon.svg'

const Label = styled.label`
   & {
      display: flex;
      align-items: center;
   }

   input[type='checkbox'] {
      border-radius: 4px;
      border: solid 1px #707070;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 0;
      min-width: 1.15rem;
      min-height: 1.15rem;
      display: grid;
      place-content: center;
      cursor: pointer;

      ${theme.below.md}{
         min-width: 1.3rem;
         min-height: 1.3rem;
      }
   }
   input[type="checkbox"]::before {
      content: "";
      width: ${({ showDefaultCheckmarkIcon }) => showDefaultCheckmarkIcon ? '0.85rem' : '0.65em'};
      height: ${({ showDefaultCheckmarkIcon }) => showDefaultCheckmarkIcon ? '0.85em' : '0.65em'};
      transform: scale(0);
      transition: 50ms transform ease-in-out;
      box-shadow: inset 1em 1em ${({ showDefaultCheckmarkIcon }) => showDefaultCheckmarkIcon ? 'transparent' : theme.colors.blue};
      background-image: ${({ showDefaultCheckmarkIcon }) => showDefaultCheckmarkIcon ? `url(${CheckIcon})` : 'none'};
      background-size: contain;
      background-repeat: no-repeat;
      color: ${({ showDefaultCheckmarkIcon }) => showDefaultCheckmarkIcon ? theme.colors.blue : 'transparent'};
    }
    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
`

const VbCheckbox = ({ id, name, label, className, showDefaultCheckmarkIcon = false, ...inputProps }) => {
   return (
      <Label htmlFor={name} className={className} showDefaultCheckmarkIcon={showDefaultCheckmarkIcon}>
         <input
            id={id}
            type="checkbox"
            {...inputProps}
         />
         <span>{label}</span>
      </Label>
   )
}

export default VbCheckbox;