import { styled } from 'linaria/react'
import { theme } from '../../Theme'

export default styled('main')`
   background: ${theme.colors.background};
   & > div:first-of-type {
      ${theme.below.md} {
         margin-top: -1.75rem;
      }
   }
`
