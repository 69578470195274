import React, { useContext } from 'react'
import {styled} from 'linaria/react'
import { getStringByPropertyName } from '../../Core/Util/Helpers'

const ContactBlock = styled.div`
   text-align: center;
   background-image: ${props => `url("${props.image}")`};
   background-position: center;
   background-size: cover;

   p {
      margin: 0;
   }
`

const FooterContact = ({contactBlocks}) => {
   return (
      <>
         {contactBlocks?.map((contactBlock, i) => {
            const title = getStringByPropertyName(
               contactBlock?.properties,
               'title'
            )
            const image = getStringByPropertyName(
               contactBlock?.properties,
               'image'
            )
            const content = getStringByPropertyName(
               contactBlock?.properties,
               'content'
            )
            return (
               <ContactBlock key={i} className="col-12 col-lg-4 p-64" image={image}>
                  <h2 className="mb-3 text-uppercase">{title}</h2>
                  <div dangerouslySetInnerHTML={{__html: content}}/>
               </ContactBlock>
            )
         })}
      </>
   )
}
 
export default FooterContact;