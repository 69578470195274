import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../../Theme'
import { pxToRem } from '../../../utils/css'
import { useSiteSettingsQuery } from '../../../hooks/useSiteSettings'
import {
   TOP_BAR_SETTING,
   USP,
   VB_SIDE_MENU_LINK,
} from '../../CustomElements/elementTypes'
import ResponsiveUspList from './Components/TopBanner/ResponsiveUspList'
import TrustPilotBanner from './Components/TopBanner/TrustPilotBanner'
import SideCategoryNav from './Components/TopBanner/SideCategoryNav'
import { getStringByPropertyName } from '../../Core/Util/Helpers'

const TopBannerWrapper = styled.div`
   background-color: ${theme.colors.siteBlue};
   color: ${theme.colors.white};
   padding-top: ${pxToRem(10)};
   padding-bottom: ${pxToRem(10)};
   min-height: ${pxToRem(36)};
   width: 100%;
   overflow-x: hidden;
   z-index: 100;
   padding-right: 1rem !important;
   padding-left: 1rem !important;
   @media (min-width: 1800px) {
      padding-right: 8rem !important;
      padding-left: 8rem !important;
   }
`

const TopBanner = () => {
   const [getSetting, loading] = useSiteSettingsQuery();
   const topBarSettings = getSetting(null, TOP_BAR_SETTING);
   
   const sideMenuLinks = topBarSettings?.children?.filter(
     c => c?.type === VB_SIDE_MENU_LINK
   ) || [];

   const usps = topBarSettings?.children?.filter(
      c => c?.type === USP
    ) || [];
 
   const topBarSettingsProps = topBarSettings?.properties;
   const splitNumber = parseInt(getStringByPropertyName(topBarSettingsProps, "splitCategoriesItemNumber") || '5', 10);
 
   const leftSideMenuLinks = sideMenuLinks.slice(0, splitNumber);
   const rightSideMenuLinks = sideMenuLinks.slice(splitNumber);
 
   return (
      <TopBannerWrapper className="gap-5 d-flex align-items-center justify-content-start justify-content-sm-between px-8 position-relative">
         <SideCategoryNav categories={leftSideMenuLinks} />
         <ResponsiveUspList usps={usps} />
         <div className="d-flex justify-content-end align-items-center">
            <SideCategoryNav categories={rightSideMenuLinks} />
            <TrustPilotBanner />
         </div>
      </TopBannerWrapper>
   )
}

export default TopBanner