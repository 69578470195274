import { useContext } from "react";
import { SiteContext } from "../../Global/SiteContext";
import { isJetshopProductNew, isTimestampWithin30Days } from "../Util/Helpers";

const UseFormatProduct = (product) => {
    const { showVat } = useContext(SiteContext)

    //Findify product
    if(product.hasOwnProperty('availability')){
        let obj = {title: product?.title, brandName: product?.brand, articleNumber: product?.sku[0], imageUrl: product?.image_url ?? product?.thumbnail_url, url: product?.product_url, hasVariants: product?.variants?.length > 1, brand: product?.brand, buyable: product?.availability?.status == "available"};
      
        obj.isPreBook = product?.custom_fields?.["product-stockstatus"]?.length > 0 && product?.custom_fields?.["product-stockstatus"][0] == "CustomStockStatus1";
        obj.buyable = (product?.custom_fields?.["product-stockstatus"]?.length > 0 && product?.custom_fields?.["product-stockstatus"][0] == "ExactNumber") || (product?.custom_fields?.["product-stockstatus"]?.length > 0 && product?.custom_fields?.["product-stockstatus"][0] == "InStock") || obj.isPreBook;
        obj.isNew = isTimestampWithin30Days(product?.created_at);
        obj.isPackage = product?.custom_fields?.["paketprodukt"]?.length > 0 && product?.custom_fields?.["paketprodukt"][0] == "True";
        if(!product.hasOwnProperty('compare_at')){
            obj.currentPrice = product?.price[0];
            obj.exVatCurrentPrice= Math.round(parseFloat(product?.custom_fields?.price_excl_vat[0]));
        }else{
            obj.currentPrice = product?.price[0];
            obj.oldPrice = product?.compare_at;
            obj.exVatCurrentPrice = Math.round(parseFloat(product?.custom_fields?.discounted_price_excl_vat[0]));
            obj.exVatOldPrice = Math.round(parseFloat(product?.custom_fields?.price_excl_vat[0]));
            obj.onSale = obj.isPackage ? false : true ;
        }
        return obj;
    }

    //Jetshop product
    //Implement fallback image
    if(product.hasOwnProperty('articleNumber')){
        let obj = {isPackage: product?.isPackage, title: product?.name, brandName: product?.subName, articleNumber: product?.articleNumber, imageUrl: product?.images[0]?.url, url: product?.primaryRoute?.path, hasVariants: product?.variants?.values?.length > 1, buyable: product?.stockStatus?.buyable };

        obj.variants = product?.variants?.values;
        obj.isPreBook = product?.stockStatus?.stockStatusId == "14";
        obj.isNew = isJetshopProductNew(product?.publishedDate);
        obj.isPackageItem = product?.isPackageItem;
        // No need for this check at the moment, keeps the code for future reference.
        // Check if product is a packageItem with percentDiscount
        // if (product?.discount?.percentage && product?.discount?.percentage > 0 && product?.isPackageItem) {
        //     obj.oldPrice = product?.price?.incVat;
        //     obj.currentPrice = product?.price?.incVat - ( product?.price?.incVat * product?.discount?.percentage )
        //     obj.exVatOldPrice = product?.price?.exVat
        //     obj.exVatCurrentPrice = product?.price?.exVat - ( product?.price?.exVat * product?.discount?.percentage )
        // } else 
        if (product?.previousPrice?.incVat == product?.price?.incVat){
            obj.currentPrice = product?.price?.incVat;
            obj.exVatCurrentPrice = product?.price?.exVat;
        } else {
            obj.currentPrice = product?.price?.incVat;
            obj.oldPrice = product?.previousPrice?.incVat
            obj.exVatCurrentPrice = product?.price?.exVat;
            obj.exVatOldPrice = product?.previousPrice?.exVat
        }

        if (obj?.oldPrice && obj?.currentPrice) {
           // Set onSale to true if oldPrice and currentPrice and are not equal, otherwise false
           obj.onSale = obj.currentPrice !== obj.oldPrice;
        }
      
        if(product?.stockStatus?.buyable == undefined){
            obj.stockStatusMissing = true;
        }
        return obj;
    }

    //HelloRetail product
    if(product.hasOwnProperty('extraData')){
        let obj = {title: product?.title, brandName: product?.brand, articleNumber: product?.productNumber, imageUrl: product?.imgUrl, url: product?.url, hasVariants: product?.extraData?.hasVariants != "false", buyable: product?.inStock, isPreBook: product?.extraData?.prebook == "true"};
        obj.variants = [];

        if(product?.price == product?.oldPrice){
            obj.currentPrice = product?.price;
            obj.exVatCurrentPrice = product?.priceExVat;
        }else{
            obj.currentPrice = product?.price;
            obj.oldPrice = product?.oldPrice
            obj.exVatCurrentPrice = product?.priceExVat;
            obj.exVatOldPrice = product?.oldPriceExVat;
            obj.onSale = true;
        }
        return obj;
    }
};

export default UseFormatProduct;