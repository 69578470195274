import React, { useContext } from 'react'
import { styled } from 'linaria/react'
import UseFormatProduct from '../Hooks/UseFormatProduct'
import { theme } from '../../Theme'
import WhishlistButton from '../Buttons/WishlistButton'
import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext'
import {
   formatPrice,
   getStickersFromProductSettings,
   getStringByPropertyName,
   stripQueryParamFromUrl,
} from '../Util/Helpers'
import { ReactComponent as PrebookIcon } from '../../../svg/Prebook.svg'
import { ReactComponent as OutOfStockIcon } from '../../../svg/OutOfStock.svg'
import { ReactComponent as InStockIcon } from '../../../svg/InStock.svg'
import Image from '@jetshop/ui/Image/Image'
import PriceSection from './PriceSection'
import { useIntl } from '@jetshop/intl'
import Link from '../Link'
import { useAddToCart } from '../../../hooks/useAddToCart'
import { useHandlePackageStatus } from '../../../hooks/useHandleProductPackageStatus'

const ProductCardContainer = styled.div`
   a {
      font-family: var(--font-grotesk);
      color: white;
      text-decoration: none;
   }

   &.product-card {
      background: white;
      overflow: hidden;
      box-shadow: none;
      scale: 1;
      transition: all 0.5s ease;
      width: 100%;
   }

   &.product-card:hover {
      transition: box-shadow 0.5s, scale 0.3s ease;
      box-shadow: 0 0 0 6px rgba(25, 81, 120, 0.2);
      scale: 1.005;
   }

   .product-card-image [data-flight-image-placeholder] img,
   .product-card-image [data-flight-image] img {
      margin-top: 0.5rem;
      padding: 1rem;
   }

   .product-card-logos {
      width: 100%;
      position: absolute;
      bottom: 0;
   }

   .product-card-wishlist {
      width: 100%;
      position: absolute;
      top: 0;
   }

   .product-card-sku {
      font-size: 0.75rem;
      font-family: var(--font-grotesk);
   }

   .product-card-stock-container {
      span {
         font-family: var(--font-grotesk);
         font-size: 0.75rem;
         font-weight: 700;
         svg {
            width: 24px;
            height: 24px;
         }
      }
   }

   .product-card-title {
      font-size: 1rem;
      font-weight: 700;
   }

   .product-card-title a {
      font-family: var(--font-industry);
      color: black;
   }

   .product-card-cta {
      font-family: var(--font-industry);
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      text-align: center;
      background-color: #59a021;
      transition: all 0.3s ease;
      line-height: 1;

      ${theme.below.md} {
         font-size: 0.9375rem;
      }
   }

   .product-card-cta:hover {
      background-color: #4f8e1c;
      transition: all 0.3s ease;
   }

   .heart-animation {
      //background-image: url('../images/sprite/heart-sprite.svg');
      background-repeat: no-repeat;
      background-size: 2900%;
      background-position: left;
      height: 80px;
      width: 80px;
      margin-right: -19px;
      margin-top: -19px;
      cursor: pointer;
      right: 0;
   }

   .product-card-cta-prebook {
      color: var(--color-black);
      background-color: var(--color-prebook);
      transition: all 0.3s ease;
   }

   .product-card-cta-prebook:hover {
      background-color: var(--color-prebook-hover);
      transition: all 0.3s ease;
   }

   .product-card-cta-outofstock {
      color: var(--color-black);
      background-color: var(--color-outofstock);
      transition: all 0.3s ease;
   }

   .product-card-cta-outofstock:hover {
      background-color: var(--color-outofstock-hover);
      transition: all 0.3s ease;
   }

   .product-card-cta-default {
      color: var(--color-white);
      background-color: var(--color-vb-blue);
      transition: all 0.3s ease;
   }

   .product-card-cta-default:hover {
      background-color: var(--color-vb-blue-darker);
      transition: all 0.3s ease;
   }

   .animate-heart {
      animation: heart-burst 0.8s steps(28) forwards;
   }
   @keyframes heart-burst {
      0% {
         background-position: left;
      }
      100% {
         background-position: right;
      }
   }

   .sticker-max {
      ${theme.below.md} {
         min-width: 25px;
         max-width: 100%;
      }
   }
`

const ProductListCard = ({ product, productSettings, closeModal }) => {
   const { selectedChannel } = useContext(ChannelContext)
   let {
      title,
      brandName,
      articleNumber,
      imageUrl,
      url,
      isPreBook,
      buyable,
      hasVariants,
      onSale,
      isNew,
      isPackage,
      stockStatusMissing,
   } = UseFormatProduct(product)
   const t = useIntl()
   const { addProductToCart } = useAddToCart()

   const { packageStatus, loading } = useHandlePackageStatus({
      articleNumber,
      isPackage,
   })
   const isPreBookValue = loading
      ? undefined
      : packageStatus
      ? packageStatus?.stockStatusId == '14'
      : isPreBook
   const buyableValue = loading
      ? undefined
      : packageStatus
      ? packageStatus?.buyable
      : buyable

   let strippedUrl = stripQueryParamFromUrl(url, 'att')

   const {
      campaignSticker,
      newSticker,
      packageSticker,
      preBookCampaignSticker,
   } = getStickersFromProductSettings(productSettings)

   const addToCart = e => {
      if (stockStatusMissing) {
         return
      }
      e.preventDefault()
      if (!buyableValue) {
         return
      }
      addProductToCart(product, articleNumber, 1, isPackage)
   }

   // Dont show title or just the brandName if title is falsy/undefined
   const titleWithPossibleBrandName = (
      // <div className="line-clamp-3">
      <Link
         to={strippedUrl}
         className="line-clamp-3"
         onNavigation={() => closeModal && closeModal()}
      >
         {title && brandName ? `${brandName} ${title}` : title}
      </Link>
      // </div>
   )

   return (
      <ProductCardContainer className="product-card br-8 d-flex flex-column">
         <div className="position-relative">
            <Link
               to={strippedUrl}
               onNavigation={() => closeModal && closeModal()}
            >
               <Image
                  className="product-card-image p-16"
                  src={imageUrl}
                  alt={title}
                  aspect="1:1"
               ></Image>
            </Link>
            <div className="row m-0 d-flex align-items-start product-card-wishlist pe-none">
               <div className="col-12 d-flex justify-content-end p-0">
                  <div className="pe-auto">
                     {/* The WhishListButton-comp-css is overrided in this file 
                   see heart-animation, animate-heart */}
                     <WhishlistButton
                        articleNumber={articleNumber}
                        title={title}
                     ></WhishlistButton>
                  </div>
               </div>
            </div>
            <div className="row m-0 d-flex align-items-end product-card-logos pb-8">
               <div className="d-flex justify-content-start flex-wrap">
                  {campaignSticker && onSale && isPreBookValue && (
                     <img
                        className="img-fluid sticker-max"
                        alt="Campaign sticker"
                        src={preBookCampaignSticker}
                     />
                  )}
                  <div className="d-flex justify-content-start col-md-5">
                     {campaignSticker && onSale && !isPreBookValue && (
                        <img
                           className="img-fluid sticker-max"
                           alt="Campaign sticker"
                           src={campaignSticker}
                        />
                     )}
                     {newSticker && isNew && (
                        <img
                           className="img-fluid sticker-max"
                           alt="New sticker"
                           src={newSticker}
                        />
                     )}
                     {packageSticker && isPackage && (
                        <img
                           className="img-fluid sticker-max"
                           alt="Package sticker"
                           src={packageSticker}
                        />
                     )}
                  </div>
               </div>
               <div className="col-6 d-flex justify-content-end pr-32"></div>
            </div>
         </div>
         <div className="product-card-description d-flex flex-column flex-grow-1 p-16">
            <div className="product-card-sku">ARTNR: {articleNumber}</div>
            <div className="product-card-title lh-1 pb-8 pt-1 d-flex flex-grow-1">
               {titleWithPossibleBrandName}
            </div>
            <div className="row d-flex align-items-center">
               <PriceSection
                  product={product}
                  selectedChannel={selectedChannel}
               ></PriceSection>
               {/* <div className="col-12 d-flex product-card-stock-container">
                  <span className="d-flex align-items-center">
                     {isPreBookValue ? (
                        <>
                           <PrebookIcon className="pr-8"></PrebookIcon>
                           {t('book in advance')}
                        </>
                     ) : buyable ? (
                        <>
                           <InStockIcon className="pr-8"></InStockIcon>
                           {t('in stock')}
                        </>
                     ) : (
                        <>
                           <OutOfStockIcon className="pr-8"></OutOfStockIcon>
                           {t('Out of stock')}
                        </>
                     )}
                  </span>
               </div> */}
               {/* <div className="col-12 d-flex">
                  <span className="product-card-stock d-flex align-items-center">
                     <Title size="0.75rem" weight={700}>
                        4 av 5 (100st)
                     </Title>
                  </span>
               </div> */}
            </div>
         </div>
         {hasVariants ? (
            <Link
               className="product-card-cta-link"
               to={strippedUrl}
               onNavigation={() => closeModal && closeModal()}
            >
               <div className="product-card-cta py-16 px-8">
                  {t('show product')}
               </div>
            </Link>
         ) : (
            <Link
               className="product-card-cta-link"
               to={strippedUrl}
               onClick={addToCart}
            >
               {stockStatusMissing ? (
                  <div className={`product-card-cta py-16 px-8`}>
                     Gå till produkt
                  </div>
               ) : (
                  <div
                     className={`product-card-cta ${
                        isPreBookValue
                           ? 'product-card-cta-prebook'
                           : buyableValue
                           ? ''
                           : 'product-card-cta-outofstock'
                     } py-16 px-8`}
                  >
                     {isPreBookValue
                        ? t('book in advance')
                        : buyableValue
                        ? t('add to cart')
                        : t('Out of stock')}
                  </div>
               )}
            </Link>
         )}
      </ProductCardContainer>
   )
}

export default ProductListCard

// t('add to cart')
