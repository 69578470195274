import React from 'react';
import { styled } from 'linaria/react'

const ProductCardSkeleton = styled.div`
   & {
      width: 100%;
      background: var(--color-white);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
      scale: 1;
      transition: all 0.5s ease;
   }
   .product-card-cta {
        font-family: var(--font-industry);
        font-weight: 700;
        text-transform: uppercase;
        color: white;
        text-align: center;
        background-color: #59a021;
        transition: all 0.3s ease;
        line-height: 1;
        border-radius: 0 0 8px 8px;
    }
`

const ListCardSkeleton = () => {
    return (
       <ProductCardSkeleton className="product-card br-8 d-flex flex-column">
          <div className="position-relative">
             <span
                style={{
                   width: '100%',
                   aspectRatio: '1/1',
                   objectFit: 'contain',
                   background: '#545454',
                   borderRadius: '8px 8px 0 0',
                }}
                className="placeholder placeholder-wave"
             ></span>
          </div>
          <div className="product-card-description d-flex flex-column flex-grow-1 p-16 placeholder-wave">
             <div className="placeholder w-25"></div>
             <div className="placeholder w-75 mt-8"></div>
             <div className="row d-flex align-items-center">
                <div className="col-12 pe-0">
                   <span className="placeholder w-25"></span>
                   <span className="placeholder w-50"></span>
                </div>
                <div className="col-12">
                   <span className="placeholder w-25"></span>
                </div>
                <div className="col-12 d-flex">
                   <span className="placeholder w-50"></span>
                </div>
             </div>
          </div>
          <button className="add-to-cart product-card-cta instock-color py-16 d-flex justify-content-center">
             <span className="placeholder placeholder-wave w-50 "></span>
          </button>
       </ProductCardSkeleton>
    )
}
 
export default ListCardSkeleton;