const verktygsboden = {
   colors: {
      secondary: '#A61200',
      accent: '#FFD336',
      success: '#59A021',
      background: '#e4e4e4',
      navigationBackground: '#F4F3F4',
      favouriteGrey: '#4A4A49',
      greyDark: '#282827',
      greyLight: '#EFEFEF',
      greyMedium: '#878787',
      green: '#59A021',
      menuGrey: '#282827',
      news: '#FFD336',
      red: '#A61200',
      lightErrorRed: '#ff513c',
      searchGrey: '#1E1E1D',
      siteBlue: '#195178',
      tableLines: '#DCDCDC',
      text: '#3C3C3C',
      textLight: '#878787',
      verktygsbodenBlue: '#003057',
   },
}

module.exports = {
   default: {
      colors: {
         primary: verktygsboden.colors.siteBlue,
         primaryDark: verktygsboden.colors.verktygsbodenBlue,
         blue: verktygsboden.colors.siteBlue,
         blueDark: verktygsboden.colors.verktygsbodenBlue,
         black: 'black',
         white: 'white',
         darkerGrey: '#767676',
         darkgrey: '#333',
         grey100: '#EFEFEF',
         grey: verktygsboden.colors.greyMedium,
         compliment: '#A0C320',
         complimentDark: '#809C19',
         highlight: '#2f80ed',
         lightgrey: '#E8E8E8',
         link: verktygsboden.colors.siteBlue,
         loadingBar: '#2f80ed',
         main: verktygsboden.colors.text,
         mediumgrey: '#9a9a9a',
         red: verktygsboden.colors.secondary,
         redDark: '#A02F21',
         tablegrey: '#DCDCDC',
         text: verktygsboden.colors.text,
         darkTransparentOverlay: 'rgb(0, 0, 0, 0.5)',
         ...verktygsboden.colors,
      },
      fontWeights: {
         small: 300,
         regular: 400,
         medium: 500,
         bold: 700,
      },
      font: {
         family: {
            heading: '"Industry"',
            body: '"HKGrotesk"',
         },
         weight: {
            light: 200,
            small: 300,
            regular: 400, // regular
            medium: 500, // medium
            semiBold: 600,
            bold: 700, // bold
         },
         size: {
            responsiveHeaderM: 'calc(0.8vw + 1.15rem)',
            responsiveHeaderS: 'calc(0.7vw + 1.05rem)',
            xxs: 10 / 16 + 'rem',
            xs: 12 / 16 + 'rem',
            s: 14 / 16 + 'rem',
            m: '1rem',
            l: 18 / 16 + 'rem',
            xl: 20 / 16 + 'rem',
            xxl: 30 / 16 + 'rem',
            xxxl: 40 / 16 + 'rem',
            body: '1rem',
         },
      },
      breakpoints: {
         xs: '20rem', // 320
         sm: '36rem', // 576
         md: '48rem', // 768
         lg: '62rem', // 992
         xl: '75rem', // 1200
         xxl: '87.5rem', // 1400
         xxxl: '100rem', // 1600
      },
      boxShadow: {
         card: '0px 1px 10px rgba(0, 0, 0, 0.07)',
      },
      fontSizes: [
         '12px',
         '14px',
         '16px',
         '20px',
         '24px',
         '32px',
         '48px',
         '64px',
         '72px',
      ],
      space: [
         '0px', // 0
         '5px', // 1
         '8px', // 2
         '16px', // 3
         '24px', // 4
         '32px', // 5
         '40px', // 6
         '48px', // 7
         '56px', // 8
         '64px', // 9
      ],
      spacing: {
         xxs: '1rem',
         xs: 20 / 16 + 'rem',
         s: 24 / 16 + 'rem',
         sm: 32 / 16 + 'rem',
         m: 48 / 16 + 'rem',
         l: 64 / 16 + 'rem',
      },
      fonts: {
         primary: 'Open Sans',
         secondary: ['Industry'],
      },
   },
   alt: {
      colors: {
         blue: 'blue',
         main: 'black',
      },
   },
}
