import React, { useState, useEffect } from 'react'
import {
   Menu as MenuIcon,
   Cancel as CancelIcon,
   ArrowLeft,
   ArrowRight,
} from 'iconoir-react'
import { styled } from 'linaria/react'
import { css } from 'linaria'
import Title from '../../Core/Title'
import VbDrawer from '../../Core/VbDrawer'
import {
   getStringByPropertyName,
   sortObjectsByProperty,
   removeAlterantiveStr
} from '../../Core/Util/Helpers'
import VatSelector from './Components/VatSelector'
import {
   VB_MENU_FEATURED_CATEGORY,
   VB_MOBILE_MENU_CATEGORY,
} from '../../CustomElements/elementTypes'
import { theme } from '../../Theme'
import { useIntl } from '@jetshop/intl'
import ChannelSelector from './ChannelSelector'
import Link from '../../Core/Link'


const DrawerOverride = css`
   border-radius: 1.5rem 0rem 0rem 1.5rem !important;
   ${theme.below.xl} {
      padding-left: 1rem !important;
      padding-right: 1.5rem !important;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
   }
   overflow: hidden;
`

const MobileDrawerContainer = styled.div`
   flex-direction: column;
   height: 100%;
   display: flex;

   .cancel-icon {
      margin-bottom: 2px;
   }

   &.page-hidden {
      display: none !important;
   }

   &.page-visible {
      animation: growDown 250ms ease-in-out forwards;
      transform-origin: right center;
   }

   // Override child comp label fontsize
   .form-check-label {
      font-size: 14px;
   }

   @keyframes growDown {
      from {
         transform: translateX(100%);
      }
      to {
         transform: translateX(0%);
      }
   }
`

const MobileCategoryContainer = styled.div`
   div {
      padding-bottom: 0.5rem;
   }

   a {
      color: var(--color-black);
   }

   a > span {
      margin: 8px 0;
   }

   svg {
      color: var(--color-vb-blue);
   }

   div:not(:first-child) {
      padding-top: 0.5rem;
   }

   div:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
   }
`

const MobileCategoryContent = styled.div`
   border-top: 1px solid #dcdcdc;
   border-bottom: 1px solid #dcdcdc;
   margin-right: -1.5rem;
   margin-left: -1rem;
`

const MobileDiscoverContent = styled.div`
   flex-grow: 1;
   /* img {
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.07);
      border-radius: 80px;
      border: solid 1px #dcdcdc;
      object-fit: cover;
      height: 4rem;
      width: 4rem;
   } */
`

const MobileDrawerFooter = styled.div`
   height: 64px;
   background-color: #195178;
   margin-right: -1.5rem;
   margin-left: -1rem;
   margin-bottom: -1rem;
   border-radius: 0rem 0rem 0rem 1.5rem;
   display: flex;
   align-items: center;
   justify-content: space-between;

   .channel-name {
      display: none;
      ${theme.above.sm} {
         display: block;
      }
   }

   a {
      ${theme.below.sm} {
         padding-bottom: 3px;
      }
   }
`

const FirstPage = ({
   categories,
   mobileMenuCms,
   desktopMenuItems,
   setLevelOneCategory,
   isOpen,
   setIsOpen,
   ...props
}) => {
   const t = useIntl()
   const cmsCategories = mobileMenuCms?.children?.filter(
      c => c.type == VB_MOBILE_MENU_CATEGORY
   )

   // Remove from cms?
   // const featuredCategories = mobileMenuCms?.children?.filter(
   //    c => c.type == VB_MENU_FEATURED_CATEGORY
   // )

   // Remove from cms?
   // const discoverTitle = getStringByPropertyName(
   //    mobileMenuCms?.properties,
   //    'discoverTitle'
   // )

   const mainTitle = getStringByPropertyName(mobileMenuCms?.properties, 'title')

   const onCategoryClick = (event, cat) => {
      event.preventDefault()
      setLevelOneCategory(cat)
   }

   return (
      <MobileDrawerContainer {...props}>
         <>
            <div className="row mb-16">
               <div className="col-1"></div>
               <div className="col-9 d-flex justify-content-center align-items-center">
                  <Title size="18px" weight={700}>
                     {mainTitle}
                  </Title>
               </div>
               <div className="col-2 d-flex justify-content-end">
                  <CancelIcon
                     strokeWidth={2.0}
                     width={32}
                     height={32}
                     className="cancel-icon"
                     onClick={() => setIsOpen(!isOpen)}
                  ></CancelIcon>
               </div>
            </div>
            <MobileCategoryContent
               style={{ borderBottom: 'none', overflowY: 'auto' }}
            >
               <div className="w-100 my-3 ps-3 pe-3">
                  <MobileCategoryContainer>
                     {cmsCategories?.map((cmsCat, i) => {
                        const icon = getStringByPropertyName(
                           cmsCat?.properties,
                           'icon'
                        )
                        const altText = getStringByPropertyName(
                           cmsCat?.properties,
                           'altText'
                        )

                        const id = getStringByPropertyName(
                           cmsCat?.properties,
                           'categoryId'
                        )

                        const category = categories?.find(c => c.id == id)

                        // If category is undefined, it's probably hidden or does not exist,
                        // and the cmsCategory item will not be rendered.
                        return (
                           category && (
                              <div
                                 key={i}
                                 onClick={e => onCategoryClick(e, category)}
                                 style={{cursor: "pointer" }}
                                 // style={{cursor: "e-resize" }}
                              >
                                 {/* Keep this as a link if we want to be able to navigate with link,
                                 onClick on parent overrides the link click */}
                                 <Link
                                    className="d-flex justify-content-between align-items-center"
                                    // to={category?.primaryRoute?.path}
                                    // onNavigation={() => setIsOpen(false)}
                                 >
                                    <span className="d-flex gap-2 border-0">
                                       {icon && <img src={icon} alt={altText ?? "category icon"} />}
                                       <Title size="16px" fontFamily="Industry">
                                          {removeAlterantiveStr(category?.name)}
                                       </Title>
                                    </span>
                                    <ArrowRight
                                       strokeWidth={2.0}
                                       width={32}
                                       height={32}
                                    ></ArrowRight>
                                 </Link>
                              </div>
                           )
                        )
                     })}
                     {desktopMenuItems?.map((cat, i) => {
                        const catLink = getStringByPropertyName(
                           cat?.properties,
                           'link'
                        )
                        const title = getStringByPropertyName(
                           cat?.properties,
                           'text'
                        )
                        return (
                           <div key={i}>
                              <Link
                                 className="d-flex justify-content-between align-items-center"
                                 to={catLink}
                                 onNavigation={() => setIsOpen(false)}
                              >
                                 <Title size="16px" fontFamily="Industry">
                                    {removeAlterantiveStr(title)}
                                 </Title>
                                 {/* Hide arrow on direct links/routes */}
                                 {/* <ArrowRight
                                    strokeWidth={2.0}
                                    width={32}
                                    height={32}
                                 ></ArrowRight> */}
                              </Link>
                           </div>
                        )
                     })}
                  </MobileCategoryContainer>
               </div>
            </MobileCategoryContent>

            {/* Keeping this as a spacer between bottom menu and menu items */}
            <MobileDiscoverContent></MobileDiscoverContent>

            <MobileDrawerFooter className="px-32 py-64">
               <div>
                  <ChannelSelector></ChannelSelector>
               </div>
               <VatSelector></VatSelector>
            </MobileDrawerFooter>
         </>
      </MobileDrawerContainer>
   )
}

const SecondPage = ({
   category,
   isOpen,
   setIsOpen,
   setLevelOneCategory,
   ...props
}) => {
   const [activeCategory, setActiveCategory] = useState({})
   const [categoryQueue, setCategoryQueue] = useState([])

   const enqueueCategory = category => {
      setCategoryQueue([...categoryQueue, category])
   }

   const dequeueCategory = () => {
      const newCategoryQueue = [...categoryQueue]
      const newActiveCategory = newCategoryQueue.pop()
      setActiveCategory(newActiveCategory)
      setCategoryQueue(newCategoryQueue)
   }

   useEffect(() => {
      setActiveCategory(category)
   }, [category])

   const goToPreviousLevel = () => {
      if (activeCategory?.level == 1) {
         setLevelOneCategory(null)
         return
      }
      dequeueCategory()
   }

   const goToNextLevel = cat => {
      enqueueCategory(activeCategory)
      setActiveCategory(cat)
   }

   return (
      <MobileDrawerContainer {...props}>
         <div className="d-flex justify-content-between align-items-center mb-16">
            <div>
               <ArrowLeft
                  strokeWidth={2.0}
                  width={32}
                  height={32}
                  onClick={goToPreviousLevel}
               ></ArrowLeft>
            </div>
            <div>
               <Title size="18px" weight={700}>
                  {activeCategory?.name}
               </Title>
            </div>
            <div>
               <CancelIcon
                  strokeWidth={2.0}
                  width={32}
                  height={32}
                  className="cancel-icon"
                  onClick={() => setIsOpen(!isOpen)}
               ></CancelIcon>
            </div>
         </div>
         <MobileCategoryContent
            style={{ borderBottom: 'none', overflowY: 'auto' }}
         >
            <div className="w-100 my-3 ps-3 pe-3">
               <MobileCategoryContainer>
                  <div>
                     <Link
                        to={activeCategory?.primaryRoute?.path}
                        onNavigation={() => setIsOpen(false)}
                     >
                        <Title size="16px" fontFamily="Industry" weight={700}>
                           Visa allt inom {removeAlterantiveStr(activeCategory?.name)}
                        </Title>
                     </Link>
                  </div>
                  {sortObjectsByProperty(
                     activeCategory?.subcategories ?? [],
                     'name'
                  )?.map((cat, i) => {
                     return (
                        <div
                           key={i}
                           className="d-flex justify-content-between align-items-center"
                        >
                           <Link
                              to={cat?.primaryRoute?.path}
                              onNavigation={() => setIsOpen(false)}
                           >
                              <Title size="16px" fontFamily="Industry">
                                 {removeAlterantiveStr(cat?.name)}
                              </Title>
                           </Link>
                           {cat?.hasSubcategories && (
                              <ArrowRight
                                 strokeWidth={2.0}
                                 width={32}
                                 height={32}
                                 onClick={() => goToNextLevel(cat)}
                              ></ArrowRight>
                           )}
                        </div>
                     )
                  })}
               </MobileCategoryContainer>
            </div>
         </MobileCategoryContent>
      </MobileDrawerContainer>
   )
}

const MobileMenu = ({
   categories,
   mobileMenuCms,
   desktopMenuItems,
   isOpen,
   setIsOpen,
}) => {
   const [levelOneCategory, setLevelOneCategory] = useState(null)

   return (
      <VbDrawer
         isOpen={isOpen}
         setIsOpen={setIsOpen}
         backgroundColor={'white'}
         overrideDefault={true}
         className={DrawerOverride}
      >
         <FirstPage
            categories={categories}
            mobileMenuCms={mobileMenuCms}
            desktopMenuItems={desktopMenuItems}
            setLevelOneCategory={setLevelOneCategory}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className={levelOneCategory ? 'page-hidden' : 'page-visible'}
         ></FirstPage>

         <SecondPage
            category={levelOneCategory}
            setLevelOneCategory={setLevelOneCategory}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className={!levelOneCategory ? 'page-hidden' : 'page-visible'}
         ></SecondPage>
      </VbDrawer>
   )
}

export default MobileMenu
