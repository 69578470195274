import React, { useState } from 'react'
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig'
import { User, LogOut } from 'iconoir-react'
import { useQuery } from '@apollo/react-hooks'
import customerNameQuery from '../../../../src/components/MyPages/CustomerNameQuery.gql'
// import customerNameQuery from '../../../MyPages/CustomerNameQuery.gql';
import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine'
import { useIntl } from '@jetshop/intl'

import { styled } from 'linaria/react'
import useAuth from '@jetshop/core/components/AuthContext/useAuth'
import Link from '../../Core/Link'
import Title from '../../Core/Title'

const UserFooterContainer = styled.div`
   a svg {
      width: 30px;
      height: 30px;
   }
`

const UserFieldFooter = () => {
   const { loggedIn } = useAuth()
   const { routes } = useShopConfig()
   const t = useIntl()

   const { data, loading } = useQuery(customerNameQuery, {
      skip: !loggedIn,
   })

   const fullName = `${data?.customer?.billingAddress?.firstName} ${data?.customer?.billingAddress?.lastName}`

   return (
      <UserFooterContainer className="mb-2">
         <Link to={routes.myPages.path} className="hover-opacity">
            <div className="d-flex gap-2 mt-2">
               <User strokeWidth={3.0}></User>
               <h4 style={{}}>{t('My Pages')}</h4>
            </div>
            {data?.customer && (
               <Title size="16px" weight={600}>
                  {fullName}
               </Title>
            )}
         </Link>
      </UserFooterContainer>
   )
}

export default UserFieldFooter
