import React, { useState } from 'react'
import { Menu as MenuIcon, Cancel as CancelIcon } from 'iconoir-react'
import { styled } from 'linaria/react'
import { css } from 'linaria'
import 'react-modern-drawer/dist/index.css'
import Title from '../../Core/Title'
import { theme } from '../../Theme'
import VbDrawer from '../../Core/VbDrawer'
import { Above, Below } from '@jetshop/ui/Breakpoints'
import MobileMenu from './MobileMenu'
import MenuButton from '../../Core/Buttons/MenuButton'
import { useSiteSettingsQuery } from '../../../hooks/useSiteSettings'
import { VB_DRAWER_MENU } from '../../CustomElements/elementTypes'
import { getStringByPropertyName } from '../../Core/Util/Helpers'
import { useIntl } from '@jetshop/intl'
import Link from '../../Core/Link'

const MenuContainer = styled.div`
   background-color: #195178;
   color: #fff;
   transition: all 0.5s ease;

   button:hover {
      opacity: 0.75;
      transition: all 0.5s ease;
   }

   span {
      font-family: var(--font-industry);
      font-weight: 600;
      line-height: initial;
   }

   button {
      display: flex;
      align-items: center;
      background: none;
      color: #fff;
   }

   .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
   }
`

const LinksContainer = styled.div`
   a {
      display: block;
   }

   a:nth-child(3n) {
      margin-bottom: 2rem;
   }

   h1 {
      font-family: 'HKGrotesk' !important;
      color: black;
      font-weight: 700;
      font-size: 28px;
      width: fit-content;
   }
`

const Menu = ({categories, mobileMenuCms}) => {
   const mobileBreakpoint = "xl"
   const [isOpen, setIsOpen] = useState(false)
   const [getSetting, loading] = useSiteSettingsQuery();
   const desktopMenuItems = getSetting(null, VB_DRAWER_MENU)?.children;
   const t = useIntl()

   const handleMenuClick = () => {
      setIsOpen(!isOpen)
   }

   return (
      <>
         <Below breakpoint="xl">
            <MenuContainer className="col-md-auto col-auto pl-32 menu-button d-flex align-items-center">
               <MenuButton isOpen={isOpen} onClick={handleMenuClick}></MenuButton>
            </MenuContainer>
         </Below>
         <Above breakpoint={mobileBreakpoint}>
            <VbDrawer isOpen={isOpen} setIsOpen={setIsOpen} title={t('Menu')}>
               <LinksContainer className="mt-32">
                  {
                     desktopMenuItems?.map((item, i) => {
                        const text = getStringByPropertyName(item?.properties, 'text')
                        const link = getStringByPropertyName(item?.properties, 'link')
                        const isHidden = getStringByPropertyName(item?.properties, 'isActive') === 'hidden'

                        if (isHidden) return null

                        return(
                        <Link to={link} key={i} onNavigation={() => setIsOpen(false)}>
                           <h1 className="link link--vb link--black">
                              {text}
                           </h1>
                        </Link>)
                     })
                  }
               </LinksContainer>
            </VbDrawer>
         </Above>
         <Below breakpoint={mobileBreakpoint}>
            <MobileMenu
               categories={categories}
               mobileMenuCms={mobileMenuCms}
               desktopMenuItems={desktopMenuItems}
               isOpen={isOpen}
               setIsOpen={setIsOpen}
            ></MobileMenu>
         </Below>
      </>
   )
}

export default Menu
