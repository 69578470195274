import React from 'react'
import { styled } from 'linaria/react'
import { theme } from '../../Theme'
import { pxToRem } from '../../../utils/css'
import { getStringByPropertyName } from '../../Core/Util/Helpers'
import { WarningCircle } from 'iconoir-react'
import Image from '@jetshop/ui/Image'

const WarningBannerWrapper = styled.section`
   background-color: ${props => props?.bannerBgColor};
   color: black;
   padding: ${pxToRem(10)};
   width: 100%;
   font-family: ${theme.font.family.heading};
   font-size: 12px;
   font-weight: 700;
   text-transform: uppercase;
   z-index: 100;

   svg {
      height: 1.5rem;
      width: 1.5rem;
   }

   .alert-message {
      color: ${props => props?.contentColor};
   }

   .brand-image {
      height: 1.5rem;
      width: auto;
   }
`

/**
 * Uses text from first site setting  on page and displays in top banner
 */
const WarningBanner = ({ data }) => {
   const isActive = getStringByPropertyName(data?.properties, 'isActive') == "visible"

   const message = getStringByPropertyName(data?.properties, 'message')

   const showIcon =
      getStringByPropertyName(data?.properties, 'iconVisibility') == 'visible'
      
   const bgColor =
      getStringByPropertyName(data?.properties, 'backgroundColor') ?? '#ffd336'

   const contentColor =
      getStringByPropertyName(data?.properties, 'contentColor') ?? '#000000'
      
   const imgSource = getStringByPropertyName(data?.properties, 'iconImage')


   return isActive ? (
      <WarningBannerWrapper bannerBgColor={bgColor} contentColor={contentColor}>
         <div className="d-flex gap-1 justify-content-center align-items-center">
            {showIcon && imgSource ? (
               <img
                  className="brand-image"
                  src={imgSource}
                  alt={`alt-text`}
               />
            ) : (
               <WarningCircle color={contentColor}></WarningCircle>
            )}

            <span className="alert-message">{message}</span>
         </div>
      </WarningBannerWrapper>
   ) : (
      <></>
   )
}

export default WarningBanner
