import React, { useContext, useState } from 'react'
import { styled } from 'linaria/react'
import UseFormatProduct from '../Hooks/UseFormatProduct'
import Title from '../Title'
import { useProductList } from '@jetshop/core/hooks/ProductList'
import { CartAlt } from 'iconoir-react'
import SiteButton from '../SiteButton'
import { ReactComponent as PrebookIcon } from '../../../svg/Prebook.svg'
import { ReactComponent as OutOfStockIcon } from '../../../svg/OutOfStock.svg'
import { ReactComponent as InStockIcon } from '../../../svg/InStock.svg'
import { theme } from '../../Theme'
import { Above } from '@jetshop/ui/Breakpoints'
import WhishlistButton from '../Buttons/WishlistButton'
import Image from '@jetshop/ui/Image/Image'
import { formatPrice, stripQueryParamFromUrl } from '../Util/Helpers'
import { SiteContext } from '../../Global/SiteContext'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import PriceSection from './PriceSection'
import { useIntl } from '@jetshop/intl'
import Link from '../Link'
import { useAddToCart } from '../../../hooks/useAddToCart'
import { useHandlePackageStatus } from '../../../hooks/useHandleProductPackageStatus'

const ProductCardContainer = styled.div`
   a {
      font-family: var(--font-grotesk);
      color: white;
      text-decoration: none;
   }

   &.product-card-horizontal {
      width: 100%;
      background: var(--color-white);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
      scale: 1;
      transition: all 0.5s ease;
   }

   &.product-card-horizontal .add-to-cart {
      border-radius: 50px;
   }

   &.product-card-horizontal .add-to-cart svg {
      filter: invert(100%);
      height: 1.3rem;
   }

   .product-card-horizontal-cart button svg {
      width: 1.5rem;
      height: 1.5rem;
      ${theme.below.md} {
         width: 1.25rem;
         height: 1.25rem;
      }
   }

   .product-card-image {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
      border-radius: 8px 8px 0 0;
      max-height: 8.75rem;
      padding: 0.75rem;
   }

   .product-card-wishlist {
      width: 100%;
      position: absolute;
      top: 0;
   }

   .product-card-sku {
      font-size: 0.75rem;
      font-family: var(--font-grotesk);
      font-weight: 600;
   }

   .product-card-stock {
      font-family: var(--font-grotesk);
      font-weight: 600;
      font-size: 0.75rem;
   }

   .product-card-title {
      font-size: 1rem;
      font-weight: 700;
   }

   .product-card-title a {
      word-wrap: break-all;
      font-family: var(--font-industry);
      color: black;
   }

   .product-card-cta {
      font-family: var(--font-industry);
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      text-align: center;
      background-color: #59a021;
      transition: all 0.3s ease;
   }

   .product-card-cta:hover {
      background-color: #4f8e1c;
      transition: all 0.3s ease;
   }

   .product-card-cart-button {
      padding: 0.5rem 2rem 0.5rem 2rem !important;
      ${theme.below.md} {
         padding: 0.5rem 1.25rem 0.5rem 1.25rem !important;
      }
   }

   .heart-animation {
      background-image: url('../../../svg/Heart.svg');
      background-repeat: no-repeat;
      background-size: 2900%;
      background-position: left;
      height: 100px;
      width: 100px;
      margin-right: -24px;
      margin-top: -24px;
      cursor: pointer;
      right: 0;
   }
   .animate-heart {
      animation: heart-burst 0.8s steps(28) forwards;
   }
   @keyframes heart-burst {
      0% {
         background-position: left;
      }
      100% {
         background-position: right;
      }
   }

   .data-flight-image-container img {
      max-width: 30px;
   }
`

const ProductSearchCardHorizontal = ({ product, onNavigationClick }) => {
   const { setShowSearch } = useContext(SiteContext)
   const { selectedChannel } = useContext(ChannelContext)
   const { addProductToCart } = useAddToCart()
   const t = useIntl()
   let {
      title,
      brand,
      articleNumber,
      imageUrl,
      url,
      isPreBook,
      hasVariants,
      isPackage,
      buyable,
   } = UseFormatProduct(product)

   const { packageStatus, loading } = useHandlePackageStatus({
      articleNumber,
      isPackage,
   })
   const isPreBookValue = loading
      ? undefined
      : packageStatus
      ? packageStatus?.stockStatusId == '14'
      : isPreBook
   const buyableValue = loading
      ? undefined
      : packageStatus
      ? packageStatus?.buyable
      : buyable

   const addToCart = e => {
      e.preventDefault()
      addProductToCart(product, articleNumber, 1, isPackage)
   }

   let strippedUrl = stripQueryParamFromUrl(url, 'att')

   return (
      <ProductCardContainer className="product-card-horizontal mb-16 br-8 d-flex flex-row">
         <div className="col-4 col-md-3 col-lg-2 position-relative">
            <Link to={url} onNavigation={() => onNavigationClick?.()}>
               <Image
                  className="product-card-image p-16"
                  src={imageUrl}
                  aspect="1:1"
               ></Image>
            </Link>
            <div className="row m-0 d-flex align-items-start product-card-wishlist pe-none">
               <div className="col-12 d-flex justify-content-end p-0">
                  <div className="pe-auto d-none d-md-block">
                     <WhishlistButton
                        articleNumber={articleNumber}
                        title={title}
                     ></WhishlistButton>
                  </div>
               </div>
            </div>
         </div>
         <div className="col-6 col-md-7 col-lg-8 product-card-description d-flex flex-column flex-grow-1 p-16">
            <div className="product-card-sku">ARTNR: {articleNumber}</div>
            <div className="product-card-title d-flex flex-grow-1 lh-1 pb-8 pt-8">
               <Link to={url} onNavigation={() => onNavigationClick?.()}>
                  {title}
               </Link>
            </div>
            <div className="row d-flex align-items-center">
               <PriceSection
                  product={product}
                  selectedChannel={selectedChannel}
                  className="mb-2"
               ></PriceSection>
               {/* <div className="col-12 d-flex">
                  <span className="product-card-stock align-items-center gap-1 d-none d-md-flex">
                     {isPreBookValue ? (
                        <>
                           <PrebookIcon></PrebookIcon>
                           <span>{t('book in advance')}</span>
                        </>
                     ) : buyableValue ? (
                        <>
                           <InStockIcon></InStockIcon>
                           <span>{t('in stock')}</span>
                        </>
                     ) : (
                        <>
                           <OutOfStockIcon></OutOfStockIcon>
                           <span>{t('Out of stock')}</span>
                        </>
                     )}
                  </span>
               </div> */}
            </div>
         </div>
         <div className="product-card-horizontal-cart col-2 py-16 pr-16 d-flex justify-content-between flex-column align-items-end">
            <Title size="1.0rem" weight={600}>
               {brand}
            </Title>
            {hasVariants ? (
               <SiteButton
                  size="medium"
                  styleType="solid"
                  color={
                     isPreBookValue ? 'yellow' : buyableValue ? 'green' : 'grey'
                  }
                  icon={true}
                  className="product-card-cart-button"
                  href={strippedUrl}
               >
                  <CartAlt strokeWidth={2.0}></CartAlt>
               </SiteButton>
            ) : (
               <SiteButton
                  size="medium"
                  styleType="solid"
                  disabled={!buyable}
                  color={
                     isPreBookValue ? 'yellow' : buyableValue ? 'green' : 'grey'
                  }
                  icon={true}
                  className="product-card-cart-button"
                  onClick={addToCart}
               >
                  <CartAlt strokeWidth={2.0}></CartAlt>
               </SiteButton>
            )}
         </div>
      </ProductCardContainer>
   )
}

export default ProductSearchCardHorizontal
