import React, { useRef } from 'react'
import { Cancel } from 'iconoir-react'
import { styled } from 'linaria/react'

const ClearBtn = styled.span`
   position: absolute;
   right: 0;
   top: 50%;
   transform: translateY(-50%);

   svg {
      pointer-events: none;
   }
`

function ClearSearch({ inputRef, handleClear, className }) {
   if (!inputRef || inputRef?.current?.value == '') return null

   return (
      <ClearBtn
         id="clear-search"
         className={className}
         onClick={() => {
            if (inputRef?.current) {
               handleClear()
            }
         }}
      >
         <Cancel
            color="#195178"
            strokeWidth={2.5}
         />
      </ClearBtn>
   )
}

export default ClearSearch
