import { formatDate, getPreBookLangStr, isProductPrebook } from "../components/Core/Util/Helpers";
import { useApolloClient } from '@apollo/react-hooks'
import { PreOrderDateQuery } from '../components/ProductPage/Queries/PreOrderDateQuery.gql'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { useContext } from "react";

export const usePrebookLogic = () => {
   const client = useApolloClient();
   const { selectedChannel } = useContext(ChannelContext)

   const checkIfPrebookCommentShouldBeAdded = async (product) => {
        if (isProductPrebook(product)) {
            const date = await getPrebookDate(product);
            product.preOrderComments = [
                {
                    name: getPreBookLangStr(selectedChannel?.id),
                    value: date,
                },
            ]
        }
   }

   const getPrebookDate = async (product) => {
      try {
         const { data } = await client.query({
            query: PreOrderDateQuery,
            variables: { articleNumber: product.articleNumber },
         })

         if (Array.isArray(data?.product?.customFields)) {
            const berLevDatumField = data?.product?.customFields?.find(
               field => field?.key === 'BerLevDatum'
            )

            if (typeof berLevDatumField?.stringValue === 'string') {
               return formatDate(berLevDatumField?.stringValue)
            }
         }
      } catch (error) {
         console.error(error)
      }
   }

   return { checkIfPrebookCommentShouldBeAdded }
}