import React, { useState, useEffect } from 'react'
import { styled } from 'linaria/react'
import {
   getStringByPropertyName,
   removeAlterantiveStr,
   splitArrayIntoChunks,
} from '../../Core/Util/Helpers'
import { debounce } from 'lodash'
import AnimateHeight from 'react-animate-height'
import CategoryLink from '@jetshop/ui/CategoryLink'
import Link from '../../Core/Link'

const MegaMenuContainer = styled(AnimateHeight)`
   position: absolute;
   width: 100%;

   .megamenu {
      box-shadow: 0 1px 20px rgba(0, 0, 0, 0.07);
   }

   .megamenu a {
      color: var(--color-black);
   }

   .megamenu-link {
      padding-top: 0.35rem;
      padding-bottom: 0.35rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 0.35rem;
   }

   .megamenu-list a:hover {
      opacity: 0.8;
   }

   .megamenu-list li {
      transform: translate(0, 0);
      transition: transform 0.2s ease-in-out;
   }
   .megamenu-list li:hover {
      transform: translate(1px, 0);
      transition: transform 0.25s ease-in-out;
   }

   .megamenu-list-level-3 li {
      margin-bottom: 8px;
   }

   .megamenu-categories {
      background: var(--color-vb-gray-1);
   }
   .megamenu-categories a {
      font-family: var(--font-industry);
      font-weight: 600;
   }

   .megamenu-categories img {
      scale: 1;
      max-height: 6.25rem;
      object-fit: cover;
   }

   .megamenu-categories img:hover {
      scale: 1.01;
      transition: background 0.5s ease, scale 0.5s;
   }

   .megamenu-col {
      overflow-y: auto;
      overflow-x: hidden;
      background: var(--color-vb-gray-2);
   }

   .megamenu-col-2 {
      overflow-y: auto;
      overflow-x: hidden;
      background: var(--color-white);
   }

   .megamenu li {
      font-size: 18px;
      list-style: none;
   }

   .megamenu li a {
      color: var(--color-black);
      font-family: var(--font-industry);
      font-weight: 500;
   }

   .megamenu-item:hover {
      background: var(--color-white);
   }

   .megamenu-item-active {
      background: var(--color-white);
   }

   .mega-menu-hidden {
      display: none;
   }

   .mega-menu-visible {
      display: block;
   }
`

// const sortSubCategories = category => {
//    if (!category) {
//       return []
//    }
//    return category?.subcategories?.slice()?.sort(function (a, b) {
//       if (a.name < b.name) {
//          return -1
//       }
//       if (a.name > b.name) {
//          return 1
//       }
//       return 0
//    })
// }

const sortSubCategories = (category) => {
   if (!category || !category.subcategories) {
       return [];
   }

   // Create a new array with 'name' cleaned up
   const cleanedSubcategories = category.subcategories.map(subcat => {
       return { ...subcat, name: removeAlterantiveStr(subcat.name) };
   });

   // Sort the new array
   return cleanedSubcategories.slice().sort(function (a, b) {
       if (a.name < b.name) {
           return -1;
       }
       if (a.name > b.name) {
           return 1;
       }
       return 0;
   });
};


const MegaMenu = ({
   category,
   featuredCategories,
   visible,
   onMouseEnterEvent,
   onMouseLeaveEvent,
   setMegaMenuIsOpen
}) => {
   const [activeCategory, setActiveCategory] = useState(
      sortSubCategories(category)[0]
   )
   const [height, setHeight] = useState(0)
   const [categoryListHeight, setCategoryListHeight] = useState(0)

   useEffect(() => {
      const menuCords = document
         .getElementById('menuHeader')
         .getBoundingClientRect()
      let height = window.innerHeight

      setCategoryListHeight(
         height > 1000 ? 'auto' : height - (menuCords.bottom + 32)
      )
      setHeight(visible ? 'auto' : 0)
   }, [visible])

   const onCategoryHover = cat => {
      setActiveCategory(cat)
   }

   const debouncedOnCategoryHover = debounce(cat => {
      if (!cat) {
         return
      }
      onCategoryHover(cat)
   }, 200)

   const handleMouseLeave = () => {
      debouncedOnCategoryHover()?.cancel()
   }

   const thirdLevelCategories = sortSubCategories(activeCategory)
   const thirdLevelCategoryChunks = splitArrayIntoChunks(
      thirdLevelCategories ?? [],
      4
   )


   return (
      <MegaMenuContainer
         onMouseEnter={onMouseEnterEvent}
         onMouseLeave={onMouseLeaveEvent}
         duration={500}
         height={height}
      >
         <div
            className={`container-fluid p-0 m-0 megamenu ${
               visible ? 'mega-menu-visible' : 'mega-menu-hidden'
            }`}
         >
            <div className="row p-0 m-0">
               <div
                  className="col-3 py-32 px-0 megamenu-col"
                  style={{
                     height: `${
                        categoryListHeight == 'auto'
                           ? categoryListHeight
                           : categoryListHeight + 'px'
                     }`,
                  }}
               >
                  <ul className="megamenu-list megamenu-level-1 m-0 p-0">
                     {sortSubCategories(category)?.map((cat, i) => {
                        return (
                           <li
                              key={i}
                              className={`megamenu-link megamenu-item pl-128 ${
                                 activeCategory?.id == cat?.id
                                    ? 'megamenu-item-active'
                                    : ''
                              }`}
                              onMouseOver={() => debouncedOnCategoryHover(cat)}
                              onMouseLeave={handleMouseLeave}
                           >
                              <CategoryLink category={cat} onClick={() => setMegaMenuIsOpen(false)}>{cat?.name}</CategoryLink>
                           </li>
                        )
                     })}
                  </ul>
               </div>
               <div className="col-9 megamenu-col-2 d-flex justify-content-between flex-column"
                  style={{
                     height: `${
                        categoryListHeight == 'auto'
                           ? categoryListHeight
                           : categoryListHeight + 'px'
                     }`,
                  }}             
               >
                  {visible && (
                     <div className="row pr-128 pl-32 py-32">
                        {thirdLevelCategoryChunks.map((catChunk, i) => {
                           return (
                              <div className="col-3" key={i}>
                                 <ul className="megamenu-list megamenu-level-2 m-0 p-0">
                                    {catChunk?.map((cat, i) => {
                                       if (cat?.name)
                                       return (
                                          <li
                                             className="megamenu-link megamenu-item-level-2"
                                             key={i}
                                          >
                                             <CategoryLink
                                                category={cat}
                                                className="link link--vb"
                                                title={cat?.name}
                                                onClick={() => setMegaMenuIsOpen(false)}
                                             >
                                                {cat?.name}
                                             </CategoryLink>
                                          </li>
                                       )
                                    })}
                                 </ul>
                              </div>
                           )
                        })}
                     </div>
                  )}
                  {visible && (
                     <div className="row d-flex megamenu-categories pr-128 pl-32 py-32">
                        <div className="col-12 align-self-end">
                           <div className="row">
                              {featuredCategories?.map((cat, i) => {
                      
                                 const title = getStringByPropertyName(
                                    cat?.properties,
                                    'title'
                                 )
                                 const link = getStringByPropertyName(
                                    cat?.properties,
                                    'link'
                                 )
                                 const altText = getStringByPropertyName(
                                    cat?.properties,
                                    'altText'
                                 )
                                 const img = getStringByPropertyName(
                                    cat?.properties,
                                    'img'
                                 )
                                 return (
                                    <div key={i} className="col-4">
                                       <Link to={link} onNavigation={() => setMegaMenuIsOpen(false)}>
                                          <img
                                             className="w-100 br-4 mb-8"
                                             alt={altText}
                                             src={img}
                                          />
                                       </Link>
                                       <Link to={link} onNavigation={() => setMegaMenuIsOpen(false)} className="link link--vb">
                                          {title}
                                       </Link>
                                    </div>
                                 )
                              })}
                           </div>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </MegaMenuContainer>
   )
}

export default MegaMenu
