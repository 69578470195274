import React, { useContext } from 'react'
import {styled} from 'linaria/react'
import {theme} from '../../Theme'
import { getStringByPropertyName } from '../../Core/Util/Helpers'


const IconsContainer = styled.div`
   background: var(--color-vb-blue);
`

const FooterIcons = ({iconBlocks}) => {
   return (
      <IconsContainer className="col-12">
         <div className="row d-flex justify-content-center align-items-center py-16">
            {iconBlocks?.map((iconBlock, i) => {
               const image = getStringByPropertyName(
                  iconBlock?.properties,
                  'image'
               )
               const imageAlt = getStringByPropertyName(
                  iconBlock?.properties,
                  'imageAlt'
               )
               return (
                  <div key={i} className="col-4 col-lg-2 py-8 text-center">
                     <img
                        src={image}
                        className="logo-image"
                        alt={imageAlt}
                        loading='lazy'
                     />
                  </div>
               )
            })}
         </div>
      </IconsContainer>
   )
}
 
export default FooterIcons;