import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import packageProductQuery from '../components/ProductPage/Queries/PackageProductQuery.gql'
import { getPackageStockStatus } from '../components/Core/Util/Helpers'

export const useHandlePackageStatus = ({ articleNumber, isPackage }) => {
   const [packageStatus, setPackageStatus] = useState(null)

   const [getPackage, { loading }] = useLazyQuery(packageProductQuery, {
      onCompleted: data => {
         const validPackageProductStatus = getPackageStockStatus(
            data?.product?.package?.items
         )
         setPackageStatus(validPackageProductStatus)
      },
   })

   useEffect(() => {
      if (articleNumber && isPackage) {
         getPackage({
            variables: { articleNumber: articleNumber },
         })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [articleNumber, isPackage])

   return { packageStatus, loading }
}
