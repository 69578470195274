import ChannelContext from '@jetshop/core/components/ChannelContext'
import React, { useContext, useEffect, useRef } from 'react'

const getReviewConfig = selectedChannel => {
   switch (selectedChannel?.id) {
      case 1:
         return {
            langLink: 'https://se.trustpilot.com/review/www.verktygsboden.se',
            reviewLang: 'sv',
            businessUnitId: '4b1f0c55000064000504d9a6',
            dataLocale: selectedChannel?.language?.culture,
         }
      case 2:
         return {
            langLink: 'https://dk.trustpilot.com/review/verktygsboden.dk',
            reviewLang: 'da',
            businessUnitId: '635a2d41d9edb4e839ed7547',
            dataLocale: selectedChannel?.language?.culture,
         }
      case 3:
         return {
            // Norwegian reviews from swedish businessUnitId
            langLink: 'https://se.trustpilot.com/review/www.verktygsboden.se',
            reviewLang: 'no,nb,nn',
            // Swedish businessUnitId is used for Norwegian reviews
            businessUnitId: '4b1f0c55000064000504d9a6',
            // Norwegian businessId
            // businessUnitId: '6389e5947f633f7abc1fc429',
            dataLocale: selectedChannel?.language?.culture,
         }
      default:
         return {
            langLink: 'https://se.trustpilot.com/review/www.verktygsboden.se',
            reviewLang: 'sv',
            businessUnitId: '4b1f0c55000064000504d9a6',
            dataLocale: selectedChannel?.language?.culture,
         }
   }
}

const TrustPilotWidget = () => {
   const { selectedChannel } = useContext(ChannelContext)
   const ref = useRef(null)
   const reviewConfig = getReviewConfig(selectedChannel)
   useEffect(() => {
      if (window?.Trustpilot) {
         window?.Trustpilot?.loadFromElement(ref.current, true)
      }
   }, [])
   return (
      <div
         ref={ref}
         className="trustpilot-widget"
         data-locale={reviewConfig?.dataLocale}
         data-template-id="5419b732fbfb950b10de65e5"
         data-businessunit-id={reviewConfig?.businessUnitId}
         data-style-height="20px"
         data-style-width="100%"
         data-theme="dark"
         data-text-color="#FFFFFF"
      >
         <a href={reviewConfig?.langLink} target="_blank" rel="noopener">
            Trustpilot
         </a>
      </div>

      // Previous code
      // <div
      // ref={ref}
      // class="trustpilot-widget"
      // data-locale={selectedChannel?.language?.culture ?? 'sv-SE'}
      // data-template-id="5419b732fbfb950b10de65e5"
      // data-businessunit-id="4b1f0c55000064000504d9a6"
      // data-style-height="20px"
      // data-style-width="100%"
      // data-theme="dark"
      // data-text-color="#FFFFFF"
      // >
      // <a
      //    href="https://se.trustpilot.com/review/www.verktygsboden.se"
      //    target="_blank"
      //    rel="noopener"
      // >
      //    Trustpilot
      // </a>
      // </div>
   )
}

export default TrustPilotWidget


