import React, { useState, useContext } from 'react'
import { styled } from 'linaria/react'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { ReactComponent as SwedenFlag } from '../../../svg/sv-flag.svg'
import { ReactComponent as NorwayFlag } from '../../../svg/no-flag.svg'
import { ReactComponent as DenmarkFlag } from '../../../svg/dk-flag.svg'
import { theme } from '../../Theme'
import { pxToRem } from '../../../utils/css'

const ChannelWrapper = styled.div`
   .text-container {
      margin: auto 0;
      text-transform: uppercase;
      cursor: pointer;
   }

   a {
      font-size: 12px;
      transition: all 0.5s ease;
      
      ${theme.above.lg}{
         font-size: 14px;
      }
   }

   a:hover {
      font-weight: 700;
   }

   .selected-channel {
      border-bottom: 2px solid white;
   }
`

function ChannelSelector() {
   // const [isOpen, setIsOpen] = useState(false)
   const { channels, selectedChannel, channelsById, updateChannel } = useContext(ChannelContext)
   
   const getFlagByChannelId = (id) => {
    switch (id) {
        case 1:
          return (<SwedenFlag></SwedenFlag>);
        case 2: 
          return (<DenmarkFlag></DenmarkFlag>);
        case 3:
          return (<NorwayFlag></NorwayFlag>);
        default:
           return (<SwedenFlag></SwedenFlag>);
      }
   }
   
   // const openSelector = () => {
   //  setIsOpen(!isOpen);
   // }

   const changeChannel = (e, id) => {
    e.preventDefault();
    const newChannel = { ...channelsById[id] }
    newChannel['language'] = channelsById[id]?.languages.find(l => l?.isDefault)
    newChannel['currency'] = channelsById[id].currencies[0]
    newChannel['country'] = channelsById[id].countries[0]
    updateChannel(newChannel);
   }

   return (
      <ChannelWrapper className="d-flex gap-3 align-items-center">
         {channels.map((channel, i) => {
            return (
               <div key={channel?.id} style={{ cursor: 'pointer' }}>
                  <a
                     href={'/'}
                     onClick={e => changeChannel(e, channel?.id)}
                     className={`d-flex gap-2 align-items-center link link--vb link--white ${
                        channel?.id == selectedChannel?.id && 'selected-channel'
                     }`}
                  >
                     <span className="channel-name">{channel?.name}</span>
                     {getFlagByChannelId(channel?.id)}
                  </a>
               </div>
            )
         })}
         
         {/* <div className='text-container' onClick={openSelector}>
            <span>{selectedChannel?.name}</span>
         </div> */}
         {/* <div className="lang-menu">
            <div className="selected-lang" onClick={openSelector}>
                {getFlagByChannelId(selectedChannel?.id)}
            </div>
            <ul style={{display: isOpen ? 'block' : 'none'}}>
               {
                   channels.map((channel, i) => {
                       if(channel?.id != selectedChannel?.id){
                        return(<li key={channel?.id}><a href="/" className={`channel-${channel?.id}`} onClick={(e) => changeChannel(e, channel?.id)}>{channel?.name}</a></li>);
                       }
                       return <React.Fragment key={channel?.id}></React.Fragment>
                   })
               }
            </ul>
         </div> */}
      </ChannelWrapper>
   )
}

export default ChannelSelector


// </div> */}
// {channels.map((channel, i) => {
//    return (
//       <div key={channel?.id} style={{ cursor: 'pointer' }}>
//          <a
//             href={'/#'}
//             onClick={e => changeChannel(e, channel?.id)}
//             className={`d-flex gap-2 align-items-center link link--vb link--white ${
//                channel?.id == selectedChannel?.id &&
//                'selected-channel'
//             }`}
//          >
//             <span className='channel-name'>{channel?.name}</span>
//             {getFlagByChannelId(channel?.id)}
//          </a>
//       </div>
//    )
// })}