export async function getVoyadoContactIdByEmail(email) {
   const encodedEmail = encodeURIComponent(email) // URL encode the email

   const url = `https://plenum.digifiservices.se/prod/vb-proxy/api/voyado/contacts/${encodedEmail}`
   try {
      const response = await fetch(url, {
         method: 'GET',
         headers: {
            accept: 'application/json',
         },
      })

      if (!response.ok) {
         throw new Error(`Failed to fetch data: ${response.status}`)
      }

      const data = await response.json()

      // Check if the data contains the expected properties (e.g., id)
      if (!data || typeof data !== 'object' || data?.id === undefined) {
         throw new Error('Invalid data structure, missing property')
      }

      // Return the contact ID
      return data?.id;

   } catch (error) {
      console.error(`Error during fetching: ${error?.message}`)
      return null // Return null in case of failure
   }
}


export async function getDecryptedVoyadoContactId(softToken) {
   // console.log('getDecryptedVoyadoContactId triggered:', softToken);
   const url = `https://plenum.digifiservices.se/prod/vb-proxy/api/voyado/decrypt?content=${softToken}`;

   try {
      const response = await fetch(url, {
         method: 'GET',
         headers: {
            accept: 'application/json',
         },
      });

      if (!response.ok) {
         console.error(`Error: ${response.statusText}`);
         return null;
      }

      const data = await response.json();

      // Validate the structure and ensure "contactId" exists
      if (!data || typeof data !== 'object' || data?.contactId === undefined) {
         throw new Error('Invalid data structure, missing property');
      }

      // Return only the contactId
      return data?.contactId;

   } catch (error) {
      console.error(`Error during decryption: ${error?.message}`);
      return null; 
   }
}