import { FindifyApiKeyDK, FindifyApiKeyNO, FindifyApiKeySE, SupportedFields } from "./constants";

const parseFilterKey = (key) => {
    //eslint-disable-next-line
    const regex = /\[([^\[\]]*)\]/g;
    let matches = key.match(regex);
    if (!matches) {
        return null;
    }
    matches = matches?.map(match => match?.slice(1, -1));
    return matches;
}

const getFilterType = (fieldName) => {
    return SupportedFields[fieldName];
}

export const stripUrl = url => {
   if (typeof url !== 'string') {
      // Handle the case where the input is not a valid non-empty string
      return url
   }
   try {
      const urlObject = new URL(url)
      return urlObject.pathname
   } catch (error) {
      // console.error(`Error parsing URL: ${url}`)
      return url
   }
}

export const getSearchParamsFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const filters = [];

    let offset = 0;
    if (searchParams.has("offset")) {
        offset = searchParams.get("offset");
        searchParams.delete("offset");
    }

    let sortFilter = {field : 'default', order: 'default'};
    if (searchParams.has("sort")) {
        let sortString = searchParams.get("sort");
        sortString.split("-").forEach((value, index) => {
            if(index == 0){
                sortFilter.field = value;
            }
            if(index == 1){
                sortFilter.order = value;
            }
        })
        // searchParams.delete("sort");
    }

    searchParams.forEach((value, key) => {

        if (!parseFilterKey(key)) {
            return;
        }

        const [fieldName, index, attribute, ...rest] = parseFilterKey(key)
        const type = getFilterType(fieldName);

        let filter = filters.find(filter => filter.name == fieldName)

        if (!filter) {
            filter = { "name": fieldName, "type": type, values: [] };
            filters.push(filter)
        }

        if (type == 'text') {
            filter.values.push({ 'value': value })
        }

        if (type == 'range') {
            if (!filter.values[index]) {
                let obj = {};
                obj[attribute] = parseInt(value);
                filter.values.push(obj)
            } else {
                filter.values[index][attribute] = parseInt(value);
            }
        }
    });

    return { parsedFilters : filters, offset, parsedSortFilter: sortFilter };
}

export const UpdateQueryParam = (key, value) => {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);
    searchParams.set(key, value);
    return `${currentUrl.protocol}//${currentUrl.host}${currentUrl.pathname}?${searchParams.toString()}`;
}

export const updateOffsetQueryParam = (newOffset) => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const searchParams = new URLSearchParams(url.search);

    if(newOffset == 0){
        searchParams.delete('offset');
        if(searchParams?.size == 0){
            return `${url.protocol}//${url.host}${url.pathname}`;
        }
        return `${url.protocol}//${url.host}${url.pathname}?${searchParams.toString()}`;
    }
  
    // Update the 'offset' query parameter
    searchParams.set('offset', newOffset);
  
    // Update the URL search parameters
    url.search = searchParams.toString();
  
    // Get the new URL with the updated 'offset' query parameter
    return url.href;
  };

export const RemoveQueryParamsThatStartswith = (partOfKey, url) => {
    
    let keysToRemove = [];
    const searchParams = new URLSearchParams(url.search);
    for (const key of searchParams.keys()) {
        if(key.toString().startsWith(partOfKey)){
            keysToRemove.push(key);
        }
    }

    for (const keyToRemove of keysToRemove) {
        searchParams.delete(keyToRemove)
    }
    
    return `${url.protocol}//${url.host}${url.pathname}?${searchParams.toString()}`;
}

export const setUrlFromFilter = (localFilters) => {
    const currentUrl = new URL(window.location.href);
    // currentUrl.searchParams.set('offset', 0);
    let newUrl = RemoveQueryParamsThatStartswith("filter", currentUrl)
    
    if (filtersToQueryString(localFilters)) {
        newUrl = `${newUrl}&${filtersToQueryString(localFilters)}`
    }

    if (newUrl.endsWith("?")) {
        newUrl = newUrl.replace(/\?$/, "");
    }
    
    window.history.replaceState({}, '', newUrl)
}
   
const filtersToQueryString = filters => {
    const searchParams = new URLSearchParams()
    for (let i = 0; i < filters.length; i++) {
    for (let j = 0; j < filters[i].values.length; j++) {
        if (filters[i].type == 'text') {
            searchParams.set(
                `filter[${filters[i]?.name}][${j}]`,
                filters[i].values[j].value
            )
        } else {
            searchParams.set(
                `filter[${filters[i]?.name}][${j}][from]`,
                filters[i].values[j].from
            )
            searchParams.set(
                `filter[${filters[i]?.name}][${j}][to]`,
                filters[i].values[j].to
            )
        }
    }
    }
    return searchParams.toString()
}

export const getFindifyApiKey = (channelId) => {
    let result;
    
    switch (channelId) {
      case 1:
        result = FindifyApiKeySE
        break;
      case 2:
        result = FindifyApiKeyDK
        break;
      case 3:
       result = FindifyApiKeyNO
        break;
      default:
       result = FindifyApiKeySE
    }
  
    return result;
 };