import React, { useState, useContext } from 'react'
import { styled } from 'linaria/react'
import UseFormatProduct from '../Hooks/UseFormatProduct'
import { useProductList } from '@jetshop/core/hooks/ProductList'
import { CartAlt } from 'iconoir-react'
import VbButton from '../SiteButton'
import { Cancel as CancelIcon } from 'iconoir-react'
import { theme } from '../../Theme'
import Image from '@jetshop/ui/Image/Image'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import PriceSection from './PriceSection'
import Link from '../Link'
import { useAddToCart } from '../../../hooks/useAddToCart'

const ProductCardContainer = styled.div`
   a {
      font-family: var(--font-grotesk);
      color: white;
      text-decoration: none;
   }

   &.product-card-horizontal {
      width: 100%;
      background: var(--color-white);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
      scale: 1;
      transition: all 0.5s ease;
   }

   &.product-card-horizontal .add-to-cart {
      border-radius: 50px;
   }

   &.product-card-horizontal .add-to-cart svg {
      filter: invert(100%);
      height: 1.3rem;
   }

   .product-card-horizontal-cart button svg {
      width: 1.25rem;
      height: 1.25rem;
      ${theme.below.md} {
         width: 1.25rem;
         height: 1.25rem;
      }
   }

   .product-card-image {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
      border-radius: 8px 8px 0 0;
      padding: 0.75rem;
   }

   .product-card-wishlist {
      width: 100%;
      position: absolute;
      top: 0;
   }

   .product-card-sku {
      font-size: 0.688rem;
      font-family: var(--font-industry);
      font-weight: 500;
   }

   .product-card-stock {
      font-family: var(--font-grotesk);
      font-weight: 600;
      font-size: 0.75rem;
   }

   .product-card-brand {
      font-size: 0.7rem;
      font-weight: 600;
      font-family: var(--font-industry);
   }

   .product-card-title {
      font-size: 1rem;
      font-weight: 700;
   }

   .product-card-title a {
      font-family: var(--font-industry);
      color: black;
   }

   .product-card-cta {
      font-family: var(--font-industry);
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      text-align: center;
      background-color: #59a021;
      transition: all 0.3s ease;
   }

   .product-card-cta:hover {
      background-color: #4f8e1c;
      transition: all 0.3s ease;
   }

   .product-card-cart-button {
      padding: 0.4rem 1.5rem 0.4rem 1.5rem !important;
      ${theme.below.md} {
         padding: 0.5rem 1.25rem 0.5rem 1.25rem !important;
      }
   }

   .cancel-icon {
      cursor: pointer;
      margin-top: -5px;
      color: var(--color-vb-blue);
   }

   .cancel-icon:hover {
      opacity: 0.75;
      transition: opacity 0.5s ease;
   }
`

const ProductWishListCard = ({ product, hideCancelIcon, closeDrawer }) => {
   const { selectedChannel } = useContext(ChannelContext)
   const { addProductToCart } = useAddToCart();
   const { remove } = useProductList()
   const {
      title,
      brandName,
      articleNumber,
      imageUrl,
      url,
      isPreBook,
      buyable,
      isPackage
   } = UseFormatProduct(product)


   const removeFromWishlist = () => {
      remove(articleNumber, {
         articleNumber,
      })
   }

   const addToCart = () => {
      addProductToCart(product, articleNumber, 1, isPackage)
   }

   return (
      <ProductCardContainer className="product-card-horizontal mb-16 br-8 d-flex flex-row">
         <div className="col-4 col-sm-3 position-relative">
            <Link to={url} onNavigation={closeDrawer}>
               <Image
                  className="product-card-image p-16"
                  src={imageUrl}
                  aspect="1:1"
               ></Image>
            </Link>
         </div>
         <div className="col-6 col-sm-7 d-flex flex-column flex-grow-1 p-16">
            <div className="product-card-sku mb-0">ARTNR: {articleNumber}</div>
            {brandName && (
               <div className="product-card-brand text-truncate mb-0 mt-0">
                  {brandName}
               </div>
            )}
            <div className="product-card-title d-flex flex-grow-1 lh-1 mb-16">
               <Link to={url} className="text-truncate" title={title} onNavigation={closeDrawer}>
                  {title}
               </Link>
            </div>
            <div className="row d-flex align-items-center">
               <PriceSection
                  product={product}
                  selectedChannel={selectedChannel}
               ></PriceSection>
            </div>
         </div>
         <div className={`product-card-horizontal-cart col-2 py-16 pr-16 d-flex flex-column align-items-end ${hideCancelIcon ? "justify-content-end" :"justify-content-between" }`}>
            { !hideCancelIcon && 
               <CancelIcon
                  strokeWidth={2.0}
                  className="cancel-icon"
                  onClick={() => removeFromWishlist()}
               ></CancelIcon>
            }
            <VbButton
               size="medium"
               styleType="solid"
               color={isPreBook ? 'yellow' : 'green'}
               icon={true}
               disabled={!buyable}
               className="product-card-cart-button"
               onClick={addToCart}
            >
               <CartAlt strokeWidth={2.0}></CartAlt>
            </VbButton>
         </div>
      </ProductCardContainer>
   )
}

export default ProductWishListCard
