import { useEffect, useRef, useState } from 'react'
import { setContactId } from '../../../utils/voyado/voyadoEngage'
import { getCookie, expireCookieImmediately } from '../../HelloRetail/HelloRetailHelper'
import useAuth from '@jetshop/core/components/AuthContext/useAuth'
import customerEmailQuery from '../CustomerEmailQuery.gql'
import { useApolloClient } from '@apollo/react-hooks'
import {
   getDecryptedVoyadoContactId,
   getVoyadoContactIdByEmail,
} from '../Api/VoyadoApi'

export const useVoyadoContactId = () => {
   const { loggedIn } = useAuth() // Monitor the logged-in state
   const prevIsLoggedIn = useRef(false) // Track if the user was previously logged in
   const windowLoaded = useRef(false) // Track if window is already loaded for soft login
   const client = useApolloClient() // Get the Apollo client

   /**
    * Effect to handle login state changes
    */
   useEffect(() => {
      const handleLoginStateChange = async () => {
         const current_vaI_CookieId = getCookie('_vaI') // Retrieve cookie value

         if (loggedIn) {
            // Keep track of the previous login state
            prevIsLoggedIn.current = true

            // Fetch the email of the logged-in user
            const { data, errors } = await client.query({
               query: customerEmailQuery,
            })

            if (!data?.customer?.email || errors) {
               // console.warn('No email found or error fetching email.')
               return
            }

            // Fetch the contact ID from Voyado using the email
            const contactId = await getVoyadoContactIdByEmail(
               data?.customer?.email
            )

            if (!contactId) {
               // console.warn('Failed to fetch contact ID.')
               return
            }

            // Check if current contact ID is the same as the fetched contact ID
            if (
               current_vaI_CookieId &&
               contactId &&
               current_vaI_CookieId === contactId
            ) {
               // Contact ID matches, current contact ID is the same as the fetched contact ID. No update needed.
               return
            }

            // Set the new contact ID
            setContactId(contactId)
            return
         }

         // Handle user sign out case, secure that the contact ID is cleared if logging out.
         if (!loggedIn && prevIsLoggedIn.current) {
            localStorage.removeItem('vtid'); // If not removed the _vaI coockie will be set again on next page load
            expireCookieImmediately('_vaI') // Remove the cookie by expiring it immediately
            prevIsLoggedIn.current = false
            return
         }
      }

      handleLoginStateChange()
   }, [loggedIn, client])


   /**
    * Effect to handle soft login via eclub token on window load (triggered once)
    */
   useEffect(() => {
      const handleSoftLogin = async () => {
         if (loggedIn) {
            // User is already logged in, prioritize this over soft login, skipping soft login.
            return
         }

         const urlParams = new URLSearchParams(window.location.search)
         const softToken = urlParams.get('eclub')
         const current_vaI_CookieId = getCookie('_vaI')

         if (!softToken) {
            //'No soft token found. Proceeding with default handling
            return
         }

         const decryptedContactId = await getDecryptedVoyadoContactId(softToken)
         const softLoginId = decryptedContactId

         if (!softLoginId) {
            // Soft login: Failed to decrypt contact ID. Do nothing.
            return
         }

         if (softLoginId && current_vaI_CookieId === softLoginId) {
            // Soft login: Contact ID matches the current cookie ID. Do nothing.
            return
         }

         if (softLoginId) {
            // Soft login: Contact ID does not match the current cookie ID. Update contact ID.
            setContactId(softLoginId)
            return
         }
      }

      // Run soft login check only once on window load,(ensures that window resources (like URL and cookies) are available)
      if (document.readyState === 'complete' && !windowLoaded.current) {
         handleSoftLogin()
         windowLoaded.current = true
      } else {
         const onWindowLoad = () => {
            handleSoftLogin()
            windowLoaded.current = true
         }
         window.addEventListener('load', onWindowLoad)

         return () => {
            window.removeEventListener('load', onWindowLoad)
         }
      }
   }, [loggedIn])

}
