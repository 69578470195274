import React from 'react'
import { styled } from 'linaria/react'
import TrustPilotWidget from '../../../../TrustPilot/TrustPilotWidget'

const TrustPilotPlaceholder = styled.div`
   white-space: nowrap;
   display: flex;
   width: 300px;
   margin-left: -1.1rem;
   @media (min-width: 576px) {
      margin-left: 0;
      margin-right: -1.1rem;
   }
`

const TrustPilotBanner = () => {
   return (
      <TrustPilotPlaceholder>
         <TrustPilotWidget />
      </TrustPilotPlaceholder>
   )
}

export default TrustPilotBanner