import React from 'react';
import { styled } from 'linaria/react'
import { useProductList } from '@jetshop/core/hooks/ProductList';

const WhishlistButtonContainer = styled.div`
    .heart-animation {
        background-image: url('../../../svg/Heart.svg');
        background-repeat: no-repeat;
        background-size: 2900%;
        background-position: left;
        height: 100px;
        width: 100px;
        margin-right: -24px;
        margin-top: -24px;
        cursor: pointer;
        right: 0;
    }

    .heart-animation-block {
      background-image: url('../../../svg/Heart.svg');
      background-repeat: no-repeat;
      background-size: 2900%;
      background-position: left;
      height: 50px;
      width: 50px;
      scale: 2.2;
      cursor: pointer;
   }

    .animate-heart {
        animation: heart-burst 0.8s steps(28) forwards;
    }

    @keyframes heart-burst {
        0% {
        background-position: left;
        }
        100% {
        background-position: right;
        }
    }
`


const WhishlistButton = ({ articleNumber, title, isBlock }) => {
   const { toggle, inList } = useProductList()

   const addToWishlist = () => {
      toggle(articleNumber, {
         articleNumber,
         productName: title,
      })
   }

   const isInWishlist = inList(articleNumber, {
      articleNumber,
   })
   return (
      <WhishlistButtonContainer>
         <div
            className={` pe-auto ${
               isBlock ? 'heart-animation-block' : 'heart-animation'
            } ${isInWishlist ? 'animate-heart' : ''} `}
            onClick={addToWishlist}
         ></div>
      </WhishlistButtonContainer>
   )
}
 
export default WhishlistButton;