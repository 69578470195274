import React, { useRef } from 'react'
import { styled } from 'linaria/react'
import SearchIcon from '../../../svg/Search.svg'
import UseSearchLogic from '../../Core/Hooks/UseSearchLogic'
import { useIntl } from '@jetshop/intl'
import Spinner from '../../ui/Spinner'
import ClearSearch from '../../Core/Icons/ClearSearch'

const SearchGroup = styled.div`
   position: relative;

   .search {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-family: var(--font-grotesk);
      font-size: 1rem;
      line-height: normal;
      /* padding: 0.8rem 1rem; */
      padding: 0.8rem 1.5rem 0.8rem 2.2rem;
      border: 0;
      border-radius: 50px;
      scale: 1;
      transition: all 0.5s ease;
      font-weight: 600;
   }

   .search:hover {
      box-shadow: 0 0 0.5rem 3px rgba(255, 255, 255, 0.2);
   }

   .search:placeholder {
      font-family: var(--font-grotesk);
   }

   .search:focus {
      box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.15);
      transition: scale 0.5s ease, box-shadow 0.5s ease;
   }

   .icon {
      position: absolute;
      padding: 0.75rem 0rem;
      margin-left: 0.7rem;
      top: 0;
      left: 0;
      opacity: 0.5;
   }
   .spinner-wrapper {
      transition: margin 0.2s ease;
      margin-top: ${props => (props.isScrolled ? '-1px' : '4px')};
      position: absolute;
      padding: 0.8rem 0rem;
      margin-right: 1rem;
      top: 0;
      right: 0;
      opacity: 0.5;
   }

   input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
   }
`

const StyledSpinner = styled(Spinner)`
   height: 1.2rem;
   width: 1.2rem;
   circle.path {
      stroke: black;
   }
`

const SearchBar = () => {
   const inputRef = useRef(null)
   const {
      handleInputChange,
      handleInputFocus,
      handleKeyPressed,
      isLoadingRedirectReq,
      handleClear,
   } = UseSearchLogic(inputRef)
   const t = useIntl()

   return (
      <>
         <SearchGroup className="form-group">
            <input
               ref={inputRef}
               type="search"
               id="searchfield-desktop"
               className="form-control search"
               placeholder={t('Searchfield-placeholder')}
               onChange={handleInputChange}
               onFocus={handleInputFocus}
               onKeyUp={handleKeyPressed}
            />

            {isLoadingRedirectReq ? (
               <span className="icon pr-8">
                  <StyledSpinner />
               </span>
            ) : (
               <img className="icon pr-8" alt="Search-icon" src={SearchIcon} />
            )}
            <ClearSearch inputRef={inputRef} handleClear={handleClear} className="pl-8 pr-8"/>
         </SearchGroup>
      </>
   )
}

export default SearchBar
