import React, { useState, useEffect, useRef } from 'react'
import { styled } from 'linaria/react'
import Title from '../../Core/Title'
import VbButton from '../../Core/SiteButton'
import AnimateHeight from 'react-animate-height'
import { useContext } from 'react'
import { SiteContext } from '../../Global/SiteContext'
import { fetchFindifyAutoComplete } from '../../Findify/ApiService'
import ProductListCard from '../../Core/Cards/ProductListCard'
import { Above, Below } from '@jetshop/ui/Breakpoints'
import ProductSearchCardHorizontal from '../../Core/Cards/ProductSearchCardHorizontal'
import { theme } from '../../Theme'
import { Cancel as CancelIcon } from 'iconoir-react'
import { clearInputSearchFieldsById, elementsToKeepInArray, formatRedirectUrl } from '../../Core/Util/Helpers'
import { useSiteSettingsQuery } from '../../../hooks/useSiteSettings'
import { Product_Page_Settings } from '../../CustomElements/elementTypes'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { getFindifyApiKey } from '../../Findify/Helper'
import { useIntl } from '@jetshop/intl'
import Link from '../../Core/Link'
import { useHistory } from 'react-router-dom'
import LoadingArrowIcon from '../../ui/LoadingArrowIcon'

const SearchResultContainer = styled(AnimateHeight)`
   font-family: var(--font-grotesk);
   border-radius: 0px 0px 2rem 2rem;
   width: 100%;
   z-index: 1030;
   position: absolute;
   backdrop-filter: blur(1.25rem);
   background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.9),
      rgba(234, 234, 234, 0.9)
   );

   // What purposes does this top position have?
   ${theme.below.xl} {
      /* top: 4.063rem; */
   }

   // What purposes does this top position have?
   ${theme.below.lg} {
      /* top: 4.563rem; */
   }

   ${theme.below.md} {
      padding-top: 2rem;
      top: 4rem;
      z-index: 0;
   }

   &.search-result-visible {
      display: block;
   }

   &.search-result-hidden {
      display: none;
   }

   .search-result-title {
      font-size: 1.3rem;
      font-weight: 700;
      margin-bottom: 0;
      ${theme.above.md} {
         margin-bottom: 1rem;
      }
   }

   li {
      list-style: none;
   }

   li a {
      color: #000;
      font-weight: 500;
   }

   .close-search-icon-container {
      position: relative;
      margin-bottom: 0rem;
      ${theme.above.md} {
         margin-bottom: 1rem;
      }
   }

   #close-search-button {
      position: absolute;
      top: 5px;
      left: 4px;
      width: 40px;
      height: 40px;
      cursor: pointer;
   }

   .search-card-wrapper {
      ${theme.below.lg} {
         height: 28vh;
         overflow: auto;
         margin-bottom: 1rem;
      }

      @media only screen and (max-width: 768px) and (max-height: 1000px) {
         overflow: auto;
         /* height: 39vh; */

         height: clamp(50px, 34vh, 600px);
         margin-bottom: 0.5rem;
         padding-bottom: 0px;
      }
   }

   .search-proposals-wrapper {
      @media only screen and (max-width: 768px) and (max-height: 895px) {
         overflow: auto;
         height: clamp(50px, 22vh, 220px);
      }
      @media only screen and (max-width: 768px) and (min-height: 896px) {
         height: auto;
      }
   }
`

const SearchResult = () => {
   const {
      cancelCloseSearch,
      debouncedCloseSearch,
      showSearch,
      setShowSearch,
      searchValue,
      setShowOverlay,
   } = useContext(SiteContext)
   const selectedChannel = useContext(ChannelContext)?.selectedChannel
   const apiKey = getFindifyApiKey(selectedChannel?.id)
   const [height, setHeight] = useState(0)
   const [searchData, setSearchData] = useState({})
   const [cachedSearchValue, setCachedSearchValue] = useState('dummy')
   const [isLoading, setIsLoading] = useState(false)
   const searchResultRef = useRef(null)
   const t = useIntl()

   const [getSetting, loadingSiteSettings] = useSiteSettingsQuery()
   const productSettings = getSetting(null, Product_Page_Settings)
   const history = useHistory()

   const fetchInit = async (itemsCount, suggestionCount) => {
      // if (cachedSearchValue == searchValue) {
      //    return
      // }
      // setIsLoading(true)
      let data = {}
      data = await fetchFindifyAutoComplete(
         apiKey,
         searchValue,
         itemsCount,
         suggestionCount
      )

      setSearchData(data)
      setCachedSearchValue(searchValue)
      // setIsLoading(false)
   }

   const handleClickInDom = event => {
      // console.log(`DOM: event id: ${event?.target?.id}`, )
      // console.log("current element", event?.target)
      // console.log("parent element", event?.target?.parentElement)

      if (event?.target?.id == 'close-search-button') {
         debouncedCloseSearch()
      }

      if (
         !searchResultRef.current?.contains(event.target) &&
         (event?.target?.id != 'searchfield-mobile' && event?.target?.id != 'searchfield-desktop') &&
         (event?.target?.id != 'root') && ( event?.target?.id != 'clear-search')
      ) {
         debouncedCloseSearch()
      }
   }

   useEffect(() => {
      fetchInit(6, 6)
      setHeight(showSearch ? 'auto' : 0)
      setShowOverlay(showSearch)
      document.addEventListener('click', handleClickInDom)
      document.body.classList.toggle('prevent-body-scroll', showSearch)

      return () => {
         document.removeEventListener('click', handleClickInDom)

         document.body.classList.remove('prevent-body-scroll')
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [showSearch, searchValue])

   async function fetchPossibleRedirectUrl(searchStr) {
      setIsLoading(true)
      let redirectUrl = null
      try {
         const data = await fetchFindifyAutoComplete(apiKey, searchStr, 0, 0)
         // Check for a redirect URL in the response
         if (data?.redirect?.url) {
            // Format and handle the redirect URL
            redirectUrl = new URL(data.redirect.url)?.pathname
         }
      } catch (error) {
         // console.error("Failed to fetch possible redirectURL", error)
         redirectUrl = null
      } finally {
         setIsLoading(false)
      }
      return redirectUrl
   }

   const handleSearchBtnClick = async (searchStr, reqData) => {
      let redirectPath = null

      // Check if last autoCompleteResp with redirectPath exist, ensure that last searchStr resp is in sync with
      // current searchStr in searchInput. Otherwise make a new request to check if
      // current searchStr has a redirectURL
      if (reqData?.redirect?.url && reqData?.meta?.q === searchStr) {
         redirectPath = formatRedirectUrl(reqData?.redirect?.url)
         // redirectPath = new URL(reqData?.redirect.url)?.pathname
      } else {
         // Make a new autoComplete req, get possible redirectURL
         redirectPath = await fetchPossibleRedirectUrl(searchStr)
      }
      setShowSearch(false)
      history.push(redirectPath ?? `/search?q=${searchStr}`)
      clearInputSearchFieldsById()
   }

   return (
      <SearchResultContainer
         className={`${
            showSearch ? 'search-result-visible' : 'search-result-hidden'
         }`}
         duration={500}
         height={height}
      >
         <div
            className="px-128 py-64 mb-16"
            ref={searchResultRef}
            onClick={() => cancelCloseSearch()}
         >
            <div className="row">
               <div className="search-proposals-wrapper col-12 col-xl-2 order-xl-1 order-2 pt-3 pt-md-0">
                  <div className="row">
                     <div className="col-6 col-xl-12 pt-md-4">
                        <p className="search-result-title mb-1">
                           {t('Search-result')}
                        </p>
                        <ul className="m-0 p-0">
                           {searchData?.suggestions
                              ?.filter(sugg => !sugg.hasOwnProperty('redirect'))
                              ?.map((sugg, i) => {
                                 return (
                                    <li key={i}>
                                       <Link
                                          to={`/search?q=${sugg.value}`}
                                          className="link link--vb"
                                          onNavigation={() => {
                                             clearInputSearchFieldsById()
                                             setShowSearch(false)
                                          }}
                                       >
                                          {sugg.value}
                                       </Link>
                                    </li>
                                 )
                              })}
                        </ul>
                     </div>
                     <div className="col-6 col-xl-12 pt-0 pt-xl-3">
                        <p className="search-result-title pt-0 pt-md-4 mb-1">
                           {t('categories')}
                        </p>
                        <ul className="m-0 p-0">
                           {searchData?.suggestions
                              ?.filter(sugg => sugg.hasOwnProperty('redirect'))
                              ?.map((sugg, i) => {
                                 return (
                                    <li key={i}>
                                       <Link
                                          to={
                                             new URL(sugg.redirect.url).pathname
                                          }
                                          onNavigation={() => {
                                             clearInputSearchFieldsById()
                                             setShowSearch(false)
                                          }}
                                          className="link link--vb"
                                       >
                                          {sugg.value}
                                       </Link>
                                    </li>
                                 )
                              })}
                        </ul>
                     </div>
                  </div>
               </div>
               <div className="col-12 col-xl-10 order-xl-2 order-1">
                  <div className="d-flex justify-content-between align-items-center">
                     <p className="search-result-title">
                        {t('product-result-suggestions')}
                     </p>

                     <div className="close-search-icon-container">
                        <CancelIcon
                           strokeWidth={2.0}
                           width={50}
                           height={50}
                           className="cancel-icon"
                        ></CancelIcon>
                        <div id="close-search-button"></div>
                     </div>
                  </div>

                  {/* Desktop div */}
                  <div className="row pb-16 d-flex search-card-wrapper">
                     <Above breakpoint="xxxl">
                        <>
                           {searchData?.items?.map((product, i) => {
                              return (
                                 <div className="col-2 d-flex" key={i}>
                                    <ProductListCard
                                       product={product}
                                       productSettings={productSettings}
                                       closeModal={() => {
                                          clearInputSearchFieldsById()
                                          setShowSearch(false) 
                                       }}
                                    ></ProductListCard>
                                 </div>
                              )
                           })}
                        </>
                     </Above>
                     <Below breakpoint="xxxl">
                        <Above breakpoint="xxl">
                           <>
                              {elementsToKeepInArray(
                                 searchData?.items ?? [],
                                 4
                              )?.map((product, i) => {
                                 return (
                                    <div className="col-3 d-flex" key={i}>
                                       <ProductListCard
                                          product={product}
                                          productSettings={productSettings}
                                          closeModal={() => {
                                             clearInputSearchFieldsById()
                                             setShowSearch(false) 
                                          }}
                                       ></ProductListCard>
                                    </div>
                                 )
                              })}
                           </>
                        </Above>
                     </Below>
                     <Below breakpoint="xxl">
                        <>
                           {elementsToKeepInArray(
                              searchData?.items ?? [],
                              3
                           )?.map((product, i) => {
                              return (
                                 <div className="col-12" key={i}>
                                    <ProductSearchCardHorizontal
                                       onNavigationClick={() => {
                                          clearInputSearchFieldsById()
                                          setShowSearch(false) 
                                       }}
                                       product={product}
                                    ></ProductSearchCardHorizontal>
                                 </div>
                              )
                           })}
                        </>
                     </Below>
                  </div>

                  <div className="d-flex justify-content-center">
                     <VbButton
                        // value={t('show-all-products')}
                        // href={`/search?q=${searchValue}`}
                        size="medium"
                        styleType="solid"
                        color="blue"
                        disabled={!searchValue}
                        className="w-100 py-8"
                        onClick={() =>
                           handleSearchBtnClick(searchValue, searchData)
                        }
                     >
                        {t('show-all-products')}{' '}
                        <LoadingArrowIcon loading={isLoading} />
                     </VbButton>
                  </div>
               </div>
            </div>
         </div>
      </SearchResultContainer>
   )
}

export default SearchResult