import React, { useContext } from 'react'
import { styled } from 'linaria/react'
import { theme } from '../../Theme'
import { getStringByPropertyName } from '../../Core/Util/Helpers'
import { Below } from '@jetshop/ui/Breakpoints'
import FooterTrustIcons from './FooterTrustIcons'
import Link from '../../Core/Link'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import UserIcon from '../Header/Components/UserIcon'
import UserFieldFooter from './UserFieldFooter'
import ChannelSelector from '../Header/ChannelSelector'
import t from '@jetshop/intl'

const FooterBodyContainer = styled.div`
   background: #282827;

   ul li {
      list-style: none;
      margin-bottom: 4px;
   }
`

const FooterBody = ({ bodyColumnBlocks, bodyColumnIcons }) => {
   const selectedChannel = useContext(ChannelContext)?.selectedChannel

   return (
      <FooterBodyContainer className="col-12 pb-64 px-128">
         <div className="d-flex row py-64">
            <div className="d-flex justify-content-start justify-content-lg-center mt-32 ml-8 ml-lg-0">
            <h4 className="mt-0">{t('language selector')}</h4>
            </div>
            <div className="d-flex justify-content-start justify-content-lg-center mb-32 ml-8 ml-lg-0">
               <ChannelSelector></ChannelSelector>
            </div>
         </div>
         <div className="row m-0 p-0">
            {bodyColumnBlocks?.map((columnBlock, i) => {
               const title = getStringByPropertyName(
                  columnBlock?.properties,
                  'title'
               )
               const content = getStringByPropertyName(
                  columnBlock?.properties,
                  'content'
               )
               return (
                  <div key={i} className="col-12 col-lg-3">
                     <h4 className="mt-0 text-uppercase">{title}</h4>
                     <div
                        dangerouslySetInnerHTML={{
                           __html: content,
                        }}
                     />
                  </div>
               )
            })}
            <div className="col-12 col-lg-3">
               {selectedChannel?.id && selectedChannel?.id !== 3 && (
                  <div className="col-12">
                     <UserFieldFooter />{' '}
                  </div>
               )}
               {bodyColumnIcons?.slice(2)?.map((imageIcon, i) => {
                  const image = getStringByPropertyName(
                     imageIcon?.properties,
                     'image'
                  )
                  const imageAlt = getStringByPropertyName(
                     imageIcon?.properties,
                     'altText'
                  )
                  const link = getStringByPropertyName(
                     imageIcon?.properties,
                     'link'
                  )
                  return (
                     <div key={i} className="col-12">
                        <Link
                           to={link ?? ''}
                           isExternalTarget
                           className="hover-opacity"
                        >
                           <img
                              src={image}
                              alt={imageAlt}
                              className="pt-2"
                              loading="lazy"
                           />
                        </Link>
                     </div>
                  )
               })}
            </div>
            <FooterTrustIcons
               bodyColumnIcons={bodyColumnIcons}
            ></FooterTrustIcons>
         </div>
      </FooterBodyContainer>
   )
}

export default FooterBody
