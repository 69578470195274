const { default: themeVariables } = require('../theme-variables')

const pxToRem = px => `${px / 16}rem`

/**
 * Returns Contentful srcSet
 *
 * @param {String} imageUrl
 * @example
 *       src={imageUrl}
         srcSet={getSrcSet(imageUrl)}
 */
const getSrcSet = imageUrl => {
   if (!imageUrl) return ''

   let sizes = []
   for (const [, value] of Object.entries(themeVariables?.breakpoints)) {
      sizes.push(parseInt(value.replace('rem', '') * 16))
   }

   let srcSet = ''
   for (const size of sizes) {
      srcSet += `${imageUrl}?extend=copy&width=${size}&method=thumbnail&type=webp ${size}w,`
   }

   return srcSet
}

module.exports = { pxToRem, getSrcSet }
