import { useContext, useState} from "react"
import { SiteContext } from "../../Global/SiteContext"
import { debounce } from "lodash";
import { useHandleRedirectSearchPath } from "./useHandleSearch";

const UseSearchLogic = (inputRef) => {
    const { handleSearchbarChange, performSearch } = useContext(SiteContext)
    const [currentSearchValue, setCurrentSearchValue] = useState("")
    const { handleFetchPossibleRedirectUrl, loading: isLoadingRedirectReq } = useHandleRedirectSearchPath()

    const debouncedSetSearchTerm = debounce(newTerm => {
        handleSearchbarChange(newTerm)
     }, 225)
    
    const handleInputChange = event => {
        const newTerm = event.target.value
        setCurrentSearchValue(newTerm)
        debouncedSetSearchTerm(newTerm)
     }

    const handleInputFocus = () => {
      const cachedValue = inputRef.current.value;
      setCurrentSearchValue(cachedValue)
      handleSearchbarChange(cachedValue)
    }

    const handleKeyPressed = async (event) => {
        if (event.key === 'Enter') {
            const redirectPath = await handleFetchPossibleRedirectUrl(currentSearchValue)
            performSearch(redirectPath, currentSearchValue)
            setCurrentSearchValue("")
        }
    }

    const handleClear = (clearValue = "") => {
      if (inputRef.current) {
         inputRef.current.value = clearValue;
         setCurrentSearchValue(clearValue)
         debouncedSetSearchTerm(clearValue)
         inputRef.current.focus()
      }
    };

    return ({handleInputChange, handleInputFocus, handleKeyPressed, isLoadingRedirectReq, handleClear});
}
 
export default UseSearchLogic;