import React, { useState, useEffect, useContext } from 'react'
import { styled } from 'linaria/react'
import UseFormatProduct from '../Hooks/UseFormatProduct'
import Title from '../Title'
import { useProductList } from '@jetshop/core/hooks/ProductList'
import { CartAlt, Minus, Plus } from 'iconoir-react'
import VbButton from '../SiteButton'
import { Cancel as CancelIcon } from 'iconoir-react'
import { ReactComponent as InStockIcon } from '../../../svg/InStock.svg'
import { ReactComponent as PrebookIcon } from '../../../svg/Prebook.svg'
import { theme } from '../../Theme'
import { Above } from '@jetshop/ui/Breakpoints'
import QuantityButton from '../Buttons/QuantityButton'
import Image from '@jetshop/ui/Image/Image'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import PriceSection from './PriceSection'
import { useIntl } from '@jetshop/intl'
import Link from '../Link'

const ProductCardContainer = styled.div`
   a {
      font-family: var(--font-grotesk);
      color: white;
      text-decoration: none;
   }

   &.product-card-horizontal {
      width: 100%;
      background: var(--color-white);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
      scale: 1;
      transition: all 0.5s ease;
   }

   &.product-card-horizontal .add-to-cart {
      border-radius: 50px;
   }

   &.product-card-horizontal .add-to-cart svg {
      filter: invert(100%);
      height: 1.3rem;
   }

   .product-card-horizontal-cart button svg {
      width: 1.25rem;
      height: 1.25rem;
      ${theme.below.md} {
         width: 1.25rem;
         height: 1.25rem;
      }
   }

   .product-card-image-container {
      ${theme.below.md} {
         margin: auto;
      }
   }

   .product-card-image {
      width: 100%;
      object-fit: contain;
      border-radius: 8px 8px 0 0;
      padding: 0.5rem;
      ${theme.above.md} {
         aspect-ratio: 1/1;
         padding: 0.75rem;
      }
   }

   .product-card-wishlist {
      width: 100%;
      position: absolute;
      top: 0;
   }

   .product-card-sku {
      font-size: 0.688rem;
      font-family: var(--font-grotesk);
      font-weight: 600;
   }

   .product-card-stock {
      font-family: var(--font-grotesk);
      font-weight: 600;
      font-size: 0.75rem;
   }

   .product-card-brand {
      font-size: 0.65rem;
      font-weight: 700;
      font-family: var(--font-industry);
   }

   .product-card-content {
      font-size: var(--fontsize-075);
      font-weight: 600;
   }

   .product-card-content a {
      font-family: var(--font-industry);
      color: black;
      font-size: 1rem;
      font-weight: 700;
      padding-bottom: 2px;
   }

   .product-card-cta {
      font-family: var(--font-industry);
      font-weight: 700;
      text-transform: uppercase;
      color: white;
      text-align: center;
      background-color: #59a021;
      transition: all 0.3s ease;
   }

   .product-card-cta:hover {
      background-color: #4f8e1c;
      transition: all 0.3s ease;
   }

   .product-card-cart-button {
      padding: 0.4rem 1.5rem 0.4rem 1.5rem !important;
      ${theme.below.md} {
         padding: 0.5rem 1.25rem 0.5rem 1.25rem !important;
      }
   }

   .product-card-stock-container {
      span {
         font-family: var(--font-grotesk);
         font-size: 0.75rem;
         font-weight: 700;
         ${theme.below.sm} {
            width: 60px;
         }
      }

      svg {
         width: 16px;
         height: 16px;
      }
   }

   .cancel-icon {
      cursor: pointer;
      margin-top: -5px;
      color: var(--color-vb-blue);
   }

   .cancel-icon:hover {
      opacity: 0.75;
      transition: opacity 0.5s ease;
   }
`

const ProductCartCard = ({
   product,
   updateQuantity,
   removeProduct,
   qty,
   // preBookCommentData,
   cartItemId,
   setIsOpen,
}) => {
   const { selectedChannel } = useContext(ChannelContext)
   const t = useIntl()

   const { title, articleNumber, imageUrl, url, isPreBook, brandName } =
      UseFormatProduct(product)

   const UpdateQuantity = newValue => {
      updateQuantity(cartItemId, newValue)
   }

   const removeFromCart = () => {
      removeProduct(product, cartItemId)
   }

   return (
      <ProductCardContainer className="product-card-horizontal mb-16 br-8 d-flex flex-column">
         <div className="d-flex flex-row">
            <div className="product-card-image-container col-4 col-sm-3 position-relative">
               <Link to={url} onNavigation={() => setIsOpen(false)}>
                  <Image
                     className="product-card-image p-16"
                     src={imageUrl}
                     aspect="1:1"
                  ></Image>
               </Link>
            </div>

            <div className="col-6 col-sm-7 d-flex flex-column flex-grow-1 p-16">
               <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column">
                     <div className="product-card-sku mb-0 mt-0">
                        ARTNR: {articleNumber}
                     </div>
                     {brandName && (
                        <div className="product-card-brand text-truncate mb-1 mt-0">
                           {brandName}
                        </div>
                     )}
                  </div>
                  <CancelIcon
                     strokeWidth={2.0}
                     className="cancel-icon"
                     onClick={() => removeFromCart()}
                  ></CancelIcon>
               </div>

               <div className="product-card-content d-flex flex-column flex-grow-1 lh-1 mt-0">
                  <Link
                     to={url}
                     className="text-truncate mb-2"
                     title={title}
                     onNavigation={() => setIsOpen(false)}
                  >
                     {title}
                  </Link>

                  {/* {preBookCommentData && ( */}
                  {isPreBook && (
                     <div className="d-flex px-6 align-items-center gap-2">
                        <PrebookIcon>                           
                        </PrebookIcon>
                        <span className="text-truncate">
                           {t('book in advance')}
                        </span>
                     </div>
                  )}
               </div>

               <div className="d-flex flex-row justify-content-between align-items-end">
                  <PriceSection
                     product={product}
                     selectedChannel={selectedChannel}
                     overrideWrapperClass={` `}
                  ></PriceSection>
                  <QuantityButton
                     qty={qty}
                     updateQuantity={UpdateQuantity}
                  ></QuantityButton>
               </div>
            </div>
         </div>
      </ProductCardContainer>
   )
}

export default ProductCartCard