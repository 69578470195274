import React, { useState, useContext, useEffect } from 'react'
import { styled } from 'linaria/react'
import { theme } from '../../Theme'
import { MailOpened, NavArrowRight } from 'iconoir-react'
import { useIntl } from '@jetshop/intl'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { useNewsletterSubscription } from '@jetshop/core/hooks/Subscriptions/useNewsletterSubscription'
import VbCheckbox from '../../Core/Form/VbCheckbox'
import Spinner from '../../ui/Spinner'
import { cx } from 'linaria'
import SiteButton from '../../Core/SiteButton'

const SubscribeToNewsletterContainer = styled.div`
   .newsletter-input {
      border-radius: 50px;
      padding-right: 3.5rem !important;
   }

   .newsletter-form {
      width: 100%;
      max-width: 525px;
   }

   .error-message-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 20px;
      width: 100%;
      margin-bottom: 0.25rem;
   }

   .sub-title {
      font-size: 1.1rem;
      font-weight: 600;
   }

   .submit-btn-position {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
   }
   .submit-btn-class-test {
      min-width: 32px;
      min-height: 32px;
   }
`
const StyledSpinner = styled(Spinner)`
   height: 20px;
   width: 20px;
   circle.path {
      stroke: white;
   }
`
const ErrorText = styled.span`
   color: ${theme.colors.lightErrorRed};
   font-size: 14px;
   font-weight: 600;
`

const ConsentLabel = styled.div`
   text-align: left;
   a {
      color: white;
      font-size: 1rem;
      font-weight: 600;
   }
`

const StyledVBCheckbox = styled(VbCheckbox)`
   #consent {
      margin-top: 0.15em;
      align-self: flex-start;
   }
`

const StyledSiteButton = styled(SiteButton)`
   min-width: 32px;
   min-height: 32px;
`

const SubscribeToNewsletter = ({ title, subTitle, className }) => {
   const t = useIntl()
   const selectedChannel = useContext(ChannelContext)?.selectedChannel

   const [consent, setConsent] = useState(false)
   const {
      inputProps,
      submit,
      submitting,
      submitted,
      failed,
      errorStates: { alreadySubscribed, invalidEmail },
   } = useNewsletterSubscription({
      enabled: true,
   })

   // Reset consent when submitted
   useEffect(() => {
      if (submitted) {
         setConsent(false)
      }
   }, [submitted])

   const getConsentLink = () => {
      switch (selectedChannel?.id) {
         case 1:
            return '/integritetspolicy'
         case 2:
            return '/integritetspolitik'
         case 3:
            return '/personvernerkaering'
         default:
            return '/integritetspolicy'
      }
   }

   const handleConsentChange = event => {
      setConsent(event.target.checked)
   }

   const showSuccess =
      submitted && !failed && !alreadySubscribed && !invalidEmail

   const disabled = inputProps?.value?.length === 0 || submitting || !consent

   return (
      <SubscribeToNewsletterContainer
         className={cx(
            'd-flex flex-column justify-content-center align-items-center position-relative',
            className
         )}
      >
         <h2 className="m-0 text-uppercase">{title}</h2>
         <p className="mt-2 mb-0 sub-title">{subTitle}</p>
         <form
            onSubmit={submit}
            disabled={disabled}
            className="newsletter-form d-flex flex-column"
         >
            <div className="error-message-area">
               {alreadySubscribed ? (
                  <ErrorText>
                     {t('You have already subscribed to the newsletter!')}
                  </ErrorText>
               ) : invalidEmail ? (
                  <ErrorText>
                     {t('Something went wrong. Please check your email and try again.')}
                  </ErrorText>
               ) : failed ? (
                  <ErrorText>
                     {t('Something went wrong. Please try again later.')}
                  </ErrorText>
               ) : null}
            </div>
            <div style={{ position: 'relative', width: '100%' }}>
               <input
                  // type="email"
                  className="newsletter-input form-control flex-grow-1 flex-md-grow-0 w-100 m-0 py-2 px-4"
                  placeholder={t('provide your email address')}
                  data-testid="newsletter-subscription-input"
                  {...inputProps}
               />
               <StyledSiteButton
                  className="submit-btn-position"
                  type="submit"
                  styleType="solid"
                  color="blue"
                  icon={true}
                  disabled={disabled || submitting}
               >
                  {submitting ? (
                     <StyledSpinner color="white" />
                  ) : (
                     <NavArrowRight strokeWidth={2.0} color="white" />
                  )}
               </StyledSiteButton>
            </div>
         </form>
         <div className="d-flex justify-content-center">
            <StyledVBCheckbox
               showDefaultCheckmarkIcon={true}
               id="consent"
               name="consent"
               className="mt-16 d-flex gap-2"
               checked={consent}
               onChange={handleConsentChange}
               label={
                  <ConsentLabel>
                     {t('consent-confirm')}{' '}
                     <a
                        className="link link--vb link--white"
                        href={getConsentLink()}
                        target="_blank"
                        rel="noreferrer"
                     >
                        {t('readmore')}{' '}
                        <NavArrowRight fontSize={'0.6em'} strokeWidth={4.0} />
                     </a>
                  </ConsentLabel>
               }
            />
         </div>
         {showSuccess && <NewsletterSuccess />}
         {/* <NewsletterSuccess /> */}
      </SubscribeToNewsletterContainer>
   )
}

export default SubscribeToNewsletter

// NewsletterSuccessComponent
const SuccessOverlay = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: var(--color-vb-blue);
   display: flex;
   align-items: center;
   justify-content: center;
   animation: fadeIn 0.3s ease-in-out;

   @keyframes fadeIn {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
   }
`
const SuccessContentWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 0.5rem;
`
const SuccessMessageContent = styled.div`
   color: white;
   font-size: 1.2rem;
   display: flex;
   gap: 0.5rem;
   align-items: center;
   font-weight: 700;
`
const SuccessSubMessageContent = styled.div`
   font-size: 1rem;
   display: flex;
   gap: 0.5rem;
   align-items: center;
`

const NewsletterSuccess = () => {
   const t = useIntl()
   return (
      <SuccessOverlay>
         <SuccessContentWrapper>
            <MailOpened
               className="mb-2"
               strokeWidth={2.0}
               width={'3rem'}
               height={'3rem'}
            />
            <SuccessMessageContent>
               {t('thank you and welcome')}
            </SuccessMessageContent>
            {/* <SuccessSubMessageContent>
               {t('verify newsletter registration message')}
            </SuccessSubMessageContent> */}
         </SuccessContentWrapper>
      </SuccessOverlay>
   )
}
