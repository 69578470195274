import { useContext, useEffect, useRef, useState } from "react";
import { SiteContext } from "../../Global/SiteContext";
import ChannelContext from "@jetshop/core/components/ChannelContext";

const IDENTIFIERS = {
   VENDOR_NO_PROD: 5564574712,
   UNIQUE_FORM_ID: 'edge-finans-hidden-form',
}

const NO_OF_MONTHS = {
   12: 12,
   24: 24,
   36: 36,
   48: 48,
   60: 60,
}

const URLS = {
   REDIRECT: '', // Thank you page passed to Edge Finans
   ORIGIN: 'https://verktygsboden.se/', // Origin passed to Edge Finans
   ACTION_REDIRECT: `https://checkout.edgefinanslaget.se/?v=${IDENTIFIERS.VENDOR_NO_PROD}`,
   EDGE_FINANS_CALCULATE: 'https://plenum.digifiservices.se/prod/vb-proxy/api/edge-finans/calculate',
}

const extractEdgeFinansValues = productItem => {
   const { product, quantity, unitPrice, previousUnitPrice, discounts } = productItem ?? {};

   const hasPackageDiscount = discounts?.some(d => 
      typeof d?.name === 'string' && d?.name.toLowerCase() === 'paket'
   );
 
   const productObject = hasPackageDiscount 
     ? { ...product ?? {}, price: unitPrice, previousPrice: previousUnitPrice }
     : product ?? {};
 
   const { name, articleNumber, images, price, previousPrice } = productObject ?? {}

   return {
      productNameWithArticleNumber: `${name} - Art.nr: ${articleNumber}`,
      articleNumber,
      name,
      imageUrl: images?.[0]?.url,
      quantity,
      currentPrice: price?.incVat, // formatPrice(price?.incVat),
      exVatCurrentPrice: price?.exVat, // formatPrice(price?.exVat),
      ...(previousPrice?.incVat !== price?.incVat && {
         oldPrice: previousPrice?.incVat,
         exVatOldPrice: previousPrice?.exVat,
      }),
   }
}

const createPayload = cart => {
   return {
      exclusiveTax: true,
      vendorNo: IDENTIFIERS.VENDOR_NO_PROD,
      redirectUrl: URLS.REDIRECT,
      origin: URLS.REDIRECT,
      products: cart?.items?.reduce((acc, productItem) => {
         const {
            productNameWithArticleNumber,
            imageUrl,
            exVatCurrentPrice,
            quantity,
         } = extractEdgeFinansValues(productItem) ?? {}
         // VALIDATE PRODUCT DATA? >
         return [
            ...acc,
            {
               description: productNameWithArticleNumber,
               unitPrice: exVatCurrentPrice,
               quantity: quantity,
               image: imageUrl,
            },
         ]
      }, []),
   }
}

const handleEdgeFinansCheckout = ({ cart, redirectNewWindow }) => {
   // VALIDATE CART DATA? >

   // CREATE PAYLOAD
   const payload = createPayload(cart)

   // VALIDATE PAYLOAD? >

   // CREATE FORM
   const form = document.createElement('form')
   form.method = 'POST'
   form.action = URLS.ACTION_REDIRECT
   form.id = IDENTIFIERS.UNIQUE_FORM_ID
   form.target = redirectNewWindow ? '_blank' : '_self'

   // CREATE INPUT AND SET VALUE
   const input = document.createElement('input')
   input.type = 'hidden'
   input.name = 'jsobject'
   input.value = JSON.stringify(payload)

   // APPEND FORM AND INPUT TO BODY
   form.appendChild(input)
   document.body.appendChild(form)

   form.submit()

   // REMOVE FORM AFTER SUBMIT (to avoid duplicate forms in body)
   setTimeout(() => {
      const formToRemove = document.getElementById(IDENTIFIERS.UNIQUE_FORM_ID)
      if (formToRemove) {
         document.body.removeChild(formToRemove)
      }
   }, 0)
}

const getEdgeFinansInfo = async ({ totalAmount, noOfMonths }) => {
   try {
     if (typeof totalAmount !== 'number' || typeof noOfMonths !== 'number' ||
         isNaN(totalAmount) || isNaN(noOfMonths) ||
         totalAmount <= 0 || noOfMonths <= 0) {
       throw new Error('Invalid payload values edge finans.');
     }
 
     const baseUrl = URLS.EDGE_FINANS_CALCULATE;
 
     const payload = JSON.stringify({
       totalAmount: totalAmount,
       noOfMonths: noOfMonths,
       residualAmount: totalAmount * 0.1,
       monthlyPayment: 0,
       downPayment: 0
     });
 
     const response = await fetch(baseUrl, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json',
       },
       body: payload,
     });
 
     if (!response.ok) {
       throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
     }

     return response.json();
 
   } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
      throw new Error(errorMessage);
   }
 }

const useEdgeFinans = (cart) => {
   const { showVat } = useContext(SiteContext)
   const selectedChannelId = useContext(ChannelContext)?.selectedChannel?.id
   const [edgeFinansData, setEdgeFinansData] = useState(null)
   const [edgeFinansIsLoading, setEdgeFinansIsLoading] = useState(false)
   const prevCartTotalExVat = useRef(null)

   useEffect(() => {
      if (
         selectedChannelId !== 1 ||
         showVat !== false ||
         !cart?.productTotal?.exVat ||
         prevCartTotalExVat?.current === cart?.productTotal?.exVat
      ) {
         return
      }

      ;(async () => {
         setEdgeFinansIsLoading(true)
         try {
            const response = await getEdgeFinansInfo({
               totalAmount: cart?.productTotal?.exVat,
               noOfMonths: NO_OF_MONTHS[36],
            })
         setEdgeFinansData(response)
         } catch (error) {
            setEdgeFinansData(null)
         } finally {
            setEdgeFinansIsLoading(false)
         }
      })()

      prevCartTotalExVat.current = cart?.productTotal?.exVat

   }, [cart, showVat, selectedChannelId])

   return { handleEdgeFinansCheckout, edgeFinansData, edgeFinansIsLoading}
}

export default useEdgeFinans


// VALIDATE CART DATA *
// if (!cart || !Array.isArray(cart.items)) {
//   console.error('Invalid cart data to checkout with Edge Finans.');
//   return null;
// }

// VALIDATE PRODUCT DATA *
//  if (!articleNumber || !exVatCurrentPrice || !quantity ) {
//    console.warn('Incomplete product data:', item.product);
//    return acc;
//  }

// VALIDATE PAYLOAD *
//   if (!payload || payload.products.length === 0) {
//     console.warn('Invalid Edge finans payload or no products found.');
//     return;
//   }


// Adding more robust error logging getEdgeFinansInfo
// if (error) {
//    console.log('Error:', error?.message);

//    // Capture additional details if available
//    if (error instanceof TypeError) {
//      console.log('Network error or URL problem:');
//    } else {
//      console.log('Unexpected error:');
//    }
//  } else {
//    console.log('Unknown error occurred');
//  }