import { fetchNewUserTrackingId } from "./Api"

export const HelloRetailHelper = (key, helloRetailSettings) => {
   if (!helloRetailSettings?.children) {
      return { title: '', value: '' }
   }
   for (let helloRetailSetting of helloRetailSettings?.children) {
      if (
         key ==
         helloRetailSetting?.properties?.find(prop => prop.name == 'key')?.value
            ?.string
      ) {
         return {
            title: helloRetailSetting?.properties?.find(
               prop => prop.name == 'title'
            )?.value?.string,
            value: helloRetailSetting?.properties?.find(
               prop => prop.name == 'value'
            )?.value?.string,
         }
      }
   }
}

export const getHelloRetailTrackingId = async (websiteUuid) => {
   const cookieName = 'hello_retail_id'
   let cookieValue = getCookie(cookieName)

   if (!cookieValue) {
      const response = await fetchNewUserTrackingId(websiteUuid);
      cookieValue = response?.id;
      setCookie(cookieName, cookieValue)
   }

   return cookieValue
}

export const getHelloRetailProducts = (data, key) => {
   return data?.find(res => res?.key == key?.value)?.products
}

export const getHelloRetailChannelConfigs = (channelId) => {
   let result;
   
   switch (channelId) {
     case 1:
       result = {id: 'd26c85d0-00d0-47af-9767-b8d0a4fda2d9', domain: "https://verktygsboden.se"};
       break;
     case 2:
       result = {id: 'e578b807-4c52-424c-931d-204c356af75d', domain: "https://verktygsboden.dk"};
       break;
     case 3:
      result = {id: 'c81f7f40-1649-45c4-838a-430130a1f111', domain: "https://verktygsboden.no"};
       break;
     default:
      result = {id: 'd26c85d0-00d0-47af-9767-b8d0a4fda2d9', domain: "https://verktygsboden.se"};
   }
 
   return result;
};

export const getCategoryHierarchies = (parents, currentCategory) => {
   let categoryTitles = [];
   if (parents) {
      for (let p of parents) {
         // Unshift(add to the beginning of the array), keep the correct hiearchial order
         categoryTitles.unshift(p?.object?.breadcrumbText);
      }
   }
   if (currentCategory) {
      // Push(Add to the end of the array) to the end of the array, keep the correct hiearchial order
      categoryTitles.push(currentCategory?.breadcrumbText);
   }
   return categoryTitles;
};

export function getCookie(cookieName) {
   const cookies = document.cookie.split(';')

   for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.startsWith(cookieName + '=')) {
         return cookie.substring(cookieName.length + 1)
      }
   }

   return null
}

function setCookie(cookieName, cookieValue) {
   const expirationDate = new Date()
   expirationDate.setFullYear(expirationDate.getFullYear() + 1) // Set cookie expiration to 1 year from now

   const cookieString = `${cookieName}=${cookieValue};expires=${expirationDate.toUTCString()};path=/`
   document.cookie = cookieString
}

export function expireCookieImmediately(cookieName) {
   const expirationDate = new Date(0); // Set expiration date to the past (1970-01-01)
   const cookieString = `${cookieName}=; expires=${expirationDate.toUTCString()}; path=/`;
   document.cookie = cookieString;
}
