import React, { useContext } from 'react'
import { styled } from 'linaria/react'
import { cx } from 'linaria'
import { theme } from '../../Theme'
import SwedenLogo from '../../../svg/verktygsboden-v3-sv.svg'
import MobileLogo from '../../../svg/VB-logo.svg'
import { Above, Below } from '@jetshop/ui/Breakpoints'
import Menu from './Menu'
import SearchBar from './SearchBar'
import VatSelector from './Components/VatSelector'
import CartIcon from '../../Cart/CartIcon'
import WhishlistIcon from './Components/WishlistIcon'
import UserIcon from './Components/UserIcon'
import SearchResult from './SearchResult'
import Logo from '../../Core/CustomLogos/Logo'
import Link from '../../Core/Link'
import { useHeaderContext } from './HeaderContext/HeaderContext'
import ChannelContext from '@jetshop/core/components/ChannelContext'

const ActionHeaderContainer = styled.div`
    background-color: ${theme.colors.siteBlue};

   .logo {
      background-color: ${theme.colors.siteBlue};
      transition: padding 0.1s ease;
   }

   .logo-desktop {
      opacity: 1;
      transition: opacity 0.5s ease;
   }

   .logo-desktop:hover {
      opacity: 0.75;
      transition: opacity 0.5s ease;
   }

   .menu-content {
      background-color: ${theme.colors.siteBlue};
      /* @media (max-width: 1800px) */
      ${theme.below.xl} {
         padding-right: 1rem !important;
      }
      ${theme.below.sm} {
         background-color: #282827;
      }
   }

   .menu-row {
      background-color: #195178;
      font-family: var(--font-industry);
      font-weight: 700;
   }

   //Badges

   .my-account {
      padding: 0;
   }

   .my-account-link {
      scale: 1;
      transition: all 0.2s;
      position: relative;
   }

   .my-account .my-account-link img {
      width: 1.4rem;
   }

   .my-account .my-account-link:hover {
      scale: 1.1;
      transform: rotate(-2deg);
      opacity: 0.75;
      transition: all 0.2s;
   }

   .badge-counter {
      position: absolute;
      top: -10px;
      right: -10px;
      border-radius: 128px;
   }

   .badge-counter span {
      height: 1rem;
      width: 1rem;
      padding: 10px;
      font-family: var(--font-grotesk);
      font-weight: 700;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-instock);
   }

   .wishlist .badge-counter span {
      background-color: var(--color-red);
   }

   .searchbar-container {
      ${theme.above.xl} {
         margin-right: 1.5rem;
      }
   }

   @media (max-width: 768px) {
      .logo {
         padding: 1rem 1rem !important;
      }
      .menu-button {
         padding: 0 1rem !important;
      }
      .menu-button .menu-icon {
         padding: 0 !important;
      }
      .my-account {
         justify-content: center !important;
         padding: 0 !important;
      }
      .my-account .row {
         width: 100%;
      }
   }

   @media (max-width: 1400px) {
      .nav-link {
         padding: 0 0.5rem;
      }
   }

   @media (max-width: 1600px) {
      .nav-link img {
         display: none;
      }
   }
`

const ActionHeader = ({ categories, mobileMenuCms }) => {
   const { isScrolled } = useHeaderContext()
   const selectedChannel = useContext(ChannelContext)?.selectedChannel

   return (
      <ActionHeaderContainer className="row m-0 p-0 position-relative z-3">
         <div
            className={cx(
               'col-md-auto col-auto logo pl-128 pr-32 d-flex align-items-center',
               isScrolled ? 'py-16' : 'py-32'
            )}
         >
            <Link to="/">
               {/* <img
                   className="logo-desktop d-xl-block d-lg-block"
                   src={SwedenLogo}
                /> */}
               <Logo></Logo>
            </Link>
         </div>

         {/* px-32 */}
         <div className="menu-content col pr-128 pl-32 py-8 d-flex align-items-center">
            <div className="row w-100 m-0 p-0">
               <div className="col ps-0 searchbar-container d-none d-md-block d-xl-block d-lg-block">
                  <SearchBar></SearchBar>
               </div>
               <div className="col p-0 d-lg-flex align-items-center d-none">
                  <VatSelector></VatSelector>
               </div>
               <div className="my-account col-12 col-md-auto pl-0 pr-32 d-flex align-items-center justify-content-end">
                  <div className="row justify-content-end">
                     <div className="cart col-6 col-md-auto d-flex justify-content-center">
                        <CartIcon></CartIcon>
                     </div>
                     <div className="wishlist col-6 col-md-auto d-flex justify-content-center">
                        <WhishlistIcon></WhishlistIcon>
                     </div>
                     <Above breakpoint="lg">
                        {selectedChannel?.id && selectedChannel?.id !== 3 && (
                           <div className="my-profile col-4 col-md-auto d-flex justify-content-center">
                              <UserIcon></UserIcon>
                           </div>
                        )}
                     </Above>
                  </div>
               </div>
            </div>
         </div>
         <Menu categories={categories} mobileMenuCms={mobileMenuCms}></Menu>
      </ActionHeaderContainer>
   )
}

export default ActionHeader
