import React, { createContext, useContext } from 'react'
import { useIsScrolled } from '../../../../hooks/useIsScrolled'

const HeaderContext = createContext()

const HeaderProvider = ({ children }) => {
   const isScrolled = useIsScrolled()

   return (
      <HeaderContext.Provider
         value={{
            isScrolled,
         }}
      >
         {children}
      </HeaderContext.Provider>
   )
}

// Custom hook to use the context
const useHeaderContext = () => {
   const context = useContext(HeaderContext)
   if (!context) {
      throw new Error('useHeaderContext must be used within HeaderProvider')
   }
   return context
}

export { HeaderProvider, useHeaderContext }
