import React from 'react';
import { NavLink } from 'react-router-dom';
import { isRelativeUrl } from '@jetshop/core/helpers/isRelativeUrl';

const Link = ({
  to,
  onNavigation,
  isExternalTarget,
  className,
  children,
  ...props
}) => {

  if (!isRelativeUrl(to) && isExternalTarget) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        {...props}
      >
        {children}
      </a>
    );
  } else if (!isRelativeUrl(to)) {
    return (
      <a href={to} className={className} {...props}>
        {children}
      </a>
    );
  } else {
    return (
      <NavLink to={to} onClick={onNavigation} className={className} {...props}>
        {children}
      </NavLink>
    );
  }
};

export default Link;
