import React, { useContext }  from 'react';
import { styled } from 'linaria/react'
import { SiteContext } from './SiteContext';

const OverlayContainer = styled.div`
    height: 100vh;
    left: 0;
    position: fixed;
    top: 12.5rem;
    width: 100%;
    background-color: rgb(0, 0, 0);
    opacity: 0.5;
    backdrop-filter: blur(2px);
    z-index: 100;
`

const Overlay = () => {
    const { showOverlay } = useContext(SiteContext)

    return (<OverlayContainer className={showOverlay ? "d-block" : "d-none"}></OverlayContainer>);
}
 
export default Overlay;