import * as translations from '../translations'
import channelsQuery from './ChannelsQuery.gql'
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql'
import { addwishTracker } from '@jetshop/flight-addwish';
import routeQuery from './components/RouteQuery.gql'
import { LoadableProductPage, LoadableContentPage } from './components/Shop'
import { theme } from './components/Theme'
import { LoadableCategoryPage } from './components/CategoryPage/LoadableCategoryPage'
import createFacebookTracker from '@jetshop/core/server/tracking/facebook'

/* eslint-disable import/no-anonymous-default-export */
export default {
   theme,
   apolloConfig: {
      shopid: process.env.REACT_APP_SHOP_ID || 'verktygsboden',
      graphQLURI:
         process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
      token:
         process.env.REACT_APP_APOLLO_TOKEN ||
         '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
      engineApiKey: process.env.ENGINE_API_KEY || '',
      enableGateway: false,
      channelsQuery,
      persistedQueries: [
         {
            query: homeCategoriesQuery,
            variables: { levels: 1 },
         },
      ],
   },
   siteSettingsId: process.env.SITE_SETTINGS_ID || process.env.REACT_APP_SITE_SETTINGS_ID || 3831,
   startPageId: 56,
   trackingID: '',
   additionalGtagTrackingIds: [],
   tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
   relewareEnabled: false,
   intl: {
      translations,
      defaultLocale: 'en',
      options: {},
   },
   channelOverrides: {},
   disableGeoRedirect: true,
   singleDomainMode: process.env.REACT_APP_SINGLE_DOMAIN_MODE,
   schemaExtensions: [],
   preserveRedirect: true,
   structuredData: {
      disableDefaultProductData: true,
   },
   openGraph: {
      disableDefaultProductData: false,
   },
   trackers: [addwishTracker('1F800E9337EC7F19781AF0E7ED811891')],
   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
   useTrackingConsentAPI: true,
   loginPath: '/login',
   pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
   preload: {
      routeQuery,
      preloadComponents: {
         Product: [LoadableProductPage],
         Category: [LoadableCategoryPage],
         Page: [LoadableContentPage],
      },
   },
   serverTrackers: [
      createFacebookTracker({
        pixelId: process.env.FACEBOOK_PIXEL_ID, // Your Facebook Pixel ID from environment variables
        token: process.env.FACEBOOK_CAPI_TOKEN // Your CAPI token from environment variables
      })
    ]
}
