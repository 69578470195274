
export const fetchHelloRetailRecoms = async (config, trackingId, recomKeys, hierarchies) => {
    let result;
    
    const path = window.location.pathname;

    let data = {
        websiteUuid: config?.id,
        trackingUserId: trackingId,
        requests: []
    };

    for (let recomKey of recomKeys) {
       let requestObj = {
          key: recomKey?.value,
          format: 'json',
          context: {
             urls: [`${config?.domain}${path}`],
          },
       }
       data.requests.push(requestObj)

       if (hierarchies) {
         delete requestObj.context.urls;
         requestObj.context.hierarchies = [hierarchies]
       }
    }

     try {
        const response = await fetch('https://core.helloretail.com/serve/recoms', {
           method: 'POST',
           headers: {
              'Content-Type': 'application/json',
           },
           body: JSON.stringify(data),
        })

        if (response.ok) {
           result = await response.json()
        } else {
           console.error('Error fetching data for Hello Retail:', response.status)
        }
     } catch (error) {
        console.error('An error occurred while fetching data for Hello Retail:', error)
     }

     return result;
 }

 export const fetchNewUserTrackingId = async (websiteId) => {
   let result;

   let data = {
       websiteUuid: websiteId,
   };

    try {
       const response = await fetch('https://core.helloretail.com/serve/trackingUser', {
          method: 'POST',
          headers: {
             'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
       })

       if (response.ok) {
          result = await response.json()
       } else {
          console.error('Error fetching data for Hello Retail:', response.status)
       }
    } catch (error) {
       console.error('An error occurred while fetching data for Hello Retail:', error)
    }

    return result;
}