import React from 'react';
import { styled } from 'linaria/react'
import ListCardSkeleton from './ListCardSkeleton';


const MenuSkeletonContainer = styled.ul`
    list-style-type: none;
    margin:0;
    overflow-y: auto;

    div {
        width: 160px;
        height: 24px;
    }
`

const MenuSkeleton = () => {
    return (
        <div className='px-128'>
            <MenuSkeletonContainer className='d-none d-xl-flex gap-2'>
                <li><div className="placeholder placeholder-wave my-32"></div></li>
                <li><div className="placeholder placeholder-wave my-32"></div></li>
                <li><div className="placeholder placeholder-wave my-32"></div></li>
                <li><div className="placeholder placeholder-wave my-32"></div></li>
                <li><div className="placeholder placeholder-wave my-32"></div></li>
                <li><div className="placeholder placeholder-wave my-32"></div></li>
                <li><div className="placeholder placeholder-wave my-32"></div></li>
                <li><div className="placeholder placeholder-wave my-32"></div></li>
            </MenuSkeletonContainer>
        </div>
    )
}
 
export default MenuSkeleton;