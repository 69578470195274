import React, { useState, useContext } from 'react'
import {styled} from 'linaria/react'
import {theme} from '../../Theme'
import { getStringByPropertyName } from '../../Core/Util/Helpers'
import SiteButton from '../../Core/SiteButton'
import { NavArrowRight } from 'iconoir-react'
import { useIntl } from '@jetshop/intl'
import SubscribeToNewsletter from './SubscribeToNewsletter'
// import { useHistory } from "react-router-dom";

const NewsletterBlock = styled.div`
    text-align: center;
    background: var(--color-vb-blue);

    .footer-newsletter-input {
        border-radius: 50px;
        max-width: 20rem;
    }
`

const FooterNewsletterV2 = ({ newsLetter }) => {
   const title = getStringByPropertyName(newsLetter?.properties, 'title')
   const subTitle = getStringByPropertyName(newsLetter?.properties, 'subTitle')

   return (
      <NewsletterBlock className="col-12 py-64 px-32">
        <SubscribeToNewsletter title={title} subTitle={subTitle}></SubscribeToNewsletter>
      </NewsletterBlock>
   )
}
 
export default FooterNewsletterV2;
