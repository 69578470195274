import React, { useRef, useEffect } from 'react'
import { default as JetshopUIBreadcrumbs } from '@jetshop/ui/Breadcrumbs'
import { styled } from 'linaria/react'
import { theme } from '../../Theme'
import { removeAlterantiveStr } from '../Util/Helpers'

const StyledBreadcrumbsWrapper = styled.div`
   width: 100%;
   overflow-x: auto;
   // Ensure hidden scrollbar in mobile-size-mode
   ${theme.below.sm} {
      &::-webkit-scrollbar {
         display: none;
      }
   }
`

const StyledBreadcrumbs = styled(JetshopUIBreadcrumbs)`
   list-style: none;
   margin: 0;
   flex-wrap: wrap;

   ${theme.above.md} {
      display: block !important;
      flex-wrap: nowrap;
      white-space: nowrap;
   }

   li {
      display: inline;
      font-size: 0.9rem;
      ${theme.above.md} {
         font-size: 1rem;
      }
   }

   li a {
      color: var(--color-black);
      text-decoration: none;
      font-weight: 500;
      opacity: 1;
      transition: opacity 0.2s ease;
      font-size: 14px; 
      ${theme.above.md} {
         font-size: 16px; 
      }
   }

   li:last-child {
      color: var(--color-vb-blue);
      font-weight: 700;
      // Right-space on last breadcrumb in mobile-size-mode
      // on scroll-end-position-right.
      ${theme.below.sm} {
         padding-right: 1rem;
      }
   }

   li a:hover {
      opacity: 0.6;
      transition: opacity 0.2s ease;
   }
`

export const Breadcrumbs = ({ title, other, ...props }) => {
   return (
      <StyledBreadcrumbsWrapper
         ref={node => {
            if (node) {
               node.scrollLeft = node.scrollWidth - node.clientWidth
            }
         }}
      >
         <StyledBreadcrumbs hideLast breadcrumbText={removeAlterantiveStr(title)} {...props} />
      </StyledBreadcrumbsWrapper>
   )
}
