import { useEffect, useState, useRef } from 'react'
// import { debounce } from 'lodash'

/**
 * This hook returns a boolean indicating whether the user has scrolled on the page.
 * @returns {boolean} True if scrolled, false otherwise.
 */
export const useIsScrolled = () => {
   const [isScrolled, setIsScrolled] = useState(false)
   const prevScrollY = useRef(0)

   useEffect(() => {
      if (!window || !window.addEventListener) {
         return
      }

      // Add a debounce function?, reduce fn executions
      //  const handleScroll = debounce(() => {
      const handleScroll = () => {
         const currentScrollY = window.scrollY
         if (
            (currentScrollY > 1 && prevScrollY.current <= 1) ||
            (currentScrollY <= 1 && prevScrollY.current > 1)
         ) {
            setIsScrolled(currentScrollY > 1)
            prevScrollY.current = currentScrollY
         }
      }
      //  }, 30)

      window.addEventListener('scroll', handleScroll)

      return () => {
         window.removeEventListener('scroll', handleScroll)
      }
   }, [])

   return isScrolled
}
