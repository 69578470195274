// utils/voyadoEngage.js

// Helper function to ensure Voyado Engage is loaded
export const ensureVoyadoEngageLoaded = async () => {
   // First, check if 'window' is defined to avoid ReferenceError
   if (typeof window === 'undefined') {
      console.warn(
         'Window object is not available. This script should run in a browser environment.'
      )
      return false
   }
   // Then, safely check if 'window.va' is undefined
   if (typeof window?.va === 'undefined') {
      console.warn(
         'Voyado Engage is not loaded. Make sure the script is included and initialized properly.'
      )
      return false
   }
   // If both checks pass, return true
   return true
}

/**
 * Set the contact ID in Voyado Engage
 * @param {*} contactId 
 * @returns 
 */
export const setContactId = contactId => {
   if (!ensureVoyadoEngageLoaded()) return
   try {
      window.va('setContactId', contactId)
      // console.log(
      //    'Likely approved/success request window.va(setContactId), Contact ID set in Voyado Engage:',
      //    contactId
      // )
   } catch (error) {
      console.error('Error setting contact ID in Voyado Engage:', error)
   }
}

/**
 * Track cart updates with Voyado Engage
 * @param {Object} cart - The current state of the cart
 * @param {string} contactId - The contact ID of the user
 * @param {string} locale - The locale of the user
 */
export const trackCartUpdate = (cart, locale = 'sv-SE') => {
   if (!ensureVoyadoEngageLoaded()) return

   const payload = {
      //  contactId: contactId, already set in window.va
      cartRef: cart?.id,
      cartUrl: cart?.externalCheckoutUrl,
      locale: locale,
      items: cart?.items?.map(item => ({
         itemId: item?.articleNumber,
         quantity: item?.quantity,
      })),
   }

   try {
      window.va('cart', {
         ...payload,
      })
      // console.log(
      //    'Likely approved/success request window.va(cart), Cart update tracked in Voyado Engage'
      // )
   } catch (error) {
      console.error('Error tracking cart update in Voyado Engage:', error)
   }
}

/**
 * Empty the cart in Voyado Engage
 * @param {string} cartId - The ID of the cart to be emptied
 */
export const emptyCart = cartId => {
   if (!ensureVoyadoEngageLoaded()) return

   try {
      window.va('emptyCart', {
         cartRef: cartId,
      })
      // console.log(
      //    'Likely approved/success request window.va(emptyCart), Cart emptied in Voyado Engage:',
      //    cartId
      // )
   } catch (error) {
      console.error('Error emptying cart in Voyado Engage:', error)
   }
}
