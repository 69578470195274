import React, { useContext } from 'react'
import {styled} from 'linaria/react'
import {theme} from '../../Theme'
import SwedenLogo from '../../../svg/verktygsboden-v3-sv.svg'
import SmallLogo from '../../../svg/VB-logo.svg'
import Logo from '../../Core/CustomLogos/Logo'
import Link from '../../Core/Link'

const FooterBottomContainer = styled.div`
   background: #191919;
   .footer-logo {
      background: var(--color-vb-blue);
   }
`

const FooterBottomBar = ({bottomContent}) => {
   return (
      <FooterBottomContainer className="row p-0 m-0">
         <div className="col footer-copyright py-16 px-128 d-flex align-items-center" dangerouslySetInnerHTML={{ __html: bottomContent}}/>
         <div className="col-md-auto col-auto footer-logo pr-128 pl-32 py-32 d-flex align-items-center">
            <Link to="/">
               <span className="logo-desktop d-none d-lg-block"><Logo/></span>
            </Link>
            <img
               className="logo-mobile d-block d-lg-none"
               alt="Small Logo"
               src={SmallLogo}
               loading='lazy'
            />
         </div>
      </FooterBottomContainer>
   )
}

 export default FooterBottomBar;