import ChannelContext from '@jetshop/core/components/ChannelContext'
import { useContext, useState } from 'react'
import { fetchFindifyAutoComplete } from '../../Findify/ApiService'
import { getFindifyApiKey } from '../../Findify/Helper'
import { formatRedirectUrl } from '../Util/Helpers'

export const useHandleRedirectSearchPath = () => {
   const selectedChannel = useContext(ChannelContext)?.selectedChannel
   const apiKey = getFindifyApiKey(selectedChannel?.id)
   const [loading, setLoading] = useState(false)

   async function handleFetchPossibleRedirectUrl(searchStr) {
      setLoading(true)
      let redirectPath = null
      try {
         const data = await fetchFindifyAutoComplete(apiKey, searchStr, 0, 0)
         // Check for a redirect URL in the response
         if (data?.redirect?.url) {
            // Format and handle the redirect URL
            redirectPath = formatRedirectUrl(data?.redirect?.url)
         }
      } catch (error) {
         // console.error("Failed to fetch possible redirectURL", error)
         redirectPath = null
      } finally {
         setLoading(false)
      }
      return redirectPath
   }

   return { handleFetchPossibleRedirectUrl, loading } 

}
