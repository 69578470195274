import React, {useContext, useEffect} from 'react'
import {useMutation} from '@apollo/react-hooks'
import t from '@jetshop/intl'

import {addToCartSuccess} from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils'
import {CartIdContext} from '@jetshop/core/components/Cart/CartIdContext'

import {addToCart as addToCartQuery} from '../components/Cart/addToCart.gql'
import {useProductList} from '@jetshop/core/hooks/ProductList'

import {useHistory} from 'react-router';
import {useNotification} from "@jetshop/core/components/Notifications";


let callbackOptions = null;

export const useJetshopWindowFunctions = () => {
  useEffect(() => {
    init()
    return cleanUp
  })
  const [trigger, dismiss] = useNotification();

  const {cartId, setCartId} = useContext(CartIdContext)

  const onAddToCartSuccess = (callbackOptions) => {
    trigger(
      <div>{t('Product has been added to cart')}</div>
    )
  }

  const [add] = useMutation(addToCartQuery, {
    onCompleted: data => {
      addToCartSuccess({onAddToCartSuccess, callbackOptions, cartId, setCartId})({data})
    },
  })

  /**
   * @typedef Props
   * @property {object} product
   * @property {string} product.articleNumber
   */

  /** @param {Props} props */
  const addToCart = product => {
    callbackOptions = {...product};
    add({
      variables: {
        input: {
          cartId,
          articleNumber: product.articleNumber,
          quantity: 1,
        },
      },
    })
  }

  const {toggle, inList} = useProductList()

  const toggleWithDefaultOptions = articleNumber => {

    return toggle(articleNumber, {
      variantArticleNumber: undefined,
      productName: undefined,
    })
  }

  const inListDefaultOptions = articleNumber => {
    return inList(articleNumber, {
      variantArticleNumber: undefined,
      productName: undefined,
    })
  }

  // FINDIFY INTERNAL LINKING
  const history = useHistory();
  const goToRoute = route => {

    // FOOLPROOF FIX FOR BAD URLS FROM HYPERDRIVE
    if (route.match("https")) {
      console.warn("Absolute url detected: ", route);
      route = route.replace("https://", "");
      route = route.substr(route.indexOf("/"));
    }
    history.push(route);
  };


  const init = () => {
    window.JetshopFunctions = {}
    window.JetshopFunctions.addToCart = addToCart
    window.JetshopFunctions.toggleFavourite = toggleWithDefaultOptions
    window.JetshopFunctions.inFavouritesList = inListDefaultOptions
    window.JetshopFunctions.goToRoute = goToRoute
  }

  const cleanUp = () => {
    window.JetshopFunctions = null
  }
}
