import React, { useContext } from 'react'
import {styled} from 'linaria/react'
import {theme} from '../../Theme'
import SiteButton from '../../Core/SiteButton'
import { getStringByPropertyName } from '../../Core/Util/Helpers'
import { NavArrowRight } from 'iconoir-react'
import FooterNewsletter from './FooterNewsletter'

const FooterTrustIconsContainer = styled.div`
   .trust-logos {
      height: 120px !important;
      ${theme.below.md} {
         height: 100px !important;
      }

      ${theme.below.sm} {
         height: 80px !important;
      }
   }
`

const FooterTrustIcons = ({ bodyColumnIcons }) => {
   const icons = bodyColumnIcons?.slice(0, 1)
   
   return (
      <FooterTrustIconsContainer className="col-12 ml-3 mt-3 d-flex justify-content-sm-center">
         {icons?.map((imageIcon, i) => {
            const image = getStringByPropertyName(
               imageIcon?.properties,
               'image'
            )
            const imageAlt = getStringByPropertyName(
               imageIcon?.properties,
               'altText'
            )
            const link = getStringByPropertyName(imageIcon?.properties, 'link')
            const imageUrl = getStringByPropertyName(
               imageIcon?.properties,
               'imageUrl'
            )
            return (
               <a key={i} target="_blank" rel="noreferrer" href={link ?? ''}>
                  <img
                     className="trust-logos"
                     src={imageUrl ? imageUrl : image}
                     alt={imageAlt}
                     loading="lazy"
                  />
               </a>
            )
         })}
         <div className="teh-certificate" data-size="150"></div>
      </FooterTrustIconsContainer>
   )
}
 
export default FooterTrustIcons;